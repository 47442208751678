const Icons = {
  "add-shopping-cart": {
    viewBox: "0 0 40.33000183105469 42",
    paths: [
      "M20 16h4v-6h6V6h-6V0h-4v6h-6v4h6v6zm-8 18c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4zm20 0c-2.21 0-3.98 1.79-3.98 4s1.77 4 3.98 4 4-1.79 4-4-1.79-4-4-4zm-19.65-6.5c0-.09.02-.17.06-.24l1.8-3.26h14.9c1.5 0 2.81-.83 3.5-2.06l7.72-14.02L36.83 6h-.01l-2.21 4-5.51 10H15.07l-.26-.54L10.32 10l-1.9-4-1.89-4H0v4h4l7.2 15.17-2.71 4.9C8.18 26.65 8 27.3 8 28c0 2.21 1.79 4 4 4h24v-4H12.85c-.28 0-.5-.22-.5-.5z",
    ],
  },
  five_stars: {
    viewBox: "0 0 72 72",
    paths: [
      "M48.333 55.27l6.18 3.73-1.64-7.03 5.46-4.73-7.19-.61-2.81-6.63-2.81 6.63-7.19.61 5.46 4.73-1.64 7.03 6.18-3.73zM24 55.27L30.18 59l-1.64-7.03L34 47.24l-7.19-.61L24 40l-2.81 6.63-7.19.61 5.46 4.73L17.82 59 24 55.27zM36.333 28.27l6.18 3.73-1.64-7.03 5.46-4.73-7.19-.61-2.81-6.63-2.81 6.63-7.19.61 5.46 4.73-1.64 7.03 6.18-3.73zM10.333 28.27l6.18 3.73-1.64-7.03 5.46-4.73-7.19-.61-2.81-6.63-2.81 6.63-7.19.61 5.46 4.73L4.153 32l6.18-3.73zM62.333 28.27l6.18 3.73-1.64-7.03 5.46-4.73-7.19-.61-2.81-6.63-2.81 6.63-7.19.61 5.46 4.73-1.64 7.03 6.18-3.73z",
    ],
  },
  star_border: {
    viewBox: "0 0 24 24",
    paths: [
      "M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z",
    ],
  },
  star_half: {
    viewBox: "0 0 24 24",
    paths: [
      "M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z",
    ],
  },
  star: {
    viewBox: "0 0 24 24",
    paths: [
      "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z",
    ],
  },
};

export default Icons;
