import React, { Component } from "react";

import { Ionicons } from "@expo/vector-icons";
import { TouchableWithoutFeedback } from "react-native";
import styled from "styled-components/native";

import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import ClickableIcon from "./ClickableIcon";
import StyledInput from "./StyledInput";

// Module constants
const INPUT_PLACEHOLDER_TEXT = "Nombre o celular";

const iconColor = "#979797";

const SEARCH_INPUT_BUFFER = 550;

interface Props {
  handleChangeText: Function;
}
interface State {
  stateShowClearIcon: boolean;
  test: string;
}

class SearchBar extends Component<Props, State> {
  storedString: any;
  timeout: ReturnType<typeof setTimeout>;

  constructor(props) {
    super(props);
    this.state = {
      stateShowClearIcon: false,
      test: "",
    };
  }

  handleChangeText = (string) => {
    this.setState({ stateShowClearIcon: !!string });
    this.setState({ test: string });
    this.storedString = string;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.bufferedHandler, SEARCH_INPUT_BUFFER);
  };

  bufferedHandler = () => {
    this.props.handleChangeText(this.storedString);
  };

  handleFocus = () => {
    this.refs.searchInput.focus();
  };

  handleClearText() {
    this.refs.searchInput && this.refs.searchInput.clear();
    this.props.onChangeText && this.props.onChangeText("");
    this.setState({ stateShowClearIcon: false });
    this.props.handleChangeText("");
    this.setState({ test: "" });
  }

  render() {
    return (
      <TouchableWithoutFeedback onPress={this.handleFocus}>
        <Header>
          <Container>
            <Wrapper>
              <SearchIcon>
                <Ionicons
                  name="ios-search"
                  size={Layout.mSize(28)}
                  color={Colors.secondaryTextColor}
                />
              </SearchIcon>
              <StyledInput
                autoFocus={false}
                autoCorrect={false}
                ref="searchInput"
                style={inputStyles}
                onChangeText={this.handleChangeText}
                multiline={false}
                underlineColorAndroid="transparent"
                placeholderTextColor={"lightgrey"}
                value={this.state.test}
                placeholder={INPUT_PLACEHOLDER_TEXT}
              />
            </Wrapper>
            <ClearIcon>
              {!this.state.stateShowClearIcon ? null : (
                <ClickableIcon
                  size={Layout.mSize(36)}
                  iconColor={iconColor}
                  name={"ios-close"}
                  onPress={this.handleClearText.bind(this)}
                />
              )}
            </ClearIcon>
          </Container>
        </Header>
      </TouchableWithoutFeedback>
    );
  }
}

export { SearchBar };

const inputStyles = {
  color: Colors.mainTextColor,
  fontFamily: Layout.fontRegular,
  fontSize: Layout.mSize(18),
  flex: 1,
};

const Header = styled.View`
  height: ${Layout.searchContainerHeight}px;
  background-color: ${Colors.white};
  margin-horizontal: ${Layout.hSize(20)}px;
`;

const Container = styled.View`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.View`
  flex: 1;
  flex-direction: row;
`;

const SearchIcon = styled.View`
  flex-direction: row;
  padding-right: ${Layout.hSize(15)}px;
`;

const ClearIcon = styled.View`
  width: ${Layout.hSize(20)}px;
  padding-top: ${Layout.vSize(3)}px;
`;
