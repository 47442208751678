import React, { useState, useEffect, useRef } from "react";

import CheckSuccess from "../../assets/svgs/CheckSuccess";

interface Props {
  count: number;
}

const ANIMATION_STATE = {
  start: 0,
  waiting: 1,
  transitionIn: 2,
  transitionOut: 3,
  done: 4,
};
const FADE_IN_TIME = 2000;
const FADE_OUT_TIME = 5000;

const CatalogueSocialProof = ({ count }: Props) => {
  const [animationState, setAnimationState] = useState(ANIMATION_STATE.start);

  useEffect(() => {
    if (animationState !== ANIMATION_STATE.start) {
      return;
    }
    const timers: NodeJS.Timeout[] = [];

    // transition in after timeout
    const timer1 = setTimeout(() => {
      setAnimationState(ANIMATION_STATE.transitionIn);

      // transition out after timeout
      const timer2 = setTimeout(() => {
        setAnimationState(ANIMATION_STATE.transitionOut);

        // permanently hide
        const timer3 = setTimeout(() => {
          setAnimationState(ANIMATION_STATE.done);
        }, 1000);
        timers.push(timer3);
      }, FADE_OUT_TIME);
      timers.push(timer2);
    }, FADE_IN_TIME);
    timers.push(timer1);

    return () => {
      timers.forEach(clearTimeout);
    };
  }, [animationState]);

  if (
    animationState === ANIMATION_STATE.start ||
    animationState === ANIMATION_STATE.waiting ||
    animationState === ANIMATION_STATE.done
  ) {
    return null;
  }

  const toastText = `${count} personas han comprado aquí`;

  return (
    <div className="fixed bottom-4 w-ecommerce z-50" data-testid="social-proof">
      <div className="flex items-center mx-4 rounded-md justify-center bg-white-100 h-14 shadow-reverse shadow">
        <div className="flex items-center text-primary-500">
          <CheckSuccess size={20} />
        </div>
        <span className="text-gray-600 font-semibold pl-4">{toastText}</span>
      </div>
    </div>
  );
};

export default CatalogueSocialProof;
