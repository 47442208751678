import React from "react";
import color from "color";
import { TouchableHighlight } from "react-native";
import { Ionicons } from "@expo/vector-icons";

type Props = {
  name?: string;
  size?: number;
  iconColor?: string;
  underlayColor?: string;
  onPress?: Function;
  modifier?: number;
  IconComponent?: Function;
};

const ClickableIcon = ({
  size,
  name,
  onPress = () => {},
  iconColor = "#999",
  modifier = 0.1,
  underlayColor = "transparent",
  IconComponent = Ionicons,
}: Props) => {
  const uColor =
    underlayColor !== "transparent"
      ? color(underlayColor).darken(modifier)
      : underlayColor;

  return (
    <TouchableHighlight underlayColor={uColor} onPress={onPress}>
      <IconComponent name={name} size={size} color={iconColor} />
    </TouchableHighlight>
  );
};

export default ClickableIcon;
