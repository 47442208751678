import get from "lodash/get";
import has from "lodash/has";
import template from "lodash/template";

// Text label `default` applies to any country code.
// Add country codes when you have different versions (i18n)
const versions = {
  youCanNowPayWithCard: {
    default: "¡Ya puedes pagar con tu tarjeta! 💳",
    506: "¡Ya podés pagar con tu tarjeta! 💳",
  },
  pleaseUpdateCardInfo: {
    default: "Por favor revisa y actualiza tu información.  💳",
    506: "Por favor revisá y actualizá tu información.  💳",
  },
  callBankIfProblem: {
    default: "Si el problema persiste, contacta a tu banco. 🏛",
    506: "Si el problema persiste, contactá a tu banco. 🏛",
  },
  sorryForDelay: {
    default: "Disculpa la demora... ⏳",
    506: "Disculpá el atraso... ⏳",
  },
  sorryForDepositDelay: {
    default: "Disculpa la demora, estamos procesando tu depósito.",
    506: "Disculpá el atraso, estamos procesando tu depósito.",
  },
  sorryProcessingPayment: {
    default: "Disculpa la demora, estamos procesando tu pago.",
    506: "Disculpá el atraso, estamos procesando tu pago.",
  },
  itCouldTake: {
    default: "Puede demorar hasta",
  },
  comeUpWithAUsername: {
    default: `Invéntate un usuario para${"\n"}que te puedan pagar. 💸`,
    506: `Inventate un usuario para${"\n"}que te puedan pagar. 💸`,
  },
  press: {
    default: "Presiona",
    506: "Seleccioná",
  },
  waitingForPayment: {
    default: "Moviendo tu chen chen 💸",
    506: "Moviendo tu harina 💸",
  },
  waitingForCharge: {
    default: "Cobrando tu chen chen 💸",
    506: "Cobrando tu harina 💸",
  },
  chargeThem: {
    default: "Cóbrale",
    506: "Cobrale",
  },
  payThem: {
    default: "Págale",
    506: "Pagale",
  },
  pickAClient: {
    default: "Escoge un cliente",
    506: "Escogé un cliente",
  },
  cancelPaymentRequest: {
    default: "¿Estás seguro que deseas cancelar este cobro?",
    506: "¿Estás seguro que querés cancelar este cobro?",
  },
  cancelPayment: {
    default: "¿Estás seguro que deseas cancelar este pago?",
    506: "¿Estás seguro que querés cancelar este pago?",
  },
  cancelSubscription: {
    default: "¿Estás seguro que deseas cancelar esta recurrencia?",
    506: "¿Estás seguro que querés cancelar esta recurrencia?",
  },
  rejectPaymentRequest: {
    default: "¿Estás seguro que deseas rechazar este cobro?",
    506: "¿Estás seguro que querés rechazar este cobro?",
  },
  markPaymentRequestAsPaid: {
    default: "¿Estás seguro que deseas marcar pagado este cobro?",
    506: "¿Estás seguro que querés marcar pagado este cobro?",
  },
  shareThisLinkToGetPaid: {
    default: `Comparte este enlace\npara que te paguen:`,
    506: `Compartí este enlace\npara que te paguen:`,
  },
  youSell: {
    default: "Vendes",
    506: "Vendés",
  },
  youPay: {
    default: "Pagas",
    506: "Pagás",
  },
  youReceive: {
    default: "Recibes",
    506: "Recibís",
  },
  onlyPayWhenYouGetPaid: {
    default: "¡Sólo nos pagas cuando a ti te pagan! 💸",
    506: "¡Sólo nos pagás cuando te pagan! 💸",
  },
  getHelp: {
    default: "Pedir ayuda",
  },
  inviteFriend: {
    default: "Invitar a un amigo",
  },
  recommendBiz: {
    default: "Recomendar a un comercio",
  },
  logout: {
    default: "Cerrar sesión",
  },
  checkoutCuantoForBiz: {
    default: `¡Chequea *Cuanto*, un app que te deja vender en línea y por redes sociales rápido y fácil! 🛍📲 \n\nBájatela aquí:\niOS: http://bit.ly/cuantoiosmerchant\nAndroid: http://bit.ly/cuantoandroidmerchant`,
    506: `¡Te recomiendo que usés *Cuanto*, un app que te deja vender en línea y por redes sociales rápido y fácil! 🛍📲 \n\nBajátela aquí:\niOS: http://bit.ly/cuantoiosmerchant\nAndroid: http://bit.ly/cuantoandroidmerchant`,
  },
  checkoutCuantoForFriend: {
    default: `¡Chequea *Cuanto*, un app que te deja pagarle a cualquier persona con tu cel! 💸📲 \n\nBájatela aquí:\niOS: http://bit.ly/cuantoios\nAndroid: http://bit.ly/cuantoandroid`,
    506: `¡Te recomiendo que usés *Cuanto*, un app que te deja pagarle a cualquier persona con tu cel! 💸📲 \n\nBajátela aquí:\niOS: http://bit.ly/cuantoios\nAndroid: http://bit.ly/cuantoandroid`,
  },
  signupSorry: {
    default: "Disculpa por ser vidajena, pero te tenemos que hacer ",
    506: "Disculpá el vinazo, pero te tenemos que hacer ",
  },
  useNameForClients: {
    default: "Usa el nombre que quieres que tus clientes vean.",
    506: "Usá el nombre que querés que tus clientes vean.",
  },
  justKiddingIWantBiz: {
    default: "¡No, mentira! Sí quiero una cuenta para mi negocio.",
  },
  comeUpWithUsername: {
    default: "Crea tu enlace de venta.",
    506: "Creá tu enlace de venta.",
  },
  suggestUseIntagram: {
    default: "Sugerencia: usa tu usuario de Instagram.",
    506: "Sugerencia: usá tu usuario de Instagram.",
  },
  comeUpWithUsernameSoYouGetPaid: {
    default: `Crea el enlace de tu tienda ${"\n"}(sugerimos tu usuario de Instagram)`,
    506: `Creá el enlace de tu tienda (sugerimos tu usuario de Instagram)`,
  },
  youCan: {
    default: "puedes",
    506: "podés",
  },
  couldNotDeposit: {
    default: "Tu depósito no pudo ser procesado, intenta nuevamente",
    506: "Tu depósito no pudo ser procesado, intentá nuevamente",
  },
  needIdOrPassport: {
    default:
      "Necesitamos una foto de tu identificación o pasaporte para cumplir con regulaciones financieras.",
    506: "Necesitamos una foto de tu cédula tica o pasaporte para cumplir con regulaciones financieras.",
  },
  pickIdAndTakePic: {
    default: "Escoge el tipo de identificación y toma una foto:",
    506: "Escogé el tipo de identificación y tomá una foto:",
  },
  placePassport: {
    default:
      "Coloca tu pasaporte dentro del recuadro blanco y toma la foto. 📷",
    506: "Colocá tu pasaporte dentro del recuadro blanco y toma la foto. 📷",
  },
  placeFrontOfId: {
    default:
      "Coloca la parte delantera de tu cédula dentro del recuadro blanco y toma la foto. 📷",
    506: "Colocá la parte delantera de tu cédula dentro del recuadro blanco y tomá la foto. 📷",
  },
  placeBackOfId: {
    default:
      "Coloca la parte trasera de tu cédula dentro del recuadro blanco y toma la foto. 📷",
    506: "Colocá la parte trasera de tu cédula dentro del recuadro blanco y tomá la foto. 📷",
  },
  reviewPhoto: {
    default: "Si no puedes leer tus datos claramente, retoma la foto.",
    506: "Si no podés leer tus datos claramente, retomá la foto.",
  },

  allowCameraAccess: { default: "Dale a Cuanto acceso a tu cámara" },
  allowCameraAccessBody: {
    default:
      "Esto es para tomar tu selfie y fotos de tu identificación para validar tu identidad.",
  },
  allowPhotoLibraryAccess: { default: "Dale a Cuanto acceso a tus imágenes" },
  allowPhotoLibraryAccessBody: {
    default:
      "Esto es para subir tu logo e imágenes de productos para personalizar tu tienda.",
  },

  yes: {
    default: "Sí",
  },
  no: {
    default: "No",
  },
  later: {
    default: "Más tarde",
  },
  sendPicture: {
    default: "Enviar foto",
  },
  pleaseSendUsPhoto: {
    default:
      "Por favor envíanos una foto legible de tu identificación vía WhatsApp.",
    506: "Por favor envianos una foto legible de tu identificación vía WhatsApp.",
  },
  freeFulfillmentOption: {
    default: "Envío gratis",
  },
  pickupFulfillmentOption: {
    default: "Retiro en tienda (pickup)",
  },
  pickupFulfillment: {
    default: "Quien recoge",
  },
  deliveryFulfillment: {
    default: "Dirección de envío",
  },
  fulfillmentDetails: {
    default: "Datos personales",
  },
  fulfillmentOptions: {
    default: "Envíos",
  },
  fulfillment: {
    default: "Envío",
  },
  pickup: {
    default: "Retiro (pickup)",
  },
  digital: {
    default: "Envío digital",
  },
  address: {
    default: "Dirección",
  },
  fulfillmentOptionFeatureToggleSectionName: {
    default: "Envío de productos",
  },
  fulfillmentOptionFeatureToggleTitle: {
    default: "Activar",
  },
  fulfillmentOptionFeatureToggleSubtitle: {
    default: "Activa pedir información y cobrar por envíos a tus clientes.",
    506: "Activá pedir información y cobrar por envíos a tus clientes.",
  },
  fulfillmentOptionContentSectionName: {
    default: "TARIFAS DE ENVÍO",
  },
  fulfillmentsAvailable: {
    default: "¡Agrega tus tarifas y cóbrale a tus clientes por el envío!",
    506: "¡Agregá tus tarifas y cobrale a tus clientes por el envío!",
  },
  fulfillmentsUnavailable: {
    default:
      "¡Activa envíos para pedirle a tu cliente su dirección y cobrarle el envío!",
    506: "¡Activá envíos para pedirle a tu cliente su dirección y cobrarle el envío!",
  },
  createFulfillmentOption: {
    default: "Crear tarifa",
  },
  saveFulfillmentOption: {
    default: "Guardar tarifa",
  },
  // TODO: add back to products feature for i18n/l10n
  emptyStoreTitle: {
    default: "Tu tienda está vacía",
  },
  emptyStoreInstructions: {
    default: "¡Aquí aparecerán los productos que agregues!",
    506: "¡Aquí aparecerán los productos que agregués!",
  },
  emptyStoreMoreInstructions: {
    default:
      "Después podrás agregar más productos desde la sección de Productos, ingresando desde el Menú",
  },
  products: {
    default: "Productos",
    506: "Productos",
  },
  addProducts: {
    default: "Agrega productos con un click en la pantalla principal 🛍📲",
    506: "Agregá productos con un click en la pantalla principal  🛍📲",
  },
  createProduct: {
    default: "Agregar producto",
  },
  saveProduct: {
    default: "Guardar producto",
  },
  dragProduct: {
    default: "Presiona y arrastra un producto para reordenarlo.",
    506: "Presioná y arrastrá un producto para reordernarlo.",
  },
  name: {
    default: "Nombre",
  },
  price: {
    default: "Precio",
  },
  description: {
    default: "Descripción (opcional)",
  },
  catalog: {
    default: "Catálogo de productos",
  },
  publish: {
    default: "Publicar",
  },
  productNotPublished: {
    default: "No publicado",
  },
  showInCatalog: {
    default: "Mostrar en el catálogo de productos",
  },
  photo: {
    default: "Foto",
  },
  newProduct: {
    default: "Nuevo producto",
  },
  newFulfillmentOption: {
    default: "Nueva tarifa",
  },
  editProduct: {
    default: "Editar producto",
  },
  editFulfillmentOption: {
    default: "Editar tarifa",
  },
  edit: {
    default: "Editar",
  },
  product: {
    default: "Producto",
  },
  charge: {
    default: "Cobrar",
  },
  proceed_to_checkout: {
    default: "Proceder al pago",
  },
  pay: {
    default: "Pagar",
  },
  newCart: {
    default: "Nueva venta",
  },
  noProducts: {
    default: "No hay productos",
  },
  addProduct: {
    default: "Agregar productos",
  },
  total: {
    default: "Total",
  },
  quantity: {
    default: "CANTIDAD",
  },
  remove: {
    default: "Eliminar",
  },
  save: {
    default: "Guardar",
  },
  whatsapp: {
    default: "WhatsApp",
  },
  addToCart: {
    default: "Agregar a venta",
  },
  addDescription: {
    default: "Agregar descripción",
  },
  unspecifiedDescription: {
    default: "Sin descripción",
  },
  newCharge: {
    default: "Nuevo cobro",
  },
  newPay: {
    default: "Nuevo pago",
  },
  for: {
    default: "por",
  },
  items: {
    default: "artículos",
  },
  item: {
    default: "artículo",
  },
  store_closed: {
    default: "Comercio Cerrado",
  },
  store_closed_desc: {
    default: "Cerrar la tienda, no entrarán órdenes",
  },
  store_closed_info: {
    default: "La tienda se encuentra cerrada por el momento. ¡Vuelve mañana!",
  },
  information: {
    default: "Información",
  },
  payment: {
    default: "Pago",
  },
  payment_plans: {
    default: "Plan de pagos",
  },
  review: {
    default: "Revisión",
  },
  subscriptions: {
    singular: {
      default: "Recurrencia",
    },
    plural: {
      default: "Recurrencias",
    },
    create: {
      default: "Crear recurrencia",
    },
    subscribe: {
      default: "Suscribirme",
    },
    subscription: {
      default: "Suscripción",
    },
    details: {
      default: "Datos",
    },
  },
  installments: {
    subscribe: {
      default: "Inscribirme",
    },
  },
  consumer: {
    howMuch: {
      default: "¿CUÁNTO?",
    },
    forWhat: {
      default: "¿PARA QUÉ?",
    },
    toWhom: {
      default: "¿A QUIÉN?",
    },
  },
  merchant: {
    products: {
      permissionBody: {
        default: "Esto te permite subir fotos de tus productos.",
      },
    },
    charge: {
      default: "COBRAR",
    },
    forWhat: {
      default: "por ${ quantity } artículos",
      506: "por ${ quantity } artículos",
    },
    to: {
      default: "A",
      506: "A",
    },
  },
  catalogue: {
    notFound: {
      header: {
        default: "¡Ups!",
      },
      body: {
        default: "No encontramos la página que buscabas. 🤔",
      },
      footer: {
        default:
          "Por favor revisa lo que escribiste o contacta al dueño de la tienda",
        506: "Por favor revisá lo que escribiste o contactá al dueño de la tienda",
      },
    },
    addToCart: {
      default: "Agregar al carrito",
    },
    addProducts: {
      default: "¡Agrega productos a tu pedido!",
      506: "¡Agregá productos a tu pedido!",
    },
  },
  compliance: {
    tabs: {
      id: {
        add: {
          default: "Agregar identificación",
        },
        text: {
          default: "Identificación",
        },
      },
      bank: {
        add: {
          default: "Agregar cuenta bancaria",
        },
        text: {
          default: "Cuenta bancaria",
        },
        selectCountry: {
          default: "Escoger país",
        },
      },
      selfie: {
        add: {
          default: "Agregar selfie",
        },
        text: {
          default: "Selfie",
        },
      },
    },
    whatsapp: {
      sendSelfiePhoto: {
        default: "Hola. Aquí te envío mi selfie:",
      },
    },
    selfieScreenTitle: {
      default: "Selfie",
    },
    requestSelfiePermission: {
      emoji: {
        default: "👩 🤳",
      },
      description: {
        default:
          "Necesitamos una foto de tu cara\n(sin lentes 🤓) para comparar con\ntu identificación y cumplir con\nregulaciones financieras.",
        506: "Necesitamos una foto de tu cara\n(sin anteojos 🤓) para comparar con\ntu identificación y cumplir con\nregulaciones financieras.",
      },
      button: {
        default: "Tomar foto",
      },
      cameraPermission: {
        title: {
          default: "Dale a Cuanto acceso\na tu cámara",
        },
        text: {
          default:
            "Esto te permite tomar una\nfoto de tu cara para\nrecibir tu dinero.",
        },
      },
    },
    selfieCameraPermissionDenied: {
      description: {
        default:
          "Necesitamos una foto de tu\ncara para poder enviarte tu \ndinero.",
      },
      emoji: {
        default: "👩 🤳",
      },
      actionable: {
        default:
          "Por favor envíanos una foto\nde tu cara sin lentes via\nWhatsApp. ",
        506: "Por favor envianos una foto\nde tu cara sin anteojos via\nWhatsApp. ",
      },
    },
    selfieCamera: {
      alert: {
        title: {
          default: "Selfie",
        },
        description: {
          default:
            "No reconocemos tu cara. Por favor pon tu cara al tomar la foto.",
          506: "No reconocemos tu cara. Por favor poné tu cara al tomar la foto.",
        },
        button: {
          default: "OK",
        },
      },
      button: {
        default: "TOMAR FOTO",
      },
      hint: {
        default: "Coloca tu cara dentro del círculo\nblanco y toma la foto.",
        506: "Colocá tu cara dentro del círculo\nblanco y tomá la foto.",
      },
    },
    confirmSelfie: {
      header: {
        default: "Asegúrate que la foto no salga\nborrosa.",
        506: "Asegurate que la foto no salga\nborrosa.",
      },
      retake: {
        default: "Retomar",
      },
      confirm: {
        default: "Continuar",
      },
      errorAlert: {
        title: {
          default: "Selfie",
        },
        description: {
          default: "Tu selfie no pudo ser procesado, intenta nuevamente.",
          506: "Tu selfie no pudo ser procesado, intentá nuevamente.",
        },
        button: {
          default: "OK",
        },
      },
    },
    selfieSubmitted: {
      success: {
        default: "¡Éxito! 🙌",
      },
      verifying: {
        default: "Nuestros robots están verificando tu foto.",
      },
      emoji: {
        default: "🤖 🔎",
      },
      willSee: {
        default: "Verás un",
      },
      whenReady: {
        default: "cuando este lista.",
      },
      onFailure: {
        default: "Si la identificación falla verás",
      },
      an: {
        default: "un",
      },
      willAsk: {
        default: "y te pediremos que",
      },
      anotherPicture: {
        default: "tomes otra foto.",
        506: "tomés otra foto.",
      },
    },
  },
  alert: {
    removeProductTitle: {
      default: "Eliminar producto",
    },
    removeProductBody: {
      default: "¿Estas seguro que deseas eliminar este producto?",
      506: "¿Estas seguro que querés eliminar este producto?",
    },
    removeFreeformProductTitle: {
      default: "Eliminar monto",
    },
    removeFreeformProductBody: {
      default: "¿Estas seguro que deseas eliminar este monto?",
      506: "¿Estas seguro que querés eliminar este monto?",
    },
    removeFulfillmentOptionTitle: {
      default: "Eliminar tarifa",
    },
    removeFulfillmentOptionBody: {
      default: "¿Estas seguro que deseas eliminar esta tarifa?",
      506: "¿Estas seguro que querés eliminar esta tarifa?",
    },
    resetCartTitle: {
      default: "Eliminar venta",
    },
    resetCartBody: {
      default:
        "¿Estas seguro que deseas eliminar todos los productos de esta venta?",
      506: "¿Estas seguro que querés eliminar todos los productos de esta venta?",
    },
  },
  currencies: {
    usd: {
      default: "Dólares",
    },
    crc: {
      default: "Colones",
    },
  },
  receipt: {
    promptTitle: {
      default: "Reenviar recibo",
    },
    promptBody: {
      default: "Escribe el correo eléctronico donde deseas enviar el recibo:",
      506: "Escribí el correo eléctronico donde deseas enviar el recibo:",
    },
    cancel: {
      default: "Cancelar",
    },
    confirm: {
      default: "Enviar",
    },
    sent: {
      default: "Enviamos tu recibo a",
    },
    notSent: {
      default: "No pudimos enviar el recibo.",
    },
    notSentMessage: {
      default:
        "Por favor revisa que el correo electrónico esté bien escrito. 🤔",
      506: "Por favor revisá que el correo electrónico esté bien escrito. 🤔",
    },
  },
  more: {
    default: "ver más",
  },
  fulfillments: {
    description: {
      default: "Descripción",
    },
    address: {
      default: "Dirección",
    },
    cellphone: {
      default: "Celular",
    },
    name: {
      default: "Nombre",
    },
  },
  navigation: {
    tabs: {
      "Quantity Keypad Screen": {
        default: "Cantidad",
      },
      "Add Product to Order Screen": {
        default: "Productos",
      },
    },
  },
};

// Return version of copy according to countryCode
function text(namespace, options) {
  if (has(versions, namespace)) {
    return function (countryCode) {
      const dictionary = get(versions, namespace);

      if (options) {
        // https://lodash.com/docs/#template
        const string = dictionary[countryCode] || dictionary["default"];
        const compiled = template(string);
        return compiled(options);
      }

      return dictionary[countryCode] || dictionary["default"];
    };
  } else {
    throw `No entry for '${namespace}' in Copy.versions`;
  }
}

// Text used across app
// Saved here for easy access
export default {
  // Common
  BTN_TEXT_CONTINUE: "Continuar",
  BTN_TEXT_RETAKE: "Retomar",
  // Verification screen
  LOGIN_CELULAR_SCREEN_HEADER_TEXT:
    "¡Vende rápido y fácil desde tu cel con Cuanto 🛍📲!",
  LOGIN_CELULAR_SCREEN_HEADER_SMALLTEXT: "Verifícate con tu celular. ☎",
  LOGIN_CELULAR_SCREEN_DISPLAY_HEADER_TEXT: "Celular",
  LOGIN_CELULAR_SCREEN_DISPLAY_PLACEHOLDER: "6123-4567",
  // Signup fullname screen
  SIGNUP_FULLNAME_SCREEN_DISPLAY_HEADER_TEXT: "NOMBRE Y APELLIDO",
  SIGNUP_FULLNAME_SCREEN_HEADER_PLACEHOLDER: "Luke Skywalker",
  SIGNUP_FULLNAME_SCREEN_HEADER_SMALLTEXT: "¿Cuál es tu nombre y apellido? 😃",
  // Signup address screen
  SIGNUP_ADDRESS_SCREEN_DISPLAY_HEADER_TEXT: "DIRECCIÓN",
  SIGNUP_ADDRESS_SCREEN_HEADER_PLACEHOLDER: `PH Watto's Shop. Calle 51B${"\n"}Mos Eisley, Tattooine`,
  SIGNUP_ADDRESS_SCREEN_HEADER_SMALLTEXT: "¿Dónde vives? 🏠",
  // Signup celular screen
  SIGNUP_CELULAR_SCREEN_HEADER_SMALLTEXT: "¿Cuál es tu cel? 📞",
  SIGNUP_CELULAR_SCREEN_DISPLAY_HEADER_TEXT: "CELULAR",
  SIGNUP_CELULAR_SCREEN_DISPLAY_PLACEHOLDER: "6123-4567",
  // Signup username screen
  SIGNUP_USERNAME_SCREEN_DISPLAY_HEADERTEXT: "USUARIO",
  SIGNUP_USERNAME_SCREEN_DISPLAY_PLACEHOLDER: "jediluke",
  SIGNUP_USERNAME_SCREEN_HEADER_SMALLTEXT: `Invéntate un usuario para${"\n"}que te puedan pagar. 💸`,
  // Signup camera id screen
  SIGNUP_CAMERA_SCREEN_HEADER_SMALLTEXT: `Coloca tu cédula o pasaporte dentro del recuadro blanco y toma la foto. 📷`,
  SIGNUP_CAMERA_SCREEN_BTN_TEXT: "TOMAR FOTO",
  PAYMENT_FORM_COMMENTS_PLACEHOLDER: "Capa de Jedi 👕",
  EMPTY_COMMENTS: "",
  validatingCard: () => {
    return "Validando tu tarjeta";
  },
  couldNotValidateCard: () => {
    return "¡Upss! No pudimos validar tu tarjeta. 😱";
  },
  amountLabelInBalanceAndTransaction: () => {
    return "SALDO";
  },
  waitingForCharge: text("waitingForCharge"),
  waitingForPayment: text("waitingForPayment"),
  signupSorry: text("signupSorry"),
  // Functions called where copy needs to be localized using the users' countryCode
  youCanNowPayWithCard: text("youCanNowPayWithCard"),
  pleaseUpdateCardInfo: text("pleaseUpdateCardInfo"),
  callBankIfProblem: text("callBankIfProblem"),
  sorryForDelay: text("sorryForDelay"),
  itCouldTake: text("itCouldTake"),
  comeUpWithAUsername: text("comeUpWithAUsername"),
  press: text("press"),
  chargeThem: text("chargeThem"),
  payThem: text("payThem"),
  pickAClient: text("pickAClient"),
  cancelPaymentRequest: text("cancelPaymentRequest"),
  rejectPaymentRequest: text("rejectPaymentRequest"),
  shareThisLinkToGetPaid: text("shareThisLinkToGetPaid"),
  sorryProcessingPayment: text("sorryProcessingPayment"),
  youPay: text("youPay"),
  youSell: text("youSell"),
  youReceive: text("youReceive"),
  onlyPayWhenYouGetPaid: text("onlyPayWhenYouGetPaid"),
  getHelp: text("getHelp"),
  checkoutCuantoForBiz: text("checkoutCuantoForBiz"),
  checkoutCuantoForFriend: text("checkoutCuantoForFriend"),
  useNameForClients: text("useNameForClients"),
  justKiddingIWantBiz: text("justKiddingIWantBiz"),
  comeUpWithUsername: text("comeUpWithUsername"),
  suggestUseIntagram: text("suggestUseIntagram"),
  cancelPayment: text("cancelPayment"),
  cancelSubscription: text("cancelSubscription"),
  youCan: text("youCan"),
  comeUpWithUsernameSoYouGetPaid: text("comeUpWithUsernameSoYouGetPaid"),
  recommendBiz: text("recommendBiz"),
  inviteFriend: text("inviteFriend"),
  logout: text("logout"),
  couldNotDeposit: text("couldNotDeposit"),
  needIdOrPassport: text("needIdOrPassport"),
  pickIdAndTakePic: text("pickIdAndTakePic"),
  placePassport: text("placePassport"),
  reviewPhoto: text("reviewPhoto"),
  placeBackOfId: text("placeBackOfId"),
  placeFrontOfId: text("placeFrontOfId"),
  allowCameraAccess: text("allowCameraAccess"),
  allowCameraAccessBody: text("allowCameraAccessBody"),
  yes: text("yes"),
  later: text("later"),
  pleaseSendUsPhoto: text("pleaseSendUsPhoto"),
  products: text("products"),
  freeFulfillmentOption: text("freeFulfillmentOption"),
  fulfillmentOptions: text("fulfillmentOptions"),
  fulfillmentOptionFeatureToggleSectionName: text(
    "fulfillmentOptionFeatureToggleSectionName"
  ),
  fulfillmentOptionFeatureToggleTitle: text(
    "fulfillmentOptionFeatureToggleTitle"
  ),
  fulfillmentOptionFeatureToggleSubtitle: text(
    "fulfillmentOptionFeatureToggleSubtitle"
  ),
  fulfillmentOptionContentSectionName: text(
    "fulfillmentOptionContentSectionName"
  ),
  emptyStoreTitle: text("emptyStoreTitle"),
  emptyStoreInstructions: text("emptyStoreInstructions"),
  emptyStoreMoreInstructions: text("emptyStoreMoreInstructions"),
  addProducts: text("addProducts"),
  createProduct: text("createProduct"),
  saveProduct: text("saveProduct"),
  name: text("name"),
  price: text("price"),
  description: text("description"),
  photo: text("photo"),
  newProduct: text("newProduct"),
  newFulfillmentOption: text("newFulfillmentOption"),
  editProduct: text("editProduct"),
  editFulfillmentOption: text("editFulfillmentOption"),
  edit: text("edit"),
  product: text("product"),
  charge: text("charge"),
  pay: text("pay"),
  newCart: text("newCart"),
  noProducts: text("noProducts"),
  fulfillmentsAvailable: text("fulfillmentsAvailable"),
  fulfillmentsUnavailable: text("fulfillmentsUnavailable"),
  createFulfillmentOption: text("createFulfillmentOption"),
  saveFulfillmentOption: text("saveFulfillmentOption"),
  addProduct: text("addProduct"),
  total: text("total"),
  quantity: text("quantity"),
  remove: text("remove"),
  save: text("save"),
  whatsapp: text("whatsapp"),
  addToCart: text("addToCart"),
  addDescription: text("addDescription"),
  unspecifiedDescription: text("unspecifiedDescription"),
  newCharge: text("newCharge"),
  newPay: text("newPay"),

  ns: (namespace, options) => text(namespace, options),
  t: (key) => text(key)(),
};
