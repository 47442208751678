import React, { useEffect } from "react";

import { useStore } from "react-redux";
import get from "lodash/get";

import { globalHistory } from "./history";

function RouterListener() {
  const store = useStore();
  useEffect(() => {
    return globalHistory.listen((event) => {
      const state = store.getState();
      const email = get(state, "consumer.email", "");
      const session = get(state, "consumer.session.jwt");
      if (session) {
        // @ts-ignore
        const _sift = (window._sift = window._sift || []);
        // Set to the user's ID, username, or email address, or '' if not yet known.
        _sift.push(["_setUserId", email]);
        // Set to a unique session ID for the visitor's current browsing session.
        _sift.push(["_setSessionId", session]);
        _sift.push(["_trackPageview"]);
      }
    });
  }, []);

  return null;
}

export default RouterListener;
