import { Colors } from "../../constants";

export const BADGE_TYPES = {
  DEFAULT: "DEFAULT",
  INVERTED: "INVERTED",
  WARNING: "WARNING",
};

export const BadgeTypes = {
  [BADGE_TYPES.DEFAULT]: {
    icon: {
      color: Colors.primary,
    },
    badge: {
      background: Colors.primary,
    },
    number: {
      color: Colors.white,
    },
  },
  [BADGE_TYPES.INVERTED]: {
    icon: {
      color: Colors.white,
    },
    badge: {
      background: Colors.white,
    },
    number: {
      color: Colors.primary,
    },
  },
  [BADGE_TYPES.WARNING]: {
    icon: {
      color: Colors.lightRed,
    },
    badge: {
      background: Colors.lightRed,
    },
    number: {
      color: Colors.white,
    },
  },
};
