import { create } from "tailwind-rn";

import styles from "./styles.json";

type TailwindStyle = { [key: string]: string };
const { tailwind, getColor } = create(styles);

const classNames = (...classes: string[]) => {
  return classes.join(" ");
};

const getColors = (colors: string[]): string[] => {
  return colors.map((color) => getColor(color));
};

export { tailwind, getColor, getColors, classNames, TailwindStyle };
