import React, { Component } from "react";

import { View, TouchableOpacity } from "react-native";
import styled from "styled-components/native";

import Avatar from "../../../../components/Avatar";
import Text from "../../../../components/Text";
import { Layout, Colors } from "../../../../constants";
import { Merchant as FullMerchant } from "../../../../types/Merchant";

// default avatar size used across ecommerce
export const AVATAR_SIZE = 36;

type Merchant = Pick<
  FullMerchant,
  "avatar_url" | "full_name" | "has_ecommerce_store"
>;

interface Props {
  merchant?: Merchant;
  hasAvatar?: boolean;
  hasInfos?: boolean;
  infos?: string;
  avatarSize?: number;
  enableBackButton?: boolean;
  onPressGoBack?: () => void;
}

class MerchantHeader extends Component<Props> {
  onPressGoBack = () => {
    const { onPressGoBack } = this.props;
    onPressGoBack ? onPressGoBack() : window.history.back();
  };

  render() {
    const {
      merchant,
      hasAvatar = true,
      hasInfos = true,
      infos,
      avatarSize = AVATAR_SIZE,
      enableBackButton = merchant.has_ecommerce_store,
    } = this.props;

    const Touchable = enableBackButton ? TouchableOpacity : View;

    return (
      <Touchable testID="header" onPress={this.onPressGoBack}>
        <HeaderLeft>
          <HeaderButton>
            {enableBackButton && (
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 12.5H3.75M10.5 5.75L3.75 12.5l6.75 6.75"
                  stroke="#000"
                  strokeWidth={2.25}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </HeaderButton>

          {hasAvatar && (
            <HeaderAvatar>
              <Avatar
                image={merchant.avatar_url}
                name={merchant.full_name}
                width={avatarSize}
                height={avatarSize}
              />
            </HeaderAvatar>
          )}

          {hasInfos && (
            <HeaderInfos>
              <HeaderInfoName>
                {infos ? infos : merchant.full_name}
              </HeaderInfoName>
            </HeaderInfos>
          )}
        </HeaderLeft>
      </Touchable>
    );
  }
}

export default MerchantHeader;

const HeaderLeft = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderButton = styled.View`
  padding-left: ${Layout.hSize(10)}px;
`;

const HeaderAvatar = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: ${Layout.vSize(8)}px;
  padding-right: ${Layout.vSize(6)}px;
`;

const HeaderInfos = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-left: ${Layout.vSize(8)}px;
`;

const HeaderInfoName = styled(Text)`
  color: ${Colors.font.dark.main};
  font-family: ${Layout.fontRegular};
  font-size: ${Layout.mSize(20)}px;
  font-weight: 300;
  line-height: ${Layout.mSize(24)}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
