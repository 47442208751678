const _000000 = "#000000";
const _212121 = "#212121";
const _333333 = "#333333";
const _5e5f61 = "#5e5f61";
const _646566 = "#646566";
const _666666 = "#666666";
const _666 = "#666"; // TODO: Duplicated to avoid updating snapshots all at once
const _757575 = "#757575";
const _818385 = "#818385";
const _888888 = "#888888";
const _8A8A8E = "#8A8A8E";
const _898B8C = "#898B8C";
const _aaaaaa = "#aaa";
const _babec2 = "#babec2";
const _cccccc = "#CCC";
const _e1e2e6 = "#e1e2e6";
const _d9d9d9 = "#d9d9d9";
const _dcdde0 = "#dcdde0";
const _dedede = "#dedede";
const _eeeeee = "#eeeeee";
const _e6e6e6 = "#e6e6e6";
const _f1f1f1 = "#f1f1f1";
const _f5f4f5 = "#f5f4f5";
const _f6f6f6 = "#f6f6f6";

export default {
  // Primary color, success, call to actions, etc
  primary: "#1ba39c",
  secondary: "#52489C",
  disabled: "#EFEFF0",

  // White
  secondaryBtnBg: "#fff",
  white: "#fff",

  // Light gray
  mainBG: _f6f6f6,
  secondaryBg: _f5f4f5,
  tabBar: "#fefefe",
  tabIconDefault: "#ccc",
  placeholder: _646566,

  // Gray
  quinaryGrey: "#e9e9e9",
  disabledTextColor: "#e7e7e7",
  borderSecondary: _dedede,
  secondaryGrey: "#D8D8D8",
  disabledBG: "#cbcbcb",
  inactive: "#c2c2c2",
  darkDisabledBG: "#afafaf",
  quaternaryGrey: "#a9a9a9",
  secondaryTextColor: _aaaaaa,

  // Dark gray
  gray: "#7d7d7d",
  tertiaryGrey: "#747474",
  mainTextColor: _666666,
  text: _666,
  starBlack: "#1f1f1f",

  // Pale yellow/orage
  pending: "#e9c46a",
  star: "#e9c46a",

  // Red, errors, warnings
  lightRed: "#e76f51",
  dangerRed: "#e76f51",
  errorRed: "#e76f51",

  black: "#000",

  cameraBackground: "#000",
  cameraBackgroundOpacity: 0.5,

  cardBorder: "#666666",

  whatsappGreen: "#25D366",

  background: {
    main: _f6f6f6,
    secondary: _f5f4f5,
    tertiary: _d9d9d9,
  },
  font: {
    main: _666666,
    secondary: _aaaaaa,
    tertiary: _888888,
    dark: {
      main: _212121,
      secondary: _5e5f61,
    },
    light: {
      main: _8A8A8E,
      secondary: _646566,
      tertiary: _818385,
    },
  },
  border: {
    main: _cccccc,
    secondary: _dedede,
    tertiary: _898B8C,
    quaternary: _e1e2e6,
    error: "#b92f15",
    light: {
      main: _e6e6e6,
      secondary: _f1f1f1,
      tertiary: _eeeeee,
      quaternary: _dcdde0,
      quinary: _babec2,
    },
    dark: {
      main: _d9d9d9,
      secondary: _757575,
      tertiary: _000000,
    },
  },
};
