import IntlPolyfill from "intl";
import memoize from "lodash/memoize";

// Intl.NumberFormat
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat

// Polyfill `Intl` since it's not supported in RN
// TODO Check support in future versions of RN
// https://github.com/react-native-community/jsc-android-buildscripts
// https://github.com/andyearnshaw/Intl.js/
import "intl/locale-data/jsonp/en.js";
import "intl/locale-data/jsonp/es-CR.js";

import Copy from "../constants/Copy";
import { currencies, Currency } from "../types/Currency";
import {
  FormatterStyle,
  NumberFormatPart,
  CurrencyFormatPart,
} from "../types/Currency";
import { Amount } from "../types/Payment";

// According to standards we're using a different grouping separator for CRC
// Default formatting is `₡4 500`
const CRC_SEPARATOR = ",";

const getFormatter = (
  currency: Currency,
  style: FormatterStyle = "currency"
) => {
  /*
  const formatter = new IntlPolyfill.NumberFormat(String, { // A string with a BCP 47 ("en-US") language tag, or an array of such strings
    style: String, // "decimal" for plain number,
                   // "currency" for currency,
                   // "percent" for percent
    currencyDisplay: String, // "symbol" localized currency symbol: €, 
                             // "code" ISO currency code, 
                             // "name" localized currency name: "dollar"
    minimumFractionDigits: Number, // minimum number of fraction digits to use
    maximumFractionDigits: Number, // maximum number of fraction digits to use
    useGrouping: Boolean, // use thousands separators
    currency: String, // ISO 4217 ("USD")
  });
 */

  switch (`${currency}.${style}`) {
    // CRC ₡

    // 10000 > 100
    // 100000 > 1,000
    case "crc.decimal":
      const decimalFormatter = new IntlPolyfill.NumberFormat("es-CR", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true,
        currency: "CRC",
      });
      return {
        formatToParts: (amount) =>
          decimalFormatter.formatToParts(Math.trunc(amount)),
        format: (amount) => {
          const formatted = decimalFormatter.format(Math.trunc(amount));
          // Default formatting is `₡4 500`
          // Customize grouping separator
          return formatted.replace(/\s/g, CRC_SEPARATOR);
        },
      };
    // 1 > 100
    // 10 > 1000
    // 100 > 10000
    // 1000 > 100000
    // 1,000 > 100000
    case "crc.decimalCents":
      const decimalCentsFormatter = new IntlPolyfill.NumberFormat("es-CR", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
        currency: "CRC",
      });
      return {
        formatToParts: (amount) => decimalCentsFormatter.formatToParts(amount),
        format: (amount) => {
          const formatted = decimalCentsFormatter.format(amount);
          // Default formatting is `₡4 500`
          // Customize grouping separator
          return formatted.replace(/\s/g, CRC_SEPARATOR);
        },
      };
    // Removes fraction digits
    case "crc.currencyInteger":
    case "crc.currency":
      const currencyFormatter = new IntlPolyfill.NumberFormat("es-CR", {
        style: "currency",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true,
        currency: "CRC",
      });
      return {
        formatToParts: (amount) =>
          currencyFormatter.formatToParts(Math.trunc(amount)),
        format: (amount) => {
          const formatted = currencyFormatter.format(Math.trunc(amount));
          // Default formatting is `₡4 500`
          // Customize grouping separator
          return formatted.replace(/\s/g, CRC_SEPARATOR);
        },
      };

    // USD $

    case "usd.decimal":
      // 100      > 100.00
      // 100.00   > 100.00
      // 1000     > 1000.00
      // "100.00" > 100.00
      // "100,00" > Error
      return new IntlPolyfill.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        currency: "USD",
      });
    // Removes fraction digits
    case "usd.decimalCents":
      // 100      > 100
      // 100.00   > 100
      // 1000     > 1000
      // "100.00" > 100
      // "100,00" > Error
      return new IntlPolyfill.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
        currency: "USD",
      });
    // Removes fraction digits
    case "usd.currencyInteger":
      return new IntlPolyfill.NumberFormat("en-US", {
        style: "currency",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
        currency: "USD",
      });
    case "usd.currency":
    default:
      return new IntlPolyfill.NumberFormat("en-US", {
        style: "currency",
        currencyDisplay: "symbol",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        currency: "USD",
      });
  }
};

const formatter = memoize(getFormatter, (...args) => args.join("."));

// Intl.Numberformat.formatToParts
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/formatToParts
// [
//   { type: "integer", value: "1000" },
//   { type: "decimal", value: "." },
//   { type: "fraction", value: "00" },
//   ...
// ];

// Returns amount without cents as a string
// Example 420 -> '4'
export function integer({ amount: amountAsCents, currency }: Amount) {
  const amount = amountAsCents / 100;

  const parts = formatter(currency, "decimal").formatToParts(amount);
  const value = parts.filter((part) => part.type === "integer")[0].value;
  return value;
}

// Returns amount without 'dollars' as a string
// Example: 420 -> 20
export function cents({ amount: amountAsCents, currency }: Amount) {
  const amount = amountAsCents / 100;

  const formatted = formatter(currency, "decimal");
  const parts = formatted.formatToParts(amount);
  const fraction = parts.filter((part) => part.type === "fraction")[0];
  const value = fraction ? fraction.value : "";

  // Stay consistent with previous behaviour
  return value === "00" ? "" : value;
}

// This is used where we _use_ but _hide_ cents (when === 0)
// we might replace this with `toString`
// or merge this with `toString` if we keep splitting logic, as this, on the long run
export function amountAsCurrency({
  amount: amountAsCents,
  currency,
}: Amount): string {
  const cent = cents({ amount: amountAsCents, currency });
  const amount = amountAsCents / 100;
  if (cent) {
    return formatter(currency, "currency").format(amount);
  } else {
    return formatter(currency, "currencyInteger").format(amount);
  }
}

export function amountAsPercent(bips) {
  return `${bips / 100}%`;
}

// 1000 > 10.00
export function amountAsDecimal({ amount: amountAsCents, currency }: Amount) {
  return formatter(currency, "decimal").format(amountAsCents / 100);
}

// 10.00 > 1000
export function amountToCents({ amount, currency }: Amount): string {
  const sanitized = sanitize(amount, currency);

  return formatter(currency, "decimalCents").format(sanitized * 100);
}

// 4      > { currency: '$', integer: '0', decimal: '.', fraction: '04' }
// 42     > { currency: '$', integer: '0', decimal: '.', fraction: '42' }
// 420    > { currency: '$', integer: '4', decimal: '.', fraction: '20' }
// "4"    > { currency: '$', integer: '0', decimal: '.', fraction: '04' }
// "42"   > { currency: '$', integer: '0', decimal: '.', fraction: '42' }
// "420"  > { currency: '$', integer: '4', decimal: '.', fraction: '20' }
// it also contains `group` (thousands separators) if
// `useGrouping` = true and amount has thousands
export function parts({
  amount: amountAsCents,
  currency,
}: Amount): CurrencyFormatPart {
  const amount = amountAsCents / 100;

  const parts: NumberFormatPart = {};
  const currencyParts = formatter(currency).formatToParts(amount);

  currencyParts.reduce((parts, part) => {
    const { type, value } = part;

    // if formatter uses `useGrouping` = true
    // `parts` is formed by many `{ type: 'integer' }`
    // split by thousands separators
    // values are already strings but we make explicit the concatenation
    parts[type] = `${parts[type] || ""}${value}`;

    return parts;
  }, parts);

  return {
    ...parts,
    fractionOrEmpty: parts.fraction !== "00" ? parts.fraction : "",
  };
}

export function toString({ amount: amountAsCents, currency }: Amount) {
  return formatter(currency).format(amountAsCents / 100);
}

// ^            : Start of line
// [0-9]        : One of “0”-“9”
// +            : between one and unlimited
//    ()        : Group #1, optional
//        \.    : “.”
//        [0-9] : One of “0”-“9”
//        {1,2} : Match a single character
//    ?         : at most once (Group #1)
// $            : End of line
// Valid digits are [100, 1.01, 10.9]
// TODO needs to be refactored or removed with currencies
export function isAmountValid(amount) {
  return /^[0-9]+(\.[0-9]{1,2})?$/g.test(amount);
}

// When amounts is being input from user, so with Keyboard,
// native layout allows for alternative characters, e.g. "," instead of "."
export function sanitize(text: string, currency: Currency) {
  if (!text || !text.replace) return text;

  switch (currency) {
    // 1,000 * 100 = 0
    case "crc":
      return `${text}`.replace(/\./g, "").replace(/,/g, "");
    // 1.0 * 100 = 100
    case "usd":
    default:
      return `${text}`.replace(/,/g, ".");
  }
}

// USD: 1000 > 10.00
// CRC: 1,000 > 1000
export function sanitizedAmountAsDecimal(amount: number, currency: Currency) {
  return sanitize(amountAsDecimal({ amount, currency }), currency);
}

// Since `settings[currency]` is loaded with a dynamic value (read from Models) make sure you load data properly before using these values
export const settings = {
  usd: {
    ...parts({
      amount: amountToCents({ amount: "9999.99", currency: "usd" }),
      currency: "usd",
    }),
    maxAmountAllowed: 9999.99,
  },
  crc: {
    ...parts({
      amount: amountToCents({ amount: "5,999,999", currency: "crc" }),
      currency: "crc",
    }),

    maxAmountAllowed: 5999999,
    // Customize grouping separator
    group: CRC_SEPARATOR,
  },
};

// TODO render the proper Keyboard based on currency ISO code
// Note: this is probably also related to system's locale

export const CURRENCY_TYPES = currencies.map((currency) => ({
  value: currency,
  label: Copy.ns(`currencies.${currency}`)(),
}));
