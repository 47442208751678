import React from "react";
import { TextProps } from "react-native";

import styled from "styled-components/native";

import Layout from "../constants/Layout";

interface Props extends TextProps {
  children: React.ReactNode;
}

export default function StyledText(props: Props) {
  return (
    <Styled
      {...props}
      // Force to `false` #472
      allowFontScaling={false}
    />
  );
}

const Styled = styled.Text`
  font-family: ${Layout.fontRegular};
`;
