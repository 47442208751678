import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Constants from "expo-constants";
import get from "lodash/get";

import { CuantoAPI as CuantoAPIUntyped } from "../../api/CuantoAPI";
import { CuantoAPI } from "../../api/CuantoApiTyped";
import { serializeException } from "../../services/Sentry.web";
import {
  CartProduct,
  PaymentPlanType,
  Price,
  Product,
} from "../../types/Product";
import { updateRememberDetails } from "../../web-app/features/Consumer/reducers";
import { CartReduxState, ToastData, CreateCartPayload } from "./types";

export const updatePaylinkAmount = createAction<number>(
  "cart/updatePaylinkAmount"
);
export const addToCart = createAction<{
  product: CartProduct | Product;
  prices: Price[];
}>("cart/addToCart");
export const removeFromCart = createAction("cart/removeFromCart");
export const updateProductQuantity = createAction("cart/updateProductQuantity");
export const removeExpiredProductsFromCart = createAction(
  "cart/removeExpiredProductsFromCart"
);

export const updateProduct = createAction("cart/updateProduct");

export const resetCart = createAction("cart/resetCart");

export const createCartStart = createAction("cart/createCartStart");
export const createCart = createAction<CreateCartPayload>("cart/createCart");
export const createCartError = createAction("cart/createCartError");

export const createCartPaymentStart = createAction(
  "cart/createCartPaymentStart"
);
export const createCartPayment = createAction("cart/createCartPayment");
export const createCartPaymentError = createAction(
  "cart/createCartPaymentError"
);

export const showToast = createAction<ToastData>("toast/show");

export const selectPrice = createAction<Price>("cart/selectPrice");
export const clearSelectedPrice = createAction("cart/clearSelectedPrice");

export const setUtm = createAction<{ [key: string]: string }>("cart/setUtm");
export const setTracker = createAction<string | null>("cart/setTracker");

const api = new CuantoAPIUntyped();

const getProductPrices = createAsyncThunk(
  "catalogue/getProductPrices",
  async ({
    username,
    productShortUuid,
  }: {
    username: string;
    productShortUuid: string;
  }) => {
    const response = await api.getPrices(username, productShortUuid);
    return response.data;
  }
);

export const async = {
  getProductPrices,
  createCart:
    (username: string, items: Array<CartProduct>, comments: string | null) =>
    (dispatch: any, getState) => {
      dispatch(createCartStart());

      const cart: CartReduxState = getState().cart;

      const isSubscription =
        cart.quantity === 1 &&
        cart.selectedPrice &&
        cart.selectedPrice.payment_plan_type !== PaymentPlanType.ONE_OFF;

      const api = CuantoAPI.getInstance();
      const discount_code = get(
        getState(),
        "discounts.selectedCartDiscount.code",
        ""
      );
      const consumer_device = Constants;
      const utm = cart.utm;
      const tracker = cart.tracker || "";

      return api
        .createCart({
          username,
          items,
          consumer_device,
          discount_code,
          utm,
          tracker,
          comments,
        })
        .then(({ data }) => {
          dispatch(createCart({ uuid: data.uuid, isSubscription }));
          // always remember details for subscriptions
          isSubscription && dispatch(updateRememberDetails(isSubscription));
          return data;
        })
        .catch((e) => {
          dispatch(createCartError(serializeException(e)));
          throw e;
        });
    },
};
