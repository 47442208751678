import React, { createContext, PropsWithChildren, useContext } from "react";

import { CuantoAPI } from "cuanto/api/CuantoApiTyped";

const Context = createContext<CuantoAPI | null>(null);

export default function useAPI() {
  const ctx = useContext(Context);
  if (!ctx) throw new Error("Missing APIProvider");
  return ctx;
}

type ProviderProps = PropsWithChildren<{ baseURL?: string }>;
export function APIProvider({ baseURL, children }: ProviderProps) {
  const api = new CuantoAPI({ baseURL });
  return <Context.Provider value={api}>{children}</Context.Provider>;
}
