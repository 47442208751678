import { createHistory } from "@reach/router";
import { globalHistory as routerGlobalHistory } from "@reach/router";
import { pick } from "@reach/router/lib/utils";

// @ts-ignore
export const history = createHistory(window);

export const globalHistory = routerGlobalHistory;

export const getRouteParams = () => {
  return pick([{ path: "/:username/*" }], history.location.pathname);
};
