import React from "react";

import CatalogueSocialProof from "./CatalogueSocialProof";

interface Props {
  transactionCount: number;
  cartQuantity: number;
}

const MIN_TRANSACTIONS_TO_SHOW_TOAST = 25;

export default function CatalogueSocialProofWrapper({
  transactionCount,
  cartQuantity,
}: Props) {
  const shown = hasShownProofInSession();

  // only show the toast if the cart is empty and min transaction count is met
  if (
    transactionCount < MIN_TRANSACTIONS_TO_SHOW_TOAST ||
    cartQuantity > 0 ||
    shown
  ) {
    return null;
  } else {
    // set the session storage to prevent showing the toast again
    setSocialProofShown();
    return <CatalogueSocialProof count={transactionCount} />;
  }
}

const STORAGE_KEY = "socialProofShown";

function hasShownProofInSession() {
  try {
    return sessionStorage.getItem(STORAGE_KEY) === "true";
  } catch {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage#exceptions
    // session storage not available, default as already shown
    return true;
  }
}

function setSocialProofShown() {
  try {
    sessionStorage.setItem(STORAGE_KEY, "true");
  } catch {
    // ignore error
  }
}
