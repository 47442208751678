import { mdiImageEdit } from "@mdi/js";
import Icon from "@mdi/react";

import { resizeImageUrl } from "cuanto/web-app/utils";

export type BannerProps = {
  bannerUrl: string | null;
  showPlaceholderIcon?: boolean;
};

export default function CatalogueBanner(props: BannerProps) {
  const { bannerUrl, showPlaceholderIcon } = props;

  const resizedBannerUrl =
    typeof bannerUrl === "string"
      ? resizeImageUrl(bannerUrl, { width: 1200, height: 600 })
      : bannerUrl;

  return (
    <div
      className="relative w-full bg-gradient-to-b from-primary-500 overflow-hidden"
      style={{ paddingBottom: "50%" }}
    >
      {resizedBannerUrl ? (
        <img src={resizedBannerUrl} className="w-full absolute" />
      ) : (
        <PlaceholderIcon enabled={showPlaceholderIcon} />
      )}
    </div>
  );
}

function PlaceholderIcon({ enabled }: { enabled?: boolean }) {
  if (!enabled) return null;

  return (
    <Icon
      path={mdiImageEdit}
      size={3}
      color="#3F4947"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    />
  );
}
