import React from "react";

import {
  Platform,
  TouchableOpacity,
  StyleSheet,
  Image,
  View,
} from "react-native";

import { Layout, Colors, Copy } from "../constants";
import CardIdMask from "./CardIdMask";
import Header from "./Header";
import StyledText from "./StyledText";

// Module data
const data = {
  headerSmallText: Copy.SIGNUP_REVIEW_SCREEN_HEADER_SMALLTEXT,
  borderAreaWidth: 4,
  currentStep: 3,
};

const RETAKE_BTN_TEXT = "Retomar";
const CONTINUE_BTN_TEXT = "Continuar";
const HEADER_HEIGHT = Layout.normalize(140) * Layout.vscale;
const STATUSBAR_HEIGHT =
  Platform.OS === "ios" ? Layout.normalize(24) * Layout.vscale : 0;

type Props = {
  imageAsBase64: string;
  onPressRetake: Function;
  onPressContinue: Function;
  instructionsText?: string;
  hideStepsFromHeader?: boolean;
};

export default function PhotoReview({
  onPressRetake,
  onPressContinue,
  imageAsBase64,
  instructionsText,
  hideStepsFromHeader = false,
}: Props) {
  // https://stackoverflow.com/questions/29380265/does-react-native-support-base64-encoded-images
  const imageUri = { uri: "data:image/png;base64," + imageAsBase64 };

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <ReviewHeader
          instructionsText={instructionsText || Copy.reviewPhoto()}
          onPressRetake={onPressRetake}
          hideStepsFromHeader={hideStepsFromHeader}
        />
        <View style={styles.fillerTop} />
        <CardIdMask
          bg={Colors.mainBG}
          opacity={1}
          borderColor={Colors.border.main}
        />
        <View style={styles.fillerBottom} />
      </View>
      <View style={styles.buttonGroup}>
        <TouchableOpacity
          style={styles.buttonSecondary}
          onPress={onPressRetake}
        >
          <StyledText style={styles.buttonSecondaryText}>
            {RETAKE_BTN_TEXT}
          </StyledText>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.buttonPrimary}
          onPress={onPressContinue}
        >
          <StyledText style={styles.buttonPrimaryText}>
            {CONTINUE_BTN_TEXT}
          </StyledText>
        </TouchableOpacity>
      </View>
      <Image source={imageUri} style={styles.photo} />
    </View>
  );
}

function ReviewHeader({
  onPressRetake,
  hideStepsFromHeader,
  instructionsText,
}) {
  if (hideStepsFromHeader) {
    return (
      <Header
        smallText={instructionsText}
        containerStyles={[styles.header, styles.bg]}
      />
    );
  } else {
    return (
      <Header
        progressIndicator={data.currentStep}
        smallText={instructionsText}
        containerStyles={[styles.header, styles.bg]}
        goBack={onPressRetake}
      />
    );
  }
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  bg: {
    backgroundColor: Colors.mainBG,
  },
  fillerTop: {
    backgroundColor: Colors.mainBG,
    height: HEADER_HEIGHT * 0.5,
  },
  fillerBottom: {
    backgroundColor: Colors.mainBG,
    height: Layout.window.height - HEADER_HEIGHT * 1.5 - Layout.idCardHeight,
  },
  container: {
    height: Layout.window.height,
  },
  photo: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -1,
  },
  header: {
    marginTop: 0,
    paddingTop: STATUSBAR_HEIGHT,
    height: HEADER_HEIGHT + STATUSBAR_HEIGHT,
  },
  buttonGroup: {
    backgroundColor: Colors.mainBG,
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: Layout.buttonHeight,
  },
  buttonPrimary: {
    flex: 1,
    height: Layout.buttonHeight,
    backgroundColor: Colors.primary,
    opacity: 100,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 5,
    shadowOpacity: 0.5,
    zIndex: 0,
  },
  buttonSecondary: {
    flex: 1,
    height: Layout.buttonHeight,
    backgroundColor: Colors.white,
    opacity: 100,
    shadowColor: Colors.black,
    shadowOffset: { width: -5, height: 3 },
    shadowRadius: 5,
    shadowOpacity: 0.5,
    zIndex: 1,
  },
  buttonPrimaryText: {
    color: Colors.white,
    fontSize: 24,
    lineHeight: 64,
    textAlign: "center",
    fontFamily: Layout.fontRegular,
  },
  buttonSecondaryText: {
    color: Colors.primary,
    fontSize: 24,
    lineHeight: 64,
    textAlign: "center",
    fontFamily: Layout.fontRegular,
  },
});
