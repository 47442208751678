import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import merge from "lodash/merge";

import banks from "./features/BankAccount/i18n";
import catalogue from "../features/Catalogue/i18n";

// the translations
const resources = {
  507: merge(banks[507], catalogue[507]),
  506: merge(banks[506], catalogue[506]),
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "507",
    fallbackLng: "507",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: process.env.EXPO_RELEASE_CHANNEL !== "production",
  });

export default i18n;
