import React from "react";

import { Colors } from "cuanto/constants";

type Props = {
  bio: string | null;
};

export default function CatalogueBio({ bio }: Props) {
  if (!bio) return null;

  return (
    <div className="mt-3">
      <span
        style={{ color: Colors.font.dark.main }}
        className="whitespace-pre-wrap text-base"
      >
        {bio}
      </span>
    </div>
  );
}
