import UAParser from "ua-parser-js";

const parser = new UAParser();

const Browser = parser.getBrowser();
const CPU = parser.getCPU();
const Device = parser.getDevice();
const Engine = parser.getEngine();
const OS = parser.getOS();
const Result = parser.getResult();
const UA = parser.getUA();

// TODO
// https://developers.google.com/speed/webp/faq
const hasWebp = Engine.name === "Blink";

export { Browser, CPU, Device, Engine, OS, Result, UA, hasWebp };
