import { createAction } from "@reduxjs/toolkit";

import { CuantoAPI } from "../../../api/CuantoAPI";

export const selectCartDiscount = createAction("discount/selectCartDiscount");

export const discountCheckStart = createAction("discount/discountCheckStart");
export const discountCheckComplete = createAction(
  "discount/discountCheckComplete"
);
export const discountCheckError = createAction("discount/discountCheckError");

export const async = {
  checkDiscount: (username, discount) => (dispatch, getState) => {
    const api = new CuantoAPI();

    dispatch(selectCartDiscount(null));
    dispatch(discountCheckStart);
    return api
      .getDiscount(username, discount)
      .then(({ data }) => {
        dispatch(discountCheckComplete(data));
        dispatch(selectCartDiscount(data));
        return data;
      })
      .catch(() => {
        dispatch(selectCartDiscount(null));
        dispatch(discountCheckError());
      });
  },
};
