import React from "react";

import { Merchant } from "../types";
import Icon from "./Icon";

type Props = {
  merchant: Pick<Merchant, "username" | "full_name" | "avatar_url">;
  cartQuantity: number;
  navigateBack?: () => void;
  navigateToCart: () => void;
};

export default function Header({
  merchant,
  cartQuantity,
  navigateBack,
  navigateToCart,
}: Props) {
  const { full_name, username, avatar_url } = merchant;

  return (
    <div className="flex items-center">
      <div
        className="flex items-center select-none cursor-pointer"
        onClick={navigateBack}
      >
        <div className="px-3">
          {navigateBack && (
            <div className="h-6 w-6 flex-shrink-0">
              <LeftArrowIcon />
            </div>
          )}
        </div>
        <Image src={avatar_url} placeholder={full_name} />
        <div className="pl-3 flex flex-col justify-center">
          <span className="font-semibold text-gray-900 leading-5">
            {full_name}
          </span>
          <span className="text-gray-400 leading-4">{username}</span>
        </div>
      </div>

      <div className="ml-auto mr-4">
        <CartQuantityIcon
          quantity={cartQuantity}
          navigateToCart={navigateToCart}
        />
      </div>
    </div>
  );
}

type ImageProps = {
  src: string | null;
  placeholder: string;
};
function Image({ src, placeholder }: ImageProps) {
  const initial = placeholder[0];

  const style =
    "h-11 w-11 bg-gray-300 text-white-100 text-2xl flex items-center justify-center rounded-full";

  return src ? (
    <img src={src} className={style} />
  ) : (
    <div className={style}>{initial}</div>
  );
}

type IconProps = { quantity: number; navigateToCart?: () => void };
function CartQuantityIcon({ quantity, navigateToCart }: IconProps) {
  // cart icon shows as disabled when there are no items
  const isDisabled = quantity === 0;

  const disabledStyle = isDisabled
    ? "bg-indigo-100 text-indigo-400"
    : "bg-primary-600 text-white-100";

  const containerStyle = `relative flex px-3 py-1 items-center rounded-3xl z-10 cursor-pointer select-none ${disabledStyle}`;

  return (
    <div>
      <div className={containerStyle} onClick={navigateToCart}>
        <div className="h-4 w-4">
          <Icon.Cart />
        </div>
        {quantity > 0 && <div className="text-xs pl-2">{quantity}</div>}
      </div>
    </div>
  );
}

function LeftArrowIcon() {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </svg>
  );
}
