import { useEffect, useState, useRef } from "react";
import { Dimensions } from "react-native";

const useDimensions = () => {
  const [dimensions, setDimensions] = useState(Dimensions.get("screen"));
  const [windowDimensions, setWindowDimensions] = useState(
    Dimensions.get("window")
  );
  const heightRef = useRef(windowDimensions.height);

  useEffect(() => {
    const handleDimensionsChange = () => {
      // Dimensions.addEventListener in `react-native-web` is debounced
      // this could lead to omitted events
      // using `window.addEventListener` in place of its `Dimensions.addEventListener` wrapper
      // https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/Dimensions/index.js
      const e = {
        window: {
          fontScale: 1,
          height: window.innerHeight,
          scale: window.devicePixelRatio || 1,
          width: window.innerWidth,
        },
        screen: {
          fontScale: 1,
          height: window.screen.height,
          scale: window.devicePixelRatio || 1,
          width: window.screen.width,
        },
      };

      setDimensions(e.screen);
      setWindowDimensions(e.window);
    };

    window.addEventListener("resize", () => {
      if (window.innerHeight !== heightRef.current) {
        heightRef.current = window.innerHeight;
        handleDimensionsChange();
      }
    });

    requestAnimationFrame(() => {
      // EventListener otherwise is not fired until user interacts with the page
      window.dispatchEvent(new Event("resize"));
    });

    // componentWillUnmount
    return () => window.removeEventListener("resize", handleDimensionsChange);
  }, []); // `[]` for componentDidMount

  return {
    // "screen" Dimensions are constant depending on orientation
    ...dimensions,
    screen: dimensions,
    window: windowDimensions,
    isLandscape: dimensions.width > dimensions.height,
    // "window" Dimensions are dynamic but they account for space removed from top/bottom bars
    height: windowDimensions.height,
  };
};

export default useDimensions;
