import React from "react";

import lazy from "react-lazy-with-preload";

import { retry } from "../services/network";

export const PaymentRequest = lazy(() =>
  retry(
    () =>
      import(
        "../features/Catalogue/screens/PaymentRequest/PaymentRequestScreen"
      )
  )
);

export const MerchantAdminDashboard = lazy(() =>
  retry(() => import("./admin/screens/MerchantAdminDashboardScreen.web"))
);

export const MerchantAdminProductForm = lazy(() =>
  retry(() => import("./admin/screens/ProductFormScreen.web"))
);

export const SubscriptionCancellation = lazy(() =>
  retry(
    () =>
      import(
        "./features/SubscriptionCancellation/SubscriptionCancellationScreen"
      )
  )
);

export const SubscriptionPrice = lazy(() =>
  retry(
    () =>
      import(
        "../features/Catalogue/screens/Subscription/SubscriptionPriceScreen"
      )
  )
);

export const ProductQuestion = lazy(() =>
  retry(
    () => import("../features/Catalogue/screens/Product/ProductQuestionScreen")
  )
);

export const CatalogueCart = lazy(() =>
  retry(() => import("../features/Catalogue/screens/Cart/CatalogueCartScreen"))
);
export const CatalogueQuantity = lazy(() =>
  retry(
    () => import("../features/Catalogue/screens/Cart/CatalogueQuantityScreen")
  )
);
export const CatalogueCartPayment = lazy(() =>
  retry(
    () =>
      import(
        "../features/Catalogue/screens/Checkout/CatalogueCartPaymentScreen"
      )
  )
);
export const CheckoutReview = lazy(() =>
  retry(() => import("./features/Checkout/screens/CheckoutReviewScreen.web"))
);

export const ConsumerValidation = lazy(() =>
  retry(
    () => import("./features/Checkout/screens/ConsumerValidationScreen.web")
  )
);
export const PaymentRequestReview = lazy(() =>
  retry(
    () =>
      import(
        "../features/Catalogue/screens/PaymentRequest/PaymentRequestReviewScreen"
      )
  )
);
export const PaymentResult = lazy(() =>
  retry(
    () =>
      import("../features/Catalogue/screens/PaymentResult/PaymentResultScreen")
  )
);

export const CatalogueMoreInfo = lazy(() =>
  retry(
    () =>
      import("../features/Catalogue/screens/MoreInfo/CatalogueMoreInfoScreen")
  )
);

export const CatalogueReviews = lazy(() =>
  retry(
    () => import("../features/Catalogue/screens/Reviews/CatalogueReviewsScreen")
  )
);
export const CatalogueReviewForm = lazy(() =>
  retry(
    () =>
      import("../features/Catalogue/screens/Checkout/CatalogueReviewFormScreen")
  )
);

export const FulfillmentOptionsList = lazy(() =>
  retry(
    () =>
      import(
        "../features/FulfillmentOptions/screens/FulfillmentOptionsListScreen.web"
      )
  )
);

export const Consumer = lazy(() =>
  retry(() => import("./features/Consumer/screens/ConsumerScreen"))
);

export const ConsumerDeliveryInfoForm = lazy(() =>
  retry(
    () =>
      import("./features/Consumer/screens/ConsumerDeliveryInfoFormScreen.web")
  )
);
export const ConsumerDeliverySelection = lazy(() =>
  retry(
    () =>
      import("./features/Consumer/screens/ConsumerDeliverySelectionScreen.web")
  )
);

export const CatalogueDiscountForm = lazy(() =>
  retry(
    () =>
      import(
        "../features/Catalogue/screens/Discount/CatalogueDiscountFormScreen"
      )
  )
);

export const Paylink = lazy(() =>
  retry(() => import("../features/Catalogue/screens/Paylink/PaylinkScreen"))
);

export const PaylinkPayment = lazy(() =>
  retry(
    () => import("../features/Catalogue/screens/Paylink/PaylinkPaymentScreen")
  )
);

export const NotFound = lazy(() =>
  retry(() => import("./components/NotFound"))
);
