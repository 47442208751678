import { useEffect } from "react";

import { captureException } from "cuanto/services/Sentry.web";

export default function useMessageListener(callback: (data: Record<string, string>) => void) {
  useEffect(() => {
    const listenerFn = (event: MessageEvent<string> | Event) => {
      try {
        if ("data" in event) {
          const parsedData = JSON.parse(event.data);
          callback(parsedData);
        } else {
          throw new Error("Preview WebView message is missing data")
        }
      } catch (err) {
        captureException(err, event)
      }
    };

    const isAndroid = /android/i.test(navigator.userAgent);

    const evtListener = isAndroid ? document : window;

    evtListener.addEventListener("message", listenerFn)

    return () => evtListener.removeEventListener("message", listenerFn);

  }, [callback]);
}
