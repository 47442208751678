import React from "react";
import { View, StyleSheet } from "react-native";

import { Layout } from "../constants";
import ClickableIcon from "./ClickableIcon";
import Colors from "../constants/Colors";

type Props = {
  name?: string;
  iconBackground?: string;
  iconSize?: number;
  visible?: boolean;
  width?: number;
  component?: any;
};

const Icon = ({
  name,
  visible,
  iconBackground,
  iconSize = Layout.defaultIconSize,
  width = Layout.defaultIconSize,
  component,
}: Props) => {
  if (visible) {
    const iconStyles = [styles.icon];

    if (iconBackground) {
      iconStyles.push({ backgroundColor: iconBackground });
    }

    if (iconSize) {
      iconStyles.push({
        paddingTop: Layout.normalize(5) * Layout.mscale,
        height: width - Layout.normalize(5) * Layout.mscale,
        width: width,
      });
    }

    return (
      <View style={iconStyles}>
        <ClickableIcon
          IconComponent={component}
          size={iconSize ? iconSize : width}
          iconColor={Colors.primary}
          name={name}
          onPress={() => null}
        />
      </View>
    );
  }

  return <View style={styles.icon} />;
};

export default Icon;

const styles = StyleSheet.create({
  icon: {
    justifyContent: "center",
    alignItems: "center",
  },
});
