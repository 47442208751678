import React from "react";

import { StyleSheet, View, ActivityIndicator } from "react-native";

export default class LoadingView extends React.Component {
  render() {
    return (
      <View testID="loading" style={styles.loadingView}>
        <ActivityIndicator style={styles.loadingProgressBar} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  loadingView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingProgressBar: {
    height: 20,
  },
});
