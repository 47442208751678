import React, { Component } from "react";

import styled from "styled-components/native";

import StyledText from "./StyledText";
import StyledInput from "./StyledInput";

import Layout from "../constants/Layout";
import Colors from "../constants/Colors";

interface Props {
  testID: string;
  placeholder: string;
  headerText: string;
  type: string;
  // types used in custom input without keyboard
  value: string;
  // types used on TextInput components
  keyboardType?: string;
  defaultValue?: string;
  errorNote?: string;
  multiline?: boolean;
  autoFocus?: boolean;
  onChangeText?: Function;
  format: Function;
}

class DisplayInput extends Component<Props> {
  renderInput(props) {
    const { type, testID, value, placeholder, format, reference } = props;
    if (type === "numericNoKeyboar") {
      return (
        <Integers testID={testID}>
          {value == 0 ? (
            <Placeholder>{placeholder}</Placeholder>
          ) : format ? (
            format(value)
          ) : (
            value
          )}
        </Integers>
      );
    }

    if (type === "text") {
      return (
        <StyledInput
          testID={testID}
          ref={reference}
          style={[inputStyles]}
          underlineColorAndroid="transparent"
          {...props}
        />
      );
    }

    // ..other expected types that use the same look and feel

    return null;
  }

  renderError(msg) {
    return <Error>{msg}</Error>;
  }

  render() {
    const { headerText, errorNote } = this.props;

    return (
      <Container>
        <Label>{headerText}</Label>
        <Wrapper>
          <Value>{this.renderInput(this.props)}</Value>
          {typeof errorNote === "string" ? this.renderError(errorNote) : null}
        </Wrapper>
      </Container>
    );
  }
}

export default DisplayInput;

const inputStyles = {
  fontFamily: Layout.fontRegular,
  textAlign: "center",
  color: Colors.font.main,
  fontSize: Layout.mSize(22),
  height: Layout.vSize(35),
  right: 0,
  flex: 1,
  left: 0,
};

const Container = styled.View`
  height: ${Layout.displayInputHeight}px;
  flex-direction: column;
  background-color: ${Colors.white};
  padding-horizontal: ${Layout.vSize(20)}px;
  display: flex;
  width: 100%;
  align-self: flex-start;
`;

const Label = styled(StyledText)`
  font-family: ${Layout.fontLight};
  color: ${Colors.mainTextColor};
  padding-horizontal: 0px;
  line-height: ${Layout.vSize(22)}px;
  margin-top: ${Layout.vSize(15)}px;
  font-size: ${Layout.mSize(16)}px;
  height: ${Layout.vSize(22)}px;
`;

const Wrapper = styled.View`
  justify-content: center;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const Value = styled.View`
  justify-content: center;
  flex-direction: row;
  display: flex;
`;

const Integers = styled(StyledText)`
  font-family: ${Layout.fontRegular};
  color: ${Colors.font.main};
  font-size: ${Layout.mSize(48)}px;
`;

const Placeholder = styled(StyledText)`
  font-family: ${Layout.fontRegular};
  color: #ddd;
  font-size: ${Layout.mSize(22)}px;
`;

const Error = styled(StyledText)`
  font-family: ${Layout.fontLight};
  text-align: center;
  font-size: ${Layout.mSize(13)}px;
  color: ${Colors.font.tertiary};
  margin-top: ${Layout.vSize(10)}px;
`;
