import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { setTracker } from "cuanto/features/Cart/actions";

/**
 *
 * @param tracker value to store in redux to be sent backend during create cart
 */
export default function useTracker(tracker?: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (tracker) dispatch(setTracker(tracker));
  }, [tracker, dispatch]);
}
