import { createAction } from "@reduxjs/toolkit";
import { CuantoAPI } from "../../api/CuantoAPI";
import { captureException } from "../../services/Sentry";

import { FulfillmentOption } from "../../types/FulfillmentOption";

// Single action to signal a request is being made
export const waitForServer = createAction("fulfillmentOptions/waitForServer");

// Get feature flag
export const resetFulfillmentsFeatureFlag = createAction(
  "fulfillmentOptions/resetFulfillmentsFeatureFlag"
);

export const resetFulfillmentOptions = createAction(
  "fulfillmentOptions/resetFulfillmentOptions"
);

export const selectFulfillmentOption = createAction<{} | FulfillmentOption>(
  "fulfillmentOptions/selectFulfillmentOption"
);

export const resetSelectedFulfillmentOption = createAction(
  "fulfillmentOptions/resetSelectedFulfillmentOption"
);

export const addFulfillmentOption = createAction(
  "fulfillmentOptions/addFulfillmentOption"
);

export const updateFulfillmentOption = createAction(
  "fulfillmentOptions/fulfillmentOptionUpdate"
);

export const fulfillmentOptionDelete = createAction(
  "fulfillmentOptions/fulfillmentOptionDelete"
);

function apiClient(getState) {
  const token = getState().userAccount.authenticationToken;
  return new CuantoAPI(token);
}

export const async = {
  fetchFulfillmentFeatureFlag: () => (dispatch, getState) => {
    const api = apiClient(getState);
    return api.fetchFulfillmentFeatureFlag().then(({ data }) => {
      dispatch(resetFulfillmentsFeatureFlag(data.enabled));
    });
  },

  setFulfillmentFeatureFlag: (value) => (dispatch, getState) => {
    dispatch(resetFulfillmentsFeatureFlag(value));

    const api = apiClient(getState);
    return api.setFulfillmentFeatureFlag(value);
  },

  fetchFulfillmentOptions: () => (dispatch, getState) => {
    dispatch(waitForServer(true));
    const api = apiClient(getState);
    return api
      .fetchFulfillmentOptions()
      .then(({ data }) => {
        dispatch(resetFulfillmentOptions(data));
      })
      .catch(captureException);
  },

  createFulfillmentOption: (fulfillmentOption) => (dispatch, getState) => {
    dispatch(waitForServer(true));
    const api = apiClient(getState);
    return api
      .createFulfillmentOption(fulfillmentOption)
      .then(({ data }) => {
        dispatch(addFulfillmentOption(data));
        dispatch(waitForServer(false));
      })
      .catch(captureException);
  },

  updateFulfillmentOption: (id, fulfillmentOption) => (dispatch, getState) => {
    dispatch(waitForServer(true));
    const api = apiClient(getState);
    return api
      .updateFulfillmentOption(id, fulfillmentOption)
      .then(({ data }) => {
        dispatch(updateFulfillmentOption(data));
        dispatch(waitForServer(false));
      })
      .catch(captureException);
  },

  deleteFulfillmentOption: (id) => (dispatch, getState) => {
    dispatch(waitForServer(true));
    const api = apiClient(getState);
    return api
      .deleteFulfillmentOption(id)
      .then(({ data }) => {
        dispatch(fulfillmentOptionDelete({ id }));
        dispatch(waitForServer(false));
      })
      .catch(captureException);
  },
};
