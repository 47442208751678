import React, { useState } from "react";

import { LoadingView } from "cuanto/components";
import useCatalogue from "cuanto/package/Catalogue/contexts/CatalogueContext";

import Preview from ".";
import useMessageListener from "./useMessageListener";

type ScreenProps = { username: string };
export default function PreviewScreen(props: ScreenProps) {
  const urlParams = new URLSearchParams(window.location.search);
  const componentName = urlParams.get("name");

  const [componentProps, setComponentProps] = useState(
    Object.fromEntries(urlParams.entries())
  );

  useMessageListener(setComponentProps);

  const { catalogueData, priceGroups } = useCatalogue();

  if (!catalogueData)
    return (
      <div className="flex h-screen justify-center">
        <LoadingView />
      </div>
    );

  const PreviewComponent = getPreviewComponent(componentName);

  return (
    <div id="preview-container">
      <PreviewComponent
        catalogueData={catalogueData}
        priceGroups={priceGroups}
        {...componentProps}
      />
    </div>
  );
}

function getPreviewComponent(componentName: string | null) {
  switch (componentName) {
    case "catalogue":
      return Preview.Catalogue;
    case "banner":
      return Preview.Banner;
    case "links":
      return Preview.Links;
    case "bio":
      return Preview.Bio;
    case "colors":
      return Preview.Colors;
    default:
      return Preview.Unknown;
  }
}
