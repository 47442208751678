import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import values from "lodash/values";

import { Product as MainProduct, Inventory } from "../types/Product";

type Product = Pick<MainProduct, "inventory" | "uuid" | "variant_options">;

export function getProductQuantity(product: Product) {
  const inventory: Inventory = get(product, "inventory");
  const quantity = get(inventory, "quantity", 0);
  return quantity;
}

export function hasProductInventory(product: Product) {
  const quantity = getProductQuantity(product);
  return quantity > 0;
}

export function hasProductInventoryEnabled(product: Product) {
  const inventory: Inventory = get(product, "inventory");
  const enabled = get(inventory, "enabled", false);
  return enabled;
}

export function hasInventory(product: Product, productGroups = {}) {
  // Product has no variants
  if (isEmpty(productGroups) || isEmpty(productGroups[product.uuid])) {
    // Inventory is disabled on Product
    if (!hasProductInventoryEnabled(product)) {
      return true;
    }

    return hasProductInventory(product);
  }

  // Product has variants
  const quantity = productGroups[product.uuid].reduce((acc, variant) => {
    // Inventory is disabled on Product variant
    if (!hasProductInventoryEnabled(variant)) {
      return acc + 1;
    }

    const quantity = getProductQuantity(variant);
    if (quantity >= 0) {
      return acc + quantity;
    } else {
      return acc;
    }
  }, 0);

  return quantity > 0;
}

export function variantsList(product: Product) {
  const options = get(product, "variant_options", []);
  return !isEmpty(options) ? `・${values(options).join(", ")}` : "";
}

export function combinationsOptions(combinations: any) {
  const variants = new Map();
  const inventory = [];

  combinations.forEach((combination) => {
    if (combination.variant_options) {
      inventory.push({
        uuid: combination.uuid,
        quantity: combination.inventory,
        tracking_status: combination.tracking_status,
        combination: combination.variant_options
          .map((option) => option.name)
          .join(", "),
      });

      combination.variant_options.forEach((option) => {
        const variantName = option.variant.name;
        variants.set(
          variantName,
          (variants.get(variantName) || new Set()).add(option.name)
        );
      });
    }
  });

  return { variants, inventory };
}

export function available(inventory: Inventory) {
  const quantity = get(inventory, "quantity");
  return quantity === 1 ? "disponible" : "disponibles";
}
