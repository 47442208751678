import Constants from "expo-constants";
import { Platform, Dimensions, PixelRatio } from "react-native";

const StatusBarCurrentHeight = Constants.statusBarHeight || 0;
const windowHeight = Dimensions.get("window").height;
const windowWidth = Dimensions.get("window").width;

const isWeb = Platform.OS === "web";
const isIOS = Platform.OS === "ios";
const isAndroid = Platform.OS === "android";

const height = isIOS ? windowHeight : windowHeight - StatusBarCurrentHeight;
// const height = Dimensions.get('window').height;

const MAX_SCREEN_WIDTH = 600;

const width = isWeb
  ? windowWidth < MAX_SCREEN_WIDTH
    ? windowWidth
    : MAX_SCREEN_WIDTH
  : windowWidth;

const pixelRatio = isWeb ? 1 : PixelRatio.get();

// based on iPhone 6 scale
const hscale = isWeb ? 1 : width / 375;
const vscale = isWeb ? 1 : height / 667;
const mscale = isWeb ? 1 : Math.min(hscale, vscale);
// id card dimentions: (54mm x 86mm) = 0.628
// id card dimentions + borders device: (153.07 x 280.35)
const cardIdGoldenRatio = 0.5459960763331564;

// Adjust sizing based on pixel density of device
const normalize = (size) => {
  if (isIOS) {
    return Math.ceil(PixelRatio.roundToNearestPixel(size));
  } else if (isAndroid) {
    return Math.ceil(PixelRatio.roundToNearestPixel(size)) - 2;
  } else {
    return size;
  }
};

const mSize = (size) => normalize(size) * mscale;
const vSize = (size) => normalize(size) * vscale;
const hSize = (size) => normalize(size) * hscale;
// Assumes 16px size in mobile-app/web/index.html
const rSize = (size) => `${size / 16}rem`;

const headerHeight = vSize(160);

const numericKeypadHeight = vSize(280);
const buttonHeight = vSize(64);
const smallButtonHeight = vSize(48);
const tabBarHeight = vSize(40);
const statusBarAdjustment = isIOS ? 0 : -1 * StatusBarCurrentHeight;
const webBottomButtonHeight = 100;

export default {
  MAX_SCREEN_WIDTH,

  fontBold: isWeb ? "Inter" : "inter-bold",
  fontBoldItalic: isWeb ? "Inter" : "inter-bold-italic",
  fontItalic: isWeb ? "Inter" : "inter-italic",
  fontRegular: isWeb ? "Inter" : "inter-regular",
  fontSemiBold: isWeb ? "Inter" : "inter-semibold",
  fontSemiBoldItalic: isWeb ? "Inter" : "inter-semibold-italic",
  fontLight: isWeb ? "Inter" : "inter-regular", // TODO: Remove references
  fontRegularLight: isWeb ? "Inter" : "inter-regular", // TODO: Remove references

  // Global values
  deviceHeight: height,
  deviceWidth: width,

  // magic numbers
  cardIdGoldenRatio,
  pixelRatio,
  // TODO: Remove these, all exports should use mSize vs normalize * mscale
  hscale: isWeb ? 1 : hscale,
  vscale: isWeb ? 1 : vscale,
  mscale: isWeb ? 1 : mscale,

  normalize: normalize,

  // components info
  numericKeypadHeight,
  displayInputHeight: vSize(136),
  headerHeight: headerHeight,
  buttonHeight,
  webBottomButtonHeight,
  smallButtonHeight,
  idCardHeight: width * cardIdGoldenRatio,
  idCardWidth: width,
  inputHeight: vSize(100),
  labelHeight: vSize(22),
  // this is a ghetto fix, in android navbar is offset by status bar
  statusBarAdjustment: statusBarAdjustment,
  statusBarHeight: StatusBarCurrentHeight,
  tabBarHeight,

  // recipient search
  searchContainerHeight: vSize(80),
  resultsHeaderHeight: vSize(30),
  searchBarHeight: vSize(50),
  fontSize: (size) => mSize(size),
  iconSize: (size) => mSize(size),

  sizes: {
    XXXL: 72,
    XXL: 60,
    XL: 48,
    L: 36,
    M: 30,
    S: 24,
    XS: 21,
    XXS: 18,
  },
  mSize: mSize,
  vSize: vSize,
  hSize: hSize,
  rSize: rSize,

  isSmallDevice: width < 375,

  // remove when possible:
  // TODO: this size need to be checked
  bottomBtnHeight: buttonHeight,
  window: { width, height },

  // Used for performance in RecipientList
  recipientListItemHeight: vSize(64),
  flagLogoHeight: mSize(24),
  flagLogoWidth: mSize(36),
  listItemIconSize: mSize(50),
  defaultIconSize: mSize(50),
};
