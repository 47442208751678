import React, { useEffect, useState } from "react";

import { navigate } from "@reach/router";
import { connect, ConnectedProps } from "react-redux";

import { CartReduxState } from "cuanto/features/Cart";
import { NotFound } from "cuanto/web-app/Screens";

import useAPI from "package/hooks/APIContext";

import useTracker from "../../hooks/useTracker";
import LoadingView from "../components/LoadingView";
import useCatalogue from "../contexts/CatalogueContext";
import { CatalogueData, Price, Product } from "../types";
import CatalogueLink from "./CatalogueLink";

type RouteProps = { username: string; short_uuid: string; tracker?: string };

function CatalogueLinkScreen({
  username,
  short_uuid,
  tracker,
  ...props
}: ReduxProps & RouteProps) {
  const api = useAPI();

  const [catalogueProduct, setCatalogueProduct] =
    useState<CatalogueData | null>(null);
  const [prices, setPrices] = useState<Price[]>([]);

  const { addToCart, isLoading } = useCatalogue();

  useTracker(tracker);

  useEffect(() => {
    async function getCatalogueProduct() {
      const { data } = await api.get<CatalogueData>(
        `/${username}/products/${short_uuid}`
      );
      setCatalogueProduct(data);
    }

    async function getPrices() {
      const { data } = await api.get<{
        prices: Price[];
        show_savings_across_prices: boolean;
      }>(`/ecommerce/${username}/products/${short_uuid}/prices`);
      setPrices(data.prices);
    }

    getCatalogueProduct();
    getPrices();
  }, [username, short_uuid, api]);

  if (!catalogueProduct || !prices.length || isLoading) return <LoadingView />;

  const { products, merchant } = catalogueProduct;

  const product = products.find((product) => product.short_uuid === short_uuid);
  const productVariants = products.filter((p) => p.product_group_uuid !== null);

  if (!product) return <NotFound />;

  function onGoToCart() {
    navigate(`/${username}/cart`, { state: { merchant } });
  }

  function onAddToCart(product: Product) {
    const { redirectTo } = addToCart(product);
    const shortUuid = (product.product_group_uuid || product.uuid).slice(-6);
    switch (redirectTo) {
      case "prices":
        navigate(`/${username}/p/${shortUuid}/prices`);
        break;
      case "cart":
        navigate(`/${username}/cart`);
        break;
      case "catalogue":
        navigate(`/${username}`);
        break;
    }
  }

  function onAskQuestions() {
    navigate(`/${username}/p/${short_uuid}/questions`);
  }

  function onBackPress() {
    // if there is state history, means we navigated from cuanto
    if (window.history.state) {
      window.history.back();
    } else {
      // no cuanto history to go back (from mercadito, or shared product link, etc...)
      navigate(`/${username}`);
    }
  }

  const storeClosed = catalogueProduct.settings.some(
    (s) => s.key === "store_closed" && s.value === "t"
  );

  return (
    <CatalogueLink
      merchant={merchant}
      prices={prices}
      product={product}
      productVariants={productVariants}
      onAskQuestions={onAskQuestions}
      cartQuantity={props.quantity}
      storeClosed={storeClosed}
      onAddToCart={onAddToCart}
      onGoToCart={onGoToCart}
      onBackPress={onBackPress}
    />
  );
}

const mapStateToProps = ({ cart }: { cart: CartReduxState }) => ({
  quantity: cart.quantity,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CatalogueLinkScreen);
