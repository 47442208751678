import React from "react";
import { TextInput, TextInputProps } from "react-native";

import styled from "styled-components/native";

import Layout from "../constants/Layout";

interface Props extends TextInputProps {
  name?: string;
}

const StyledInput = React.forwardRef(
  ({ style, ...rest }: Props, ref: React.Ref<TextInput>) => {
    // required for `react-native-web`
    const dataSet = rest.name ? { dataSet: { name: rest.name } } : {};

    return (
      <Input
        {...rest}
        {...dataSet}
        ref={ref}
        // Force to `false` #472
        allowFontScaling={false}
        style={[{ fontFamily: Layout.fontRegular }, style]}
      />
    );
  }
);

const Input = styled.TextInput``;

export default StyledInput;

export { Props };
