import React, { ComponentProps } from "react";

type Props = ComponentProps<"select"> & { error: string };
export default function Select({ children, error, ...rest }: Props) {
  return (
    <>
      <div className="inline-block relative mb-2">
        <select
          {...rest}
          className={`block appearance-none rounded-md w-full border border-solid border-white-500 px-4 py-3 pr-8 leading-tight focus:outline-none text-base font-sans ${
            error
              ? "bg-red-50 border-red-600 text-red-600"
              : "bg-white-100 text-gray-900"
          }`}
        >
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2 text-gray-400">
          <ChevronDown />
        </div>
      </div>
      {error && <div className="mb-2 text-red-600">{error}</div>}
    </>
  );
}

function ChevronDown() {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" width="1em" height="1em">
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
}
