import { StyleSheet } from "react-native";
import Colors from "./Colors";
import Layout from "./Layout";

const containerHeight = Layout.deviceHeight;

const GlobalStyles = StyleSheet.create({
  screen: {
    backgroundColor: Colors.mainBG,
    flex: 1,
  },
  container: {
    height: containerHeight,
    flex: 1,
  },
  fixedContainer: {
    width: Layout.deviceWidth,
    position: "absolute",
    top: 0,
  },
  content: {
    flex: 1,
    height: containerHeight,
  },
  scrollContainer: {
    height: Layout.window.height,
  },
  cameraContainer: {
    flex: 1,
  },
  avoidingView: {
    flex: 1,
    height: Layout.window.height,
    justifyContent: "flex-end",
  },
  filled: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
});

export default GlobalStyles;
