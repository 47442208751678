import React from "react";
import {
  View,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  ViewStyle,
} from "react-native";

import * as Svg from "react-native-svg";

import StyledText from "./StyledText";
import Svgx from "./Svgx";

import { Layout, Colors } from "../constants";

import cuantoIconsLib from "../icons/cuantoIconsLib.json";

const ballHeight = Layout.normalize(35) * Layout.mscale;
const numSteps = 3;

// TODO: wtf use an array
const iconList = {
  1: cuantoIconsLib.stepOne,
  2: cuantoIconsLib.stepTwo,
  3: cuantoIconsLib.stepThree,
};

const generateStepStyles = (pos, step) => {
  const currentColor = pos <= step ? Colors.primary : Colors.gray;
  const styles = {};

  styles[`circle-path-${pos}`] = { fill: currentColor };
  styles[`number-path-${pos}`] = { fill: currentColor };
  styles[`checkmark-path-${pos}`] = { fill: currentColor };

  // select checked or step number
  styles[`checkmark-path-${pos}`].display = pos >= step ? "none" : "block";
  styles[`number-path-${pos}`].display = pos < step ? "none" : "block";

  return styles;
};

const renderStep = (pos, step) => {
  const stepStyles = generateStepStyles(pos, step);

  const iconProps = {
    component: Svg,
    styles: {
      ...stepStyles,
      height: ballHeight,
      width: ballHeight,
    },
    data: iconList[pos],
  };

  return (
    <View style={styles.stepContainerBall}>
      <Svgx {...iconProps} />
    </View>
  );
};

// get 80% width of the screen, minus balls width, divided by 4
const separatorWidth =
  (Layout.window.width * 0.8 - ballHeight * numSteps) / (numSteps - 1);
const renderSeparator = (pos, step) => {
  const separatorStyles = { width: separatorWidth, height: 1 };

  separatorStyles.backgroundColor = step > pos ? Colors.primary : Colors.gray;

  return <View style={separatorStyles} />;
};
const renderIndicator = (step) => {
  return (
    <View style={styles.progressContainer}>
      {renderStep(1, step)}
      {renderSeparator(1, step)}
      {renderStep(2, step)}
      {renderSeparator(2, step)}
      {renderStep(3, step)}
    </View>
  );
};

type Props = {
  progressIndicator?: number;
  headerTitleStyles?: ViewStyle;
  smallTextStyles?: ViewStyle;
  containerStyles?: ViewStyle;
  smallText?: string;
  smallerText?: string;
  bigText?: string;
  width?: number;
  goBack?: Function;
};

const Header = ({
  bigText,
  smallText,
  smallerText,
  progressIndicator,
  containerStyles = {},
  headerTitleStyles = {},
  smallTextStyles = {},
  goBack,
}: Props) => {
  const containerStylesDef = [styles.headerContainer, containerStyles];
  const headerTitleStylesDef = [styles.headerTitle, headerTitleStyles];
  const smallTextStylesDef = [styles.smallText, smallTextStyles];

  if (progressIndicator)
    smallTextStylesDef.push({
      paddingTop: Layout.normalize(30) * Layout.vscale,
    });

  return (
    <View style={containerStylesDef}>
      <View style={styles.progressWrapper}>
        {goBack ? (
          <TouchableWithoutFeedback onPress={goBack}>
            <View style={styles.backBtnWrapper}>
              <StyledText style={styles.backArrow}>‹</StyledText>
            </View>
          </TouchableWithoutFeedback>
        ) : null}
        {progressIndicator ? renderIndicator(progressIndicator) : null}
        <View style={styles.filler} />
      </View>
      {bigText ? (
        <StyledText style={headerTitleStylesDef}>{bigText}</StyledText>
      ) : null}
      {smallText ? (
        <StyledText style={smallTextStylesDef}>{smallText}</StyledText>
      ) : null}
      {smallerText ? (
        <StyledText style={styles.smallerText}>{smallerText}</StyledText>
      ) : null}
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  progressContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    height: Layout.normalize(20) * Layout.vscale,
    width: "80%",
  },
  headerContainer: {
    justifyContent: "center",
    flexDirection: "column",
    height: Layout.headerHeight,
    marginTop: Platform.OS === "ios" ? Layout.normalize(24) * Layout.vscale : 0,
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  headerTitle: {
    fontFamily: Layout.fontRegular,
    textAlign: "center",
    paddingRight: Layout.normalize(15) * Layout.hscale,
    paddingLeft: Layout.normalize(15) * Layout.hscale,
    marginTop: Layout.normalize(15) * Layout.vscale,
    marginBottom: Layout.normalize(25) * Layout.vscale,
    color: Colors.font.main,
    width: "100%",
    fontSize: Layout.normalize(36) * Layout.mscale,
  },
  smallText: {
    fontFamily: Layout.fontRegular,
    textAlign: "center",
    paddingRight: Layout.normalize(15) * Layout.hscale,
    paddingLeft: Layout.normalize(15) * Layout.hscale,
    marginTop: -1 * Layout.normalize(15) * Layout.vscale,
    color: Colors.font.main,
    width: "100%",
    fontSize: Layout.normalize(18) * Layout.mscale,
  },
  smallerText: {
    fontFamily: Layout.fontLight,
    textAlign: "center",
    paddingRight: Layout.normalize(15) * Layout.hscale,
    paddingLeft: Layout.normalize(15) * Layout.hscale,
    color: Colors.font.main,
    width: "100%",
    fontSize: Layout.normalize(18) * Layout.mscale,
  },
  stepContainerBall: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: ballHeight,
    height: ballHeight,
  },
  progressWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  backBtnWrapper: {
    width: Layout.normalize(20) * Layout.hscale,
    paddingLeft: Layout.normalize(5) * Layout.hscale,
    height: ballHeight,
  },
  filler: {
    width: Layout.normalize(25) * Layout.hscale,
  },
  backArrow: {
    color: Colors.primary,
    fontSize: Layout.normalize(36) * Layout.mscale,
    marginTop: -14,
  },
});
