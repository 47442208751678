import React from "react";

export type Props = {
  price: number;
  priceNotDiscounted: number | null;
  fontSize: number;
};

export default function DiscountPercentBadge({
  price,
  priceNotDiscounted,
  fontSize,
}: Props) {
  if (!priceNotDiscounted || price >= priceNotDiscounted) return null;

  const percent = ((priceNotDiscounted - price) / priceNotDiscounted) * 100;

  return (
    <span
      className="bg-primary-300 text-primary-700 m-1 rounded py-0.5 px-1 font-semibold"
      style={{ fontSize }}
    >
      {`-${percent.toFixed(0)}%`}
    </span>
  );
}
