import React from "react";
import { TouchableOpacity } from "react-native";

import styled, { css } from "styled-components/native";
import { Ionicons } from "@expo/vector-icons";

import Text from "./Text";

import Layout from "../constants/Layout";
import Colors from "../constants/Colors";

interface RowProps {
  keys: number[];
  ownProps: any;
}

function Row({ keys, ownProps }: RowProps) {
  return (
    <StyledRow>
      {keys.map((key) => Key({ textDisplayed: key, ownProps }))}
    </StyledRow>
  );
}

interface KeyProps {
  textDisplayed: number | string | JSX.Element;
  keyPressed?: string;
  border?: boolean;
  ownProps: any;
}

function Key({
  textDisplayed,
  border = true,
  keyPressed = textDisplayed,
  ownProps,
}: KeyProps) {
  const onPress =
    keyPressed !== "helpKey"
      ? ownProps && ownProps.handleKeyPress.bind(this, keyPressed)
      : ownProps && ownProps.handleHelpKeyPress;

  return (
    <TouchableOpacity
      testID={ownProps && ownProps.testID + "-key-" + keyPressed}
      key={keyPressed}
      onPress={onPress}
    >
      <StyledKey border={border}>
        <KeyText> {textDisplayed} </KeyText>
      </StyledKey>
    </TouchableOpacity>
  );
}

interface Props {
  handleKeyPress: (any) => any;
  showDecimalKey: boolean;
  // deleteKey should be passed even if always the same
  // handleKeyPress is defined outside and uses it as input
  // The client of this class should define it and pass it
  // in order to keep the definition and its use 'close to each other'
  // to avoid breakeage
  deleteKey: string;
  decimalKey: string;
  handleHelpKeyPress?: Function;
  testID: string;
}

export default class NumericKeypad extends React.Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Container testID={this.props.testID}>
        <Row keys={[1, 2, 3]} ownProps={this.props} />
        <Row keys={[4, 5, 6]} ownProps={this.props} />
        <Row keys={[7, 8, 9]} ownProps={this.props} />

        <StyledRow>
          {this.renderBottomLeftKey()}
          <Key textDisplayed={0} border={false} ownProps={this.props} />
          <Key
            textDisplayed={
              <Ionicons
                name="md-backspace"
                size={Layout.mSize(30)}
                color={"rgba(96,100,109, 1)"}
              />
            }
            border={false}
            keyPressed={this.props.deleteKey}
            ownProps={this.props}
          />
        </StyledRow>
      </Container>
    );
  }

  renderBottomLeftKey = () => {
    const { showDecimalKey, decimalKey } = this.props;

    if (showDecimalKey) {
      return (
        <Key textDisplayed={decimalKey} border={false} ownProps={this.props} />
      );
    } else if (this.props.handleHelpKeyPress) {
      return (
        <Key
          textDisplayed={
            <Text>
              {" "}
              <HelpText>Ayuda</HelpText>{" "}
            </Text>
          }
          border={false}
          keyPressed={"helpKey"}
          ownProps={this.props}
        />
      );
    } else {
      return <StyledKey />;
    }
  };
}

const Container = styled.View`
  height: ${Layout.numericKeypadHeight}px;
  justify-content: space-around;
  flex-direction: column;
  background-color: ${Colors.white};
  padding-vertical: ${Layout.vSize(15)}px;
  display: flex;
`;

const StyledRow = styled.View`
  justify-content: space-around;
  align-items: stretch;
  flex-direction: row;
  display: flex;
  flex: 1;
`;

const StyledKey = styled.View`
  width: ${Layout.window.width * 0.25}px; /* 25% */
  padding-vertical: ${Layout.vSize(10)}px;
  margin-bottom: ${Layout.vSize(5)}px;
  height: ${Layout.vSize(60)}px;

  ${(props) =>
    props.border &&
    css`
      /* Ratio ensures consistent line width across
      devices and parts of the screen */
      border-bottom-width: ${1 / Layout.pixelRatio}px;
      border-bottom-color: ${Colors.border.main};
    `}
`;

const KeyText = styled(Text)`
  font-family: ${Layout.fontRegular};
  color: rgba(96, 100, 109, 1);
  text-align: center;
  font-size: ${Layout.mSize(25)}px;
`;

const HelpText = styled(Text)`
  font-size: ${Layout.mSize(16)}px;
`;
