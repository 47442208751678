import { IMAGES_API_URL } from "cuanto/api/config";
import isEmpty from "lodash/isEmpty";

export const hasSelectedOption = (option): boolean => {
  return !isEmpty(option);
};

export const hasDiscount = (selectedCartDiscount): boolean => {
  return !isEmpty(selectedCartDiscount);
};

export const getCheckoutDetails = ({
  selectedCartDiscount,
  selectedFulfillmentOption: option,
  total,
}): {
  hasDiscount: boolean;
  discountTotal: number;
  hasSelectedOption: boolean;
  optionTotal: number;
  itemsTotal: number;
  checkoutTotal: number;
} => {
  const withDiscount = hasDiscount(selectedCartDiscount);
  const discount = withDiscount
    ? (total / 100) * selectedCartDiscount.percentage
    : 0;

  const withFulfillmentOption = hasSelectedOption(option);
  const optionTotal = withFulfillmentOption ? option.price : 0;
  const itemsTotal = total;
  const checkoutTotal = total - discount + optionTotal;

  return {
    hasDiscount: withDiscount,
    hasSelectedOption: withFulfillmentOption,
    optionTotal,
    discountTotal: discount,
    itemsTotal,
    checkoutTotal,
  };
};

// get the last four digits, these four digits are the same for the credit card and token
export const maskedLastFour = (number) => {
  return "•••• " + number.substr(-4);
};

export function resizeImageUrl(
  url: string,
  dimensions: { width: number; height: number }
) {
  // ensure we only resize 'saved' images. if its a base64, leave as is
  return url.startsWith("http")
    ? `${IMAGES_API_URL}/?url=${url}&w=${dimensions.width}&h=${dimensions.height}&output=jpg`
    : url;
}
