import { View, ViewStyle } from "react-native";
import React, { Component } from "react";

import { GlobalStyles } from "../constants";

type Props = {
  style?: ViewStyle;
  children: any;
};

class Container extends Component<Props> {
  containerStyles() {
    let styles = [GlobalStyles.container];
    const { style } = this.props;

    if (Array.isArray(style)) return [...styles, ...style];
    if (style) return [...styles, style];

    return styles;
  }

  render() {
    return <View style={this.containerStyles()}>{this.props.children}</View>;
  }
}

export default Container;
