import React from "react";

import { Link } from "@reach/router";

const Breadcrumb = ({
  children,
  path,
  current = false,
  active = false,
  last = false,
}) => {
  return (
    <>
      <li
        className={`font-normal text-gray-500 text-sm leading-5 pr-2 ${
          active ? "text-primary-500" : ""
        } ${current ? "font-semibold text-gray-800" : ""}`}
      >
        {active ? (
          <Link
            to={path}
            className={`no-underline pr-0 ${active ? "text-primary-500" : ""}`}
          >
            {children}
          </Link>
        ) : (
          children
        )}
      </li>
      {!last && (
        <span className="pr-2 flex items-center">
          <svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.188 7h9.624M7.875 3.063L11.813 7l-3.938 3.938"
              stroke="#5E5F61"
              strokeWidth={1.313}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}
    </>
  );
};

export default Breadcrumb;
