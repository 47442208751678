// https://redux-starter-kit.js.org/api/configureStore

import { pick } from "@reach/router/lib/utils";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import localForage from "localforage";
import get from "lodash/get";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { persistStore, persistReducer } from "redux-persist";

import cart from "../features/Cart/reducers";
import discounts from "./features/Discount/reducers";
import fulfillments from "../features/FulfillmentOptions/reducers";
import sentryMiddleware from "./createSentryMiddleware.web";
import consumer from "./features/Consumer/reducers";
import catalogue from "./reducers";

const routeParams = pick([{ path: "/:username/*" }], window.location.pathname);
const username = get(routeParams, "params.username");

const persistCartConfig = {
  // Partition `localStorage` based on main pathname path
  // (either `username` or `c` at this time, based on Router's routes)
  key: username ? `root-${username}` : "root",
  storage: localForage,
  blacklist: ["uuid"],
};

const persistConsumerConfig = {
  // Single partition for consumer
  key: "consumer",
  storage: localForage,
  // Do not persist anything other than `email`
  whitelist: ["email"],
};

const reducers = {
  // Make sure to check reducers persisted here as they should take into account
  // that there may be items that are no longer valid in our DB
  cart: persistReducer(persistCartConfig, cart.reducer),
  catalogue: catalogue.reducer,
  consumer: persistReducer(persistConsumerConfig, consumer.reducer),
  discounts: discounts.reducer,
  fulfillments: fulfillments.reducer,
};

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  sentryMiddleware,
];

const store = configureStore({
  reducer: reducers,
  middleware,
});

const persistor = persistStore(store);

export { store, persistor };
