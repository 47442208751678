import React from "react";

import styled from "styled-components";

import Layout from "../../../../constants/Layout";

const Breadcrumbs = ({ children }) => {
  return (
    <nav className="crumbs">
      <List>{children}</List>
    </nav>
  );
};

export default Breadcrumbs;

const List = styled.ol`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: ${Layout.mSize(16)}px;
`;
