import { Category } from "cuanto/types/Category";
import { FeatureFlags } from "cuanto/types/FeatureFlags";
import { UserSetting } from "cuanto/types/UserSetting";
import { StoreConfig } from "cuanto/web-app/components/StorePersonalization/types";

export type Currency = "usd" | "crc";

export type Merchant = {
  id: number;
  username: string;
  full_name: string;
  avatar_url: string;
  cellphone: string;
  whatsapp_cellphone: string;
  email: string;

  has_ecommerce_store: boolean;
  currency_code: Currency;
};

type Inventory = {
  enabled: boolean;
  quantity: number;
};

export type Product = {
  price: number;
  currency: Currency;

  name: string;
  description: string;
  short_description: string;
  price_not_discounted: number | null;

  id: number;
  image_url: string;
  images: string[];
  uuid: string;
  short_uuid: string;
  product_group_uuid: string | null;
  published: boolean;
  url: string;
  user_id: number;
  inventory?: Inventory;
  updated_at: Date;
  sale_email: string;

  categories: Array<{
    id: number;
    uuid: string;
    name: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    products: Array<{ uuid: string }>;
  }>;
  google_product_category?: number;
  variant_options: Record<string, string>; // variant : option

  fulfillment_type: FulfillmentType;
  ecommerce_view: EcommerceView;
  benefits: string[];

  draft: boolean;
  default_sort_index: number | null;
};

export type Price = {
  uuid: string;
  amount: number;
  amount_not_discounted: number | null;
  recurring_interval: "day" | "week" | "month" | "year" | null;
  recurring_interval_count: number;
  name: string;
  payment_plan_type: PaymentPlanType;
  installments_count?: number;
  installments: Date[];
  inventory?: Inventory;
  sold_out?: boolean;
};

enum FulfillmentType {
  DELIVERY = "delivery",
  PICKUP = "pickup",
  DIGITAL = "digital",
}
enum EcommerceView {
  DEFAULT = "default",
  SUBSCRIPTIONS = "subscriptions",
}

export enum PaymentPlanType {
  ONE_OFF = "one_off",
  RECURRING = "recurring",
  INSTALLMENT = "installment",
}

export type CatalogueData = {
  products: Product[];
  merchant: Merchant;
  categories: Category[];
  store_config: StoreConfig;
  feature_flags: FeatureFlags;
  settings: UserSetting[];
  reviews_average: number | null;
  reviews_count: number;
  total_transaction_count: number;
  has_fulfillments_enabled: boolean;
};

export type PriceData = Array<Price & { product_uuid: string }>;

export type PriceGroups = Record<string, Price[]>;
