import { RouteComponentProps } from "@reach/router";

import { Category } from "../types/Category";
import { Discount } from "../types/Discount";
import { Merchant } from "../types/Merchant";
import { Product as CuantoProduct } from "../types/Product";

export type Product = CuantoProduct;

export type ScreenProps = RouteComponentProps & {
  username: string;
  category_uuid: string;

  hasFetchingErrored: boolean;
  isLoadingInventory: boolean;
  isCatalogueFetched: boolean;
  storeClosed: boolean;

  merchant: Merchant;
  products: Product[];
  filteredProducts: Product[];
  categories: Category[];
  productGroups: { [key: string]: Product[] };

  total: number;
  quantity: number;
  transactionCountPastThreeMonths: number;

  fulfillmentOptionsSetting: any;
  fulfillmentOptions: any;

  fetchCatalogue: Function;
  addToCart: Function;
  removeFromCart: Function;
  updateProductQuantity: Function;
  catalogueLoadingStart: Function;
  catalogueLoading: Function;
  catalogueLoadingError: Function;
  fetchSocialStats: Function;

  productUuids: any[];
  cartList: any;

  reviewsCount: number;

  selectedCartDiscount: Discount;
};

export type PayForCartPayload = {
  order_uuid: string;
  status: PayForCartStatus;
};

export enum PayForCartStatus {
  SUCCEEDED = "succeeded",
  PENDING = "pending",
  FAILED = "failed",
  REVIEW = "review",
}
