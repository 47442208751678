import React from "react";
import { View } from "react-native";

import indexOf from "lodash/indexOf";
import endsWith from "lodash/endsWith";
import dropRight from "lodash/dropRight";

import SquareCashPaymentAmount from "./SquareCashPaymentAmount";
import NumericKeypad from "../NumericKeypad";

import {
  parts,
  amountAsDecimal,
  amountToCents,
  settings,
} from "../../services/currency";

import { Currency } from "../../types/Payment";
import isEmpty from "lodash/isEmpty";

// Mobile
export const DELETE_KEY = "del";
// Web
export const BACKSPACE_KEY = "Backspace";

export function updateAmountReducer(
  state,
  { type: keyPressed, currency, decimalKey, maxAmountAllowed }
) {
  let { amount } = state;

  switch (keyPressed) {
    case decimalKey:
      // Do not allow more than one `decimalKey`
      amount =
        indexOf(amount, decimalKey) !== -1 ? amount : `${amount}${decimalKey}`;
      break;
    case DELETE_KEY:
    case BACKSPACE_KEY:
      // Delete last digit
      amount = dropRight(amount).join("");
      // Default to "0" if empty
      amount = amount === "" ? "0" : amount;
      // Trim `decimalKey`
      amount = endsWith(amount, decimalKey)
        ? dropRight(amount).join("")
        : amount;
      break;
    default:
      // Default to "" if "0" before adding new digits
      amount = amount === "0" ? "" : amount;
      // amount = amount === "0.0" ? "0." : amount;
      // Add new digit
      amount = `${amount}${keyPressed}`;
      break;
  }

  // Rollback updates if needed
  // Do not allow more than `maxAmountAllowed`
  if (amount > maxAmountAllowed) {
    amount = state.amount;
  }
  // Do not allow more than 99 cents
  // not more than 2 fraction digits
  // Do not let type 00
  const fractionDigits = (amount || "").split(decimalKey)[1];
  if (
    Number(fractionDigits) > 99 ||
    fractionDigits === "00" ||
    (fractionDigits || "").length > 2
  ) {
    amount = state.amount;
  }

  return {
    ...state,
    amount,
  };
}

interface Props {
  amount: string | number;
  currency: Currency;
  grouping: string;
  decimalKey: string;

  updateAmountInCents: Function;
  paymentAmountHeight: Number;
  maxAmountAllowed: Number;
  children: JSX.Element;
}

interface State {
  amount: string | number;
}

// This component represents the Amount + the Keys used to modify it
export default class SquareCashKeyboard extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    let amount = amountAsDecimal({
      amount: props.amount,
      currency: props.currency,
    });
    amount = amount.replace(".00", "");

    this.state = {
      amount: amount,
    };
  }

  componentDidUpdate = prevProps => {
    const { amount, currency } = this.props;
    const { decimal: decimalKey } = settings[currency];

    // We currently only use this for resetting (after adding to Cart)
    if (amount !== prevProps.amount) {
      // Only update internal state when receiving 0 (reset)
      // but preserve 0.0
      if (amount === 0 && this.state.amount !== `0${decimalKey}0`) {
        this.setState({ amount: 0 });
      }
    }
  };

  updateAmount = keyPressed => {
    const { currency, maxAmountAllowed } = this.props;
    const { decimal: decimalKey } = settings[currency];

    this.setState(
      state => {
        let { amount } = updateAmountReducer(state, {
          type: keyPressed,
          currency,
          decimalKey,
          maxAmountAllowed,
        });

        return {
          amount,
        };
      },
      () => {
        const cents = Number(
          amountToCents({
            amount: this.state.amount,
            currency: currency,
          })
        );
        this.props.updateAmountInCents(cents);
      }
    );
  };

  render() {
    const { amount } = this.state;
    const { currency } = this.props;
    const { paymentAmountHeight, children } = this.props;

    const { group: grouping, decimal: decimalKey } = settings[currency];

    const { currency: currencySymbol, integer, fraction } = parts({
      amount: amountToCents({ amount, currency }),
      currency,
    });

    const integers = Number(integer);
    const cents = Number(fraction);

    const amountString = `${amount}` || "";
    let fractionDigits = 0;
    if (decimalKey) {
      fractionDigits = (amountString.split(decimalKey)[1] || "").length;
    }

    return (
      <View>
        <SquareCashPaymentAmount
          currency={currencySymbol}
          integers={
            grouping
              ? amountAsDecimal({ amount: integers * 100, currency })
              : integers
          }
          cents={cents}
          shouldRenderCents={
            endsWith(amountString, `${decimalKey}`) || fractionDigits > 0
          }
          zeroCentClicked={amountString.includes(`${decimalKey}0`)}
          fractionDigits={fractionDigits}
          height={paymentAmountHeight}
        />
        {children}
        <NumericKeypad
          testID="squarecash-code-keypad"
          showDecimalKey={!isEmpty(decimalKey)}
          decimalKey={decimalKey}
          handleKeyPress={this.updateAmount}
          deleteKey={DELETE_KEY}
        />
      </View>
    );
  }
}
