import React from "react";
import { View } from "react-native";

import styled, { css } from "styled-components/native";

import SquareCashCentsText from "./SquareCashCentsText";
import Text from "../Text";

import { Layout, Colors } from "../../constants";

const { XXXL, XXL, XL, L } = Layout.sizes;

interface Props {
  currency: string;
  integers: number;
  cents: number;

  fractionDigits?: number;
  zeroCentClicked?: boolean;
  shouldRenderCents?: boolean;
  readOnly?: boolean;

  hasBackground?: boolean;
  strikethrough?: boolean;
  hideEmptyCents?: boolean;
  height?: Number;
}

export default class SquareCashPaymentAmount extends React.Component<Props> {
  amountIsEmpty = () => {
    const {
      shouldRenderCents,
      integers,
      cents,
      strikethrough = false,
      readOnly = false,
    } = this.props;
    if (readOnly) {
      return false;
    }
    if (shouldRenderCents) {
      return false;
    }
    if (strikethrough) {
      return true;
    }

    return integers === 0 && cents === 0;
  };

  render() {
    const { currency, integers, cents } = this.props;
    const {
      height,
      shouldRenderCents,
      zeroCentClicked,
      fractionDigits,

      hasBackground = true,
      strikethrough = false,
      hideEmptyCents = false,
      readOnly = false,
    } = this.props;

    const canUseBiggerFont = `${integers}`.length < 6;
    const integersSize = canUseBiggerFont ? XXXL : XXL;
    const centsSize = canUseBiggerFont ? XL : L;

    return (
      <Container
        testID="squarecash-amount"
        hasBackground={hasBackground}
        height={height}
      >
        <Wrapper>
          <Amount>
            <StyledCurrency
              testID="squarecash-amount-currency"
              empty={this.amountIsEmpty()}
              fontSize={Layout.mSize(centsSize)}
            >
              {currency}
            </StyledCurrency>

            <Integers
              testID="squarecash-amount-integers"
              empty={this.amountIsEmpty()}
              fontSize={Layout.mSize(integersSize)}
            >
              {integers}
            </Integers>

            <View>
              {!readOnly && shouldRenderCents && (
                <Text testID="squarecash-amount-cents">
                  {zeroCentClicked && (
                    <>
                      <SquareCashCentsText isEmpty={false} size={centsSize}>
                        {"0"}
                      </SquareCashCentsText>
                      <SquareCashCentsText
                        isEmpty={cents === 0}
                        size={centsSize}
                      >
                        {cents}
                      </SquareCashCentsText>
                    </>
                  )}
                  {!zeroCentClicked && cents === 0 && (
                    <SquareCashCentsText isEmpty size={centsSize}>
                      {"00"}
                    </SquareCashCentsText>
                  )}
                  {!zeroCentClicked && cents >= 10 && (
                    <>
                      <SquareCashCentsText isEmpty={false} size={centsSize}>
                        {Math.floor(cents / 10)}
                      </SquareCashCentsText>
                      <SquareCashCentsText
                        isEmpty={fractionDigits !== 2}
                        size={centsSize}
                      >
                        {cents % 10}
                      </SquareCashCentsText>
                    </>
                  )}
                </Text>
              )}
              {/* Show all cents */}
              {(() => {
                if (readOnly && hideEmptyCents && cents === 0) {
                  return null;
                }
                if (readOnly && fractionDigits > 0) {
                  return (
                    <Text testID="squarecash-amount-cents">
                      <SquareCashCentsText
                        isEmpty={strikethrough}
                        size={centsSize}
                      >
                        {cents ? Math.floor(cents / 10) : 0}
                        {cents ? cents % 10 : 0}
                      </SquareCashCentsText>
                    </Text>
                  );
                }
              })()}
            </View>

            {strikethrough && <StrikeThrough />}
          </Amount>
        </Wrapper>
      </Container>
    );
  }
}

const Container = styled.View`
  height: ${Layout.vSize(78)}px;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.hasBackground &&
    css`
      background-color: ${Colors.white};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

const Wrapper = styled.View`
  justify-content: center;
  flex-direction: row;
`;
const Amount = styled.View`
  justify-content: center;
  flex-direction: row;
`;

const StrikeThrough = styled.View`
  border-bottom-width: ${Layout.vSize(4)}px;
  border-bottom-color: ${Colors.placeholder};
  width: 100%;
  position: absolute;
  top: ${Layout.vSize(36)}px;
`;

const StyledCurrency = styled(Text)`
  color: ${Colors.text};
  font-family: ${Layout.fontRegularLight};
  font-size: ${(props) => props.fontSize}px;

  ${(props) =>
    props.empty &&
    css`
      color: ${Colors.placeholder};
      font-family: ${Layout.fontRegularLight};
    `};
`;

const Integers = styled(Text)`
  color: ${Colors.text};
  font-family: ${Layout.fontRegularLight};
  font-size: ${(props) => props.fontSize}px;

  ${(props) =>
    props.empty &&
    css`
      color: ${Colors.placeholder};
      font-family: ${Layout.fontRegularLight};
    `};
`;
