// https://github.com/streamich/react-use/issues/372#issuecomment-500235222

import { useMemo, useEffect } from "react";
import throttle from "lodash/throttle";

export const useThrottleFn = <T extends (...args: any) => any>(
  fn: T,
  ms = 1000,
  options = {}
) => {
  const throttledFn = useMemo(() => {
    return throttle(fn, ms, options);
  }, [fn, ms]);

  // This is because fn can call setState.
  useEffect(() => {
    return () => {
      throttledFn.cancel();
    };
  }, [throttledFn]);

  return throttledFn;
};
