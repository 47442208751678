const Icons = {
  "ios-checkmark-circle-outline": {
    viewBox: "0 0 416 416",
    paths: [
      "M292.1 129.3L167.3 255l-47.2-47.2-17.8 17.8 56 56c2.5 2.5 5.9 4.5 8.9 4.5s6.3-2 8.8-4.4l133.7-134.4-17.6-18z",
      "M208 0C93.1 0 0 93.1 0 208s93.1 208 208 208 208-93.1 208-208S322.9 0 208 0zm0 398.7c-105.1 0-190.7-85.5-190.7-190.7 0-105.1 85.5-190.7 190.7-190.7 105.1 0 190.7 85.5 190.7 190.7 0 105.1-85.6 190.7-190.7 190.7z",
    ],
  },
  "ios-close-circle-outline": {
    viewBox: "0 0 456.800048828125 456.5999755859375",
    paths: [
      "M375.5 81.2C294.3 0 162.6 0 81.3 81.2s-81.2 212.9 0 294.2c81.2 81.2 212.9 81.2 294.2 0s81.2-213 0-294.2zm-12.3 281.9c-74.3 74.3-195.3 74.3-269.6 0-74.3-74.3-74.3-195.3 0-269.6s195.3-74.3 269.6 0c74.4 74.3 74.4 195.3 0 269.6z",
      "M312.6 132.3l-84.4 84.2-84-83.8-11.8 11.8 84 83.8-84 83.8 11.8 11.8 84-83.8 84.4 84.2 11.8-11.8-84.4-84.2 84.4-84.2z",
    ],
  },
  "ios-trash": {
    viewBox: "0 0 288 384",
    paths: [
      "M208 49V29.3C208 13.1 194.9 0 178.7 0h-69.5C93.1 0 80 13.1 80 29.3V49H0v15h21.1l23.6 290.7c0 16.2 13.1 29.3 29.3 29.3h141c16.2 0 29.3-13.1 29.3-29.3L267.6 64H288V49h-80zM95 29.3c0-8.1 6.2-14.3 14.3-14.3h69.5c8.1 0 14.3 6.2 14.3 14.3V49h-98V29.3H95zM90.7 337L80 96h14.5l10.9 241H90.7zm60.3 0h-14V96h14v241zm46.3 0h-14.6l10.8-241H208l-10.7 241z",
    ],
  },
};

export default Icons;
