import { navigate as navigateFn } from "@reach/router";
import normalize from "normalize-url";
import psl from "psl";

const currentLocation = (): string => {
  // Provides safe defaults for Jest, should probably switch to `jsdom`
  return window.location ? window.location.hostname : "localhost";
};

const options = { stripHash: true, stripProtocol: true, stripWWW: true };
const url = psl.parse(normalize(currentLocation(), options));

// Ignore TLDs, mostly for dev purposes
const IGNORE = ["ngrok.io", "ngrok-free.app"];
if (IGNORE.includes(url.domain) || url.subdomain === "staging") {
  url.subdomain = false;
}

export const navigate = (username, path?, options?) => {
  if (url.subdomain) {
    if (path) {
      return navigateFn(`${path}`, options);
    }
    return navigateFn(`/`, options);
  }

  if (path) {
    return navigateFn(`/${username}${path}`, options);
  }
  return navigateFn(`/${username}`, options);
};

export const redirect = (username, path?) => {
  if (url.subdomain) {
    if (path) {
      return `${path}`;
    }
    return `/`;
  }

  if (path) {
    return `/${username}${path}`;
  }
  return `/${username}`;
};

export { url };

export const goBackToMainPage = (username: string) => {
  if (window.history) {
    window.history.back();
  } else {
    navigate(username);
  }
};
