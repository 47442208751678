import React from "react";

// TODO: this is a different type than regular Category as it only has name and uuid
type Category = {
  uuid: string;
  name: string;
};

import Layout from "../../constants/Layout";

const SHARED =
  "rounded-2xl px-2 py-1 m-0.5 font-medium text-sm leading-5 flex-shrink-0";
const SELECTED = "bg-primary-600 text-white-100";
const UNSELECTED = "bg-primary-100 text-primary-800";

type Props = {
  categories: Category[];
  onCategoryClick: (uuid: string) => void;

  selectedCategoryUuid?: string;
  resetSelectedCategoryPillVisbile?: boolean;
};

const CategoriesPills = ({
  categories,
  selectedCategoryUuid,
  resetSelectedCategoryPillVisbile,
  onCategoryClick,
}: Props) => {
  const isWeb = window.innerWidth > Layout.MAX_SCREEN_WIDTH;

  // Desktop view should not scroll sideways. This styling makes the category pills overflow to the next line.
  const containerStyle = `flex overflow-x-auto no-scrollbar cursor-pointer select-none ${
    isWeb ? "flex-wrap" : "flex-nowrap"
  }`;

  return (
    <div className={containerStyle}>
      {resetSelectedCategoryPillVisbile && (
        <ResetSelectedCategoryPill
          selectedCategoryUuid={selectedCategoryUuid}
          onCategoryClick={onCategoryClick}
        />
      )}

      {categories.map(({ uuid, name }, index) => {
        const isSelected = uuid === selectedCategoryUuid;
        const style = `${SHARED} ${isSelected ? SELECTED : UNSELECTED}`;

        return (
          <div
            key={index}
            className={style}
            onClick={() => onCategoryClick(uuid)}
          >
            <span>{name}</span>
          </div>
        );
      })}
    </div>
  );
};

type ResetSelectedCategoryPillProps = {
  selectedCategoryUuid?: string;
  onCategoryClick: (uuid: string) => void;
};

const ResetSelectedCategoryPill = ({
  selectedCategoryUuid,
  onCategoryClick,
}: ResetSelectedCategoryPillProps) => {
  // considered selected if no other catgories selected
  const isSelected = !selectedCategoryUuid || selectedCategoryUuid === "all";
  const style = `${SHARED} ${isSelected ? SELECTED : UNSELECTED}`;

  // resets the selected category
  const onClick = () => onCategoryClick("all");

  return (
    <div className="flex">
      <div className={style} onClick={onClick}>
        <span>Todos</span>
      </div>
      <div className="h-full w-px bg-gray-100 mx-0.5 rounded-md" />
    </div>
  );
};

export default CategoriesPills;
