import axios, { AxiosHeaders, AxiosInstance, AxiosResponse } from "axios";
import Constants from "expo-constants";

import { SetupChecklistResponse } from "../features/Setup/types";
import { Cart } from "../types/Cart";
import { Discount } from "../types/Discount";
import { Payment } from "../types/Payment";
import { ConsumerPaymentMethod } from "../types/PaymentMethod";
import { CartProduct } from "../types/Product";
import { ProductLink } from "../types/ProductLink";
import { SocialStatsResponse } from "../types/SocialStats";
import { UserSetting } from "../types/UserSetting";
import { BootstrappedData as SubscriptionCancellationData } from "../web-app/features/SubscriptionCancellation/types";
import { PayForCartPayload } from "../web-app/types";
import {
  addErrorInterceptor,
  addRequestInterceptor,
  getPaymentProxyUrl,
  urlBasedOnReleaseChannel,
} from "./utils";

export { urlBasedOnReleaseChannel };

let instance: CuantoAPI = null;

// Returns payment object with sanitized credit card number and bin.
function updatePaymentNumber(payment: Payment): Payment {
  // Payment.number should be an optional param, as with the addition of cof
  // we dont always send it (cof_id || number)
  if (payment.number) {
    const sanitizedNumber = payment.number.replace(/\s/g, "");
    payment.number = sanitizedNumber;
    payment.bin = sanitizedNumber.substring(0, 6);
  }
  return payment;
}

type APIOptions = Partial<{ authenticationToken: string; baseURL: string }>;
export class CuantoAPI {
  httpClient: AxiosInstance;
  authenticationToken?: string;

  constructor(options: APIOptions = {}) {
    this.authenticationToken = options.authenticationToken;
    this.httpClient = axios.create({
      baseURL: options.baseURL || urlBasedOnReleaseChannel(),
      headers: this.headers(options.authenticationToken),
    });
    addErrorInterceptor(this.httpClient);
    addRequestInterceptor(this.httpClient);
  }

  static init(authenticationToken: string) {
    instance = new CuantoAPI({ authenticationToken });
    return instance;
  }

  static getInstance() {
    if (instance) {
      return instance;
    } else {
      throw new Error(
        "Class not initialized, did you call `init` with the authorization token?"
      );
    }
  }

  headers = (token?: string): Partial<AxiosHeaders> => {
    const h: Partial<AxiosHeaders> = { "Content-Type": "application/json" };
    if (token) {
      // JWT token
      h["Authorization"] = `Bearer ${token}`;
    }
    return h;
  };

  // Wrap axios.get for easier testing
  get = <T>(url: string, options: any = {}): Promise<AxiosResponse<T>> => {
    return this.httpClient.get<T>(url, options);
  };

  // TODO: confusion-prone, the base gets replaced and dev would not know about it
  getFullUrl = <T>(url: string, options: any = {}) => {
    console.log(`Full URL used in GET request: ${url}`);
    return this.httpClient.get<T>(url, options);
  };

  // Wrap axios.post for easier testing
  post = <T>(
    url: string,
    data?: any,
    options: any = {}
  ): Promise<AxiosResponse<T>> => {
    return this.httpClient.post<T>(url, data, options);
  };

  put = <T>(url: string, params: any = {}, options: any = {}) => {
    return this.httpClient.put<T>(url, params, options);
  };

  delete = <T>(url: string) => {
    return this.httpClient.delete<T>(url);
  };

  createShareProductLink(product_uuid: string) {
    return this.post<ProductLink>("/product_links", {
      product_uuid,
    });
  }

  getSocialStats(username: string) {
    return this.get<SocialStatsResponse>(`/${username}/social_stats`);
  }

  createCart(params: {
    username: string;
    items: Array<CartProduct>;
    consumer_device: typeof Constants;
    discount_code: Discount["code"];
    utm: { [key: string]: string };
    tracker: string | null;
    comments: string | null;
  }) {
    return this.post<Cart>("carts", params);
  }

  // TODO: should we send the urls from the backend?
  // this function potentially uses a proxy to tokenize credit card info
  payForCart(uuid: string, payment: Payment) {
    const proxy = axios.create({
      baseURL: getPaymentProxyUrl(),
      headers: this.headers(this.authenticationToken),
    });
    const updatedPayment = updatePaymentNumber(payment);

    return proxy.post<PayForCartPayload>(
      `carts/${uuid}/payments`,
      updatedPayment
    );
  }

  // function uses a proxy to tokenize credit card info
  payWithCard = (username: string, payment: Payment) => {
    const proxy = axios.create({
      baseURL: getPaymentProxyUrl(),
      headers: this.headers(this.authenticationToken),
    });
    const updatedPayment = updatePaymentNumber(payment);
    return proxy.post(`users/${username}/credit_card_payments`, updatedPayment);
  };

  // TODO: need to update BIN also
  updateConsumerPaymentMethod = (
    consumer_id: number,
    paymentMethod: ConsumerPaymentMethod
  ) => {
    const proxy = axios.create({
      baseURL: getPaymentProxyUrl(),
      headers: this.headers(this.authenticationToken),
    });

    // cleanup cc_number just in case, VGS can't handle whitespace enconding
    if (paymentMethod.consumer_credit_card.number) {
      paymentMethod.consumer_credit_card.number =
        paymentMethod.consumer_credit_card.number.replace(/\s/g, "");
    }

    // route through VGS
    return proxy.put(
      `/consumers/${consumer_id}/consumer_payment_methods/${paymentMethod.id}`,
      paymentMethod
    );
  };

  async getUserSettings(key: string): Promise<UserSetting[]> {
    return await this.get<UserSetting[]>("/users/settings").then(
      (response) => response.data
    );
  }

  async upsertUserSetting(key: string, value: boolean): Promise<UserSetting> {
    return await this.post<UserSetting>("/users/settings", {
      key,
      value,
    }).then((response) => response.data);
  }

  consumerCancelSubscription = (uuid: string, reason?: string) => {
    return this.post<SubscriptionCancellationData>(
      "/subscription_cancellations",
      { uuid, reason }
    );
  };

  fetchSetupChecklist = () => {
    return this.get<SetupChecklistResponse>("setup/checklist");
  };

  createInstagramProfile = (instagram_username: string) => {
    return this.post("instagram/profile", { instagram_username });
  };
}
