import React from "react";

import styled from "styled-components/native";

import { Layout } from "../../constants";

const MAX_WIDTH = 600;

interface Props {
  children: React.ReactNode;
  stylesOverride?: {};
}
/**
 * Despite being called ResponsiveView
 * being used on web, we need to rely on page's native scrolling
 * due to in-app browsers issues
 * using computed dimensions would need a ScrollView (react-native-web's)
 * that would "capture" native scrolling events
 * and it will cause top/bottom bars not hide properly
 */
function CatalogueResponsiveView({ children, stylesOverride }: Props) {
  return (
    <Container testID="responsive-view" style={stylesOverride}>
      {children}
    </Container>
  );
}

// On web we account for viewport units for `width` so to avoid quirks
const Container = styled.View`
  flex: 1;
  width: 100vw;
  max-width: ${MAX_WIDTH}px;
  margin-bottom: ${Layout.webBottomButtonHeight}px;
  padding-bottom: ${Layout.vSize(24)}px;
`;

export default CatalogueResponsiveView;
