export enum SettingValue {
  True = "t",
  False = "f",
}

export const getSettingValue = (v: string): SettingValue => {
  return v === SettingValue.True.valueOf()
    ? SettingValue.True
    : SettingValue.False;
};

export interface UserSetting {
  key: string;
  value: string;
}
