import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Layout, Colors } from "../constants";

import StyledText from "./StyledText";

const btnStyle = (isBtnPrimary, isBtnDisabled) => {
  const x = [];
  if (isBtnPrimary) {
    x.push(styles.buttonPrimary);
  } else {
    x.push(styles.buttonSecondary);
  }
  if (isBtnDisabled) {
    x.push(styles.disabledBG);
  }
  return x;
};

const btnTextStyle = (isBtnPrimary, isBtnDisabled) => {
  const x = [];
  if (isBtnPrimary) {
    x.push(styles.buttonPrimaryText);
  } else {
    x.push(styles.buttonSecondaryText);
  }
  if (isBtnDisabled) {
    x.push(styles.disabledTextColor);
  }
  return x;
};

export default function ButtonGroup({
  leftBtnText,
  leftBtnHandlePress,
  isLeftBtnPrimary = true,
  isLeftBtnDisabled = false,
  rightBtnText,
  rightBtnHandlePress,
  isRightBtnPrimary = true,
  isRightBtnDisabled = false,
}) {
  return (
    <View style={styles.buttonGroup}>
      <TouchableOpacity
        style={[
          btnStyle(isLeftBtnPrimary, isLeftBtnDisabled),
          lineInTheMiddle(isLeftBtnDisabled),
        ]}
        onPress={leftBtnHandlePress}
        disabled={isLeftBtnDisabled}
      >
        <StyledText style={btnTextStyle(isLeftBtnPrimary, isLeftBtnDisabled)}>
          {leftBtnText}
        </StyledText>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          btnStyle(isRightBtnPrimary, isRightBtnDisabled),
          styles.rightBtn,
        ]}
        onPress={rightBtnHandlePress}
        disabled={isRightBtnDisabled}
      >
        <StyledText style={btnTextStyle(isRightBtnPrimary, isRightBtnDisabled)}>
          {rightBtnText}
        </StyledText>
      </TouchableOpacity>
    </View>
  );

  // Style separation between buttons
  function lineInTheMiddle(isLeftBtnDisabled) {
    return {
      borderRightWidth: 1,
      borderRightColor: isLeftBtnDisabled
        ? Colors.darkDisabledBG
        : Colors.primary,
    };
  }
}

const styles = StyleSheet.create({
  buttonGroup: {
    marginBottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: Layout.buttonHeight,
    elevation: 2,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    shadowOpacity: 0.5,
  },
  buttonPrimary: {
    flex: 1,
    height: Layout.buttonHeight,
    backgroundColor: Colors.primary,
    opacity: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonSecondary: {
    flex: 1,
    height: Layout.buttonHeight,
    backgroundColor: Colors.secondaryBtnBg,
    borderColor: Colors.border.light.secondary,
    borderWidth: 1,
    opacity: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonPrimaryText: {
    color: Colors.white,
    fontSize: Layout.normalize(24) * Layout.mscale,
    textAlign: "center",
    fontFamily: Layout.fontRegular,
  },
  buttonSecondaryText: {
    color: Colors.primary,
    fontSize: Layout.normalize(24) * Layout.mscale,
    textAlign: "center",
    fontFamily: Layout.fontRegular,
  },
  disabledBG: {
    backgroundColor: Colors.disabledBG,
  },
  disabledTextColor: {
    color: Colors.disabledTextColor,
  },
});
