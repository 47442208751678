import React, { Component } from "react";
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  Image,
  ViewStyle,
  ImageSourcePropType,
} from "react-native";

import { FontAwesome5 } from "@expo/vector-icons";

import StyledText from "./StyledText";

import Layout from "../constants/Layout";
import Colors from "../constants/Colors";

type Props = {
  placeholder?: string;
  headerText: string;
  type: string;
  value?: string;
  keyboardType?: string;
  defaultValue?: string;
  customStyles?: ViewStyle;
  onChangeText?: Function;
  multiline?: boolean;
  autoFocus?: boolean;
  format?: Function;

  flag: ImageSourcePropType;
  prefix: string;
  errorNote?: string;
};

class PhoneInput extends Component<Props> {
  renderInput(props) {
    if (props.value == 0) {
      return (
        <StyledText testID={props.testID} style={styles.placeholder}>
          {props.placeholder}
        </StyledText>
      );
    } else {
      return (
        <StyledText testID={props.testID} style={styles.integers}>
          {props.format ? props.format(props.value) : props.value}
        </StyledText>
      );
    }
  }

  clickFlag = () => {
    this.props.navigateToScreen();
  };

  renderError(msg) {
    return <StyledText style={styles.errormsg}>{msg}</StyledText>;
  }

  render() {
    const containerStyles = [styles.container];
    if (this.props.customStyles && this.props.customStyles.container) {
      containerStyles.push(this.props.customStyles.container);
    }
    return (
      <View style={containerStyles}>
        <StyledText style={styles.label}>{this.props.headerText}</StyledText>
        <View style={styles.wrapper}>
          <TouchableWithoutFeedback onPress={this.clickFlag}>
            <View style={styles.countryPicker}>
              <Image
                source={this.props.flag}
                style={{
                  height: Layout.flagLogoHeight,
                  width: Layout.flagLogoWidth,
                  marginRight: Layout.hSize(8),
                }}
              />
              <FontAwesome5
                name="caret-down"
                size={Layout.mSize(24)}
                color="#999"
              />
              <StyledText style={[styles.integers, styles.countryPrefix]}>
                +{this.props.prefix}
              </StyledText>
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.value}>{this.renderInput(this.props)}</View>
          {typeof this.props.errorNote === "string"
            ? this.renderError(this.props.errorNote)
            : null}
        </View>
      </View>
    );
  }
}

export default PhoneInput;

const styles = StyleSheet.create({
  container: {
    height: Layout.displayInputHeight,
    flexDirection: "column",
    backgroundColor: Colors.white,
    paddingHorizontal: Layout.vSize(20),
    display: "flex",
    width: "100%",
    alignSelf: "flex-start",
  },
  label: {
    fontFamily: Layout.fontLight,
    color: Colors.mainTextColor,
    paddingHorizontal: 0,
    lineHeight: Layout.vSize(22),
    marginTop: Layout.vSize(15),
    fontSize: Layout.mSize(16),
    height: Layout.vSize(22),
  },
  wrapper: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  countryPicker: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: Layout.hSize(10),
  },
  countryPrefix: {
    marginLeft: Layout.hSize(8),
  },
  value: {
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    paddingLeft: Layout.hSize(10),
    flex: 1,
  },
  integers: {
    fontFamily: Layout.fontRegular,
    color: Colors.font.main,
    fontSize: Layout.mSize(28),
  },
  placeholder: {
    fontFamily: Layout.fontRegular,
    color: "#ddd",
    fontSize: Layout.mSize(28),
  },
  errormsg: {
    fontFamily: Layout.fontLight,
    textAlign: "center",
    fontSize: Layout.mSize(13),
    color: Colors.font.tertiary,
    marginTop: Layout.vSize(10),
  },
});
