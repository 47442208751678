import { createAction } from "@reduxjs/toolkit";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { CuantoAPI } from "../api/CuantoAPI";
import { CuantoAPI as TypedAPIClient } from "../api/CuantoApiTyped";
import { clearSelectedPrice, resetCart } from "../features/Cart/actions";
import * as cartActions from "../features/Cart/actions";
import { resetSelectedFulfillmentOption } from "../features/FulfillmentOptions/actions";
import { serializeException } from "../services/Sentry";
import { AxiosError, Error } from "../types/Error";
import { Payment } from "../types/Payment";
import { PaymentMethod } from "../types/PaymentMethod";
import { CartProduct } from "../types/Product";
import { resetPaymentMethod } from "./features/Consumer/actions";
import { resetDeliveryAddress } from "./features/Consumer/reducers";
import { getPaymentValuesFrom } from "./features/Consumer/utils";
import i18n from "./i18n";
import { CatalogueLoadingSegment, State } from "./reducers";
import { PayForCartPayload, PayForCartStatus } from "./types";

export const fetchCatalogueStart = createAction(
  "catalogue/fetchCatalogueStart"
);
export const fetchCatalogue = createAction("catalogue/fetchCatalogue");
export const fetchCatalogueError = createAction(
  "catalogue/fetchCatalogueError"
);

export const resetCartPayment = createAction("catalogue/resetCartPayment");
export const selectPaymentMethodType = createAction<PaymentMethod>(
  "catalogue/selectPaymentMethodType"
);
export const resetPaymentMethodType = createAction(
  "catalogue/resetPaymentMethodType"
);

export const createCartPaymentStart = createAction(
  "catalogue/createCartPaymentStart"
);
export const createCartPayment = createAction<PayForCartPayload>(
  "catalogue/createCartPayment"
);
export const createCartPaymentError = createAction(
  "catalogue/createCartPaymentError"
);

export const fetchPaymentRequestStart = createAction(
  "catalogue/fetchPaymentRequestStart"
);
export const fetchPaymentRequest = createAction(
  "catalogue/fetchPaymentRequest"
);
export const fetchPaymentRequestError = createAction(
  "catalogue/fetchPaymentRequestError"
);

export const catalogueLoadingStart = createAction<CatalogueLoadingSegment>(
  "catalogue/catalogueLoadingStart"
);
export const catalogueLoading = createAction<CatalogueLoadingSegment>(
  "catalogue/catalogueLoading"
);
export const catalogueLoadingError = createAction<CatalogueLoadingSegment>(
  "catalogue/catalogueLoadingError"
);

export const fetchSocialStatsStart = createAction(
  "catalogue/fetchSocialStatsStart"
);
export const fetchSocialStats = createAction("catalogue/fetchSocialStats");
export const fetchSocialStatsError = createAction(
  "catalogue/fetchSocialStatsError"
);

export const fetchReviewsStart = createAction("catalogue/fetchReviewsStart");
export const fetchReviews = createAction("catalogue/fetchReviews");
export const fetchReviewsError = createAction("catalogue/fetchReviewsError");

export const createReviewStart = createAction("catalogue/createReviewStart");
export const createReview = createAction("catalogue/createReview");
export const createReviewError = createAction("catalogue/createReviewError");
export const selectCategoryUuid = createAction<string>(
  "catalogue/selectCategoryUuid"
);

export const setPaymentResult = createAction<PaymentResultData>(
  "catalogue/payment_result"
);
export const nuke = createAction("catalogue/nuke");

interface Action {
  type: string;
  payload: any;
}

export interface PaymentResultData {
  email: string;
  username: string;
  subscribe: boolean;
  amount: number;
  paymentMethod: PaymentMethod;
  error?: Error;
  items?: CartProduct[];
}
export interface CartPaymentErrorAction extends Action {
  payload: Error;
}

function typedAPIClient(): TypedAPIClient {
  return TypedAPIClient.getInstance();
}

const api = new CuantoAPI();

const changeLanguage = ({ country_code = "507" }) => {
  i18n.changeLanguage(country_code);
};

export const async = {
  fetchCatalogue: (username) => (dispatch: any, getState: () => any) => {
    const state: State = getState().catalogue;

    // only fetch catalogue if we haven't before
    if (state.isCatalogueFetched) {
      return Promise.resolve();
    }
    dispatch(fetchCatalogueStart());

    try {
      const data = get(window, "gon.__initialData.products");

      if (data) {
        dispatch(fetchCatalogue(data));

        changeLanguage(data);
        if (data && data.products) {
          dispatch(cartActions.removeExpiredProductsFromCart(data.products));
        }

        return data;
      } else {
        dispatch(async.refreshCatalogue(username));
      }
    } catch (error) {
      dispatch(fetchCatalogueError());
    }
  },

  refreshCatalogue: (username) => (dispatch: any, getState: () => any) => {
    return api
      .getCatalogue(username)
      .then(({ data }) => {
        dispatch(fetchCatalogue(data));

        changeLanguage(data);
        if (data && data.products) {
          dispatch(cartActions.removeExpiredProductsFromCart(data.products));
        }

        return data;
      })
      .catch(() => {
        dispatch(fetchCatalogueError());
      });
  },

  fetchCatalogueProduct:
    (username, shortUuid) => (dispatch, getState: () => any) => {
      // check if the product is already cached. if so, skip network request
      const state: State = getState().catalogue;
      const index = state.products.findIndex(
        (product) => product.short_uuid === shortUuid
      );
      if (index > -1) {
        return Promise.resolve();
      }

      dispatch(fetchCatalogueStart());

      return api
        .getCatalogueProduct(username, shortUuid)
        .then(({ data }) => {
          data.isProductOnly = true;

          changeLanguage(data);
          dispatch(fetchCatalogue(data));
        })
        .catch(() => {
          dispatch(fetchCatalogueError());
        });
    },

  payForCart: () => (dispatch, getState) => {
    dispatch(createCartPaymentStart());

    const { catalogue, consumer, cart, discounts } = getState();
    const token = JSON.parse(localStorage.getItem("authorization"));

    // const jwt = get(consumer, "session.jwt", "");
    const email = get(consumer, "email", "");
    const paymentMethod = get(consumer, "paymentMethod", "");
    const paymentMethodCode = get(consumer, "paymentMethodCode", "");
    const rememberDetails = get(consumer, "rememberDetails", false);
    const discount_code = get(discounts, "selectedCartDiscount.code", "");
    const payment_method_type = get(
      catalogue,
      "selectedPaymentMethod.type",
      "card"
    );

    let cart_uuid;
    const isPaymentRequest = !isEmpty(catalogue.paymentRequest);
    if (isPaymentRequest) {
      cart_uuid = catalogue.paymentRequest.cart_uuid;
    } else {
      cart_uuid = cart.uuid;
    }

    let payment;
    // Saved methods (Cards)
    if (paymentMethod.id) {
      payment = { cof_id: paymentMethod.id };
    } else {
      payment = getPaymentValuesFrom({
        ...paymentMethod,
      });
      if (paymentMethodCode) {
        payment.cvv = paymentMethodCode;
      }
    }

    const api = TypedAPIClient.init(token);
    return api
      .payForCart(cart_uuid, {
        ...payment,
        cof_save: rememberDetails,
        email,
        discount_code,
        payment_method_type,
      })
      .then(({ data }) => {
        dispatch(createCartPayment(data));
      })
      .catch((e: AxiosError) => {
        if (e.response.status === 503) {
          const data: PayForCartPayload = {
            // order and cart uuid is the same
            order_uuid: cart_uuid,
            status: PayForCartStatus.PENDING,
          };

          dispatch(createCartPayment(data));
        } else {
          dispatch(createCartPaymentError(serializeException(e)));
          throw e;
        }
      });
  },

  payWithCard: (username: string, payment: Payment) => (dispatch, getState) => {
    const { consumer } = getState();
    const token = JSON.parse(localStorage.getItem("authorization"));

    // const jwt = get(consumer, "session.jwt", "");
    const email = get(consumer, "email", "");

    const api = TypedAPIClient.init(token);
    return api
      .payWithCard(username, { ...payment, email })
      .then(({ data }) => {
        dispatch(createCartPayment(data));
        // TODO: no one uses this return value, and we really dont wanna cast is as a message: hash
        return { message: data || "" };
      })
      .catch((e: AxiosError) => {
        // We can't recover from timeout errors (503) like in payForCart since
        // we don't have a uuid assigned.
        dispatch(createCartPaymentError(serializeException(e)));
        throw e;
      });
  },

  subscribeWithCard:
    (username: string, payment: Payment) => (dispatch, getState) => {
      return api
        .subscribeWithCard(username, payment)
        .then(({ data }) => {
          return { message: data || "" };
        })
        .catch((e) => {
          throw e;
        });
    },

  // TODO DRY from payment actions?
  fetchPaymentRequest: (uuid) => (dispatch) => {
    dispatch(fetchPaymentRequestStart());

    return api
      .getPaymentRequest(uuid)
      .then(({ data }) => {
        data.paymentRequestUuid = uuid;

        changeLanguage(data);
        dispatch(fetchPaymentRequest(data));
      })
      .catch(() => {
        dispatch(fetchPaymentRequestError());
      });
  },

  fetchSocialStats: (username) => (dispatch) => {
    const api = typedAPIClient();
    dispatch(fetchSocialStatsStart());

    // return api
    //   .getSocialStats(username)
    //   .then(({ data }) => {
    //     dispatch(fetchSocialStats(data));
    //   })
    //   .catch(() => {
    //     dispatch(fetchSocialStatsError());
    //   });
    try {
      // @ts-ignore
      dispatch(fetchSocialStats(window.gon.__initialData.social_stats));
    } catch (error) {
      dispatch(fetchSocialStatsError());
    }
  },

  fetchReviews: (username) => (dispatch: any, state: State) => {
    dispatch(fetchReviewsStart());

    return api
      .getReviews(username)
      .then(({ data }) => {
        dispatch(fetchReviews(data));
      })
      .catch(() => {
        dispatch(fetchReviewsError());
      });
  },

  createReview: (username, params) => (dispatch) => {
    dispatch(createReviewStart());

    return api
      .createReview(username, params)
      .then(({ data }) => {
        dispatch(createReview(data));
      })
      .catch((e) => {
        dispatch(createReviewError(serializeException(e)));
        throw e;
      });
  },

  setPaymentResult: (data: PaymentResultData) => (dispatch) => {
    dispatch(setPaymentResult(data));
  },

  // reset EVERYTHING related to cart
  nuke: () => (dispatch) => {
    dispatch(resetCartPayment());
    dispatch(resetPaymentMethodType());
    dispatch(resetCart());
    dispatch(resetSelectedFulfillmentOption());
    dispatch(resetDeliveryAddress());
    dispatch(resetPaymentMethod());
    dispatch(clearSelectedPrice());
  },
};
