import React from "react";
import { View } from "react-native";

import styled from "styled-components/native";

import Text from "../../../../components/Text";

import { Layout, Colors } from "../../../../constants";
import useDimensions from "../../../../hooks/useDimensions";

export const HEADER_ICON_WIDTH = Layout.hSize(44);
export const HEADER_HEIGHT = Layout.vSize(60);

interface Props {
  headerLeft?: React.ReactNode;
  headerTitle?: React.ReactNode;
  headerRight?: React.ReactNode;
}

function Header({ headerLeft, headerTitle, headerRight }: Props) {
  const dimensions = useDimensions();
  const { width } = dimensions;
  const maxWidth = width > 600 ? 600 : width;

  return (
    <Container style={[{ maxWidth }]}>
      <View
        style={[
          // `HEADER_ICON_WIDTH` assumes CartBadge in the `headerRight`
          { maxWidth: headerRight ? maxWidth - HEADER_ICON_WIDTH : maxWidth },
        ]}
      >
        {headerLeft}
      </View>
      {/* // `HEADER_ICON_WIDTH` assumes CartBadge in the `headerRight` */}
      {/* // `HEADER_ICON_WIDTH` assumes `arrow-left` incon in the `headerLeft` */}
      <Title style={[{ maxWidth: maxWidth - HEADER_ICON_WIDTH * 2 }]}>
        {typeof headerTitle === "string" ? (
          <TitleText numberOfLines={1} ellipsizeMode="tail">
            {headerTitle}
          </TitleText>
        ) : (
          headerTitle
        )}
      </Title>
      <View>{headerRight}</View>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  z-index: 1;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.border.light.tertiary};
`;

const Title = styled.View`
  position: absolute;
  left: ${HEADER_ICON_WIDTH}px;
  right: ${HEADER_ICON_WIDTH}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: -1;
`;

const TitleText = styled(Text)`
  color: ${Colors.font.main};
  font-family: ${Layout.fontRegular};
  font-size: ${Layout.mSize(24)}px;
  line-height: ${Layout.mSize(29)}px;
`;

export default Header;
