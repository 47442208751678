import * as React from "react";

import Svg, { Path } from "react-native-svg";

import { TailwindStyle } from "../../tailwind";

type Props = {
  size?: number;
  style?: TailwindStyle;
};

const CheckSuccess = ({ size = 104, style }: Props) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 104 104"
      style={style}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.109 10.112a19.62 19.62 0 0011.165-4.625c7.333-6.25 18.119-6.25 25.452 0a19.62 19.62 0 0011.165 4.625c9.604.766 17.23 8.393 17.997 17.997a19.62 19.62 0 004.625 11.165c6.249 7.333 6.249 18.119 0 25.452a19.62 19.62 0 00-4.625 11.165c-.766 9.604-8.393 17.23-17.997 17.997a19.621 19.621 0 00-11.165 4.625c-7.333 6.249-18.119 6.249-25.452 0a19.621 19.621 0 00-11.165-4.625c-9.605-.766-17.231-8.393-17.998-17.997a19.62 19.62 0 00-4.624-11.165c-6.25-7.333-6.25-18.119 0-25.452A19.62 19.62 0 0010.11 28.11c.767-9.604 8.393-17.23 17.998-17.997zm47.616 33.613a6.4 6.4 0 00-9.05-9.05L45.6 55.749l-8.275-8.274a6.4 6.4 0 10-9.05 9.05l12.8 12.8a6.4 6.4 0 009.05 0l25.6-25.6z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default CheckSuccess;
