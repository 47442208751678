import React from "react";
import { View, StyleSheet, Image, TouchableHighlight } from "react-native";

import StyledText from "./StyledText";

import Layout from "../constants/Layout";
import Colors from "../constants/Colors";

const logoDimensions = Layout.normalize(40) * Layout.mscale;

type Props = {
  bank: Object;
  handlePress: Function;
};

export default class BankListItem extends React.Component<Props> {
  handlePress = () => {
    this.props.handlePress(this.props.bank);
  };

  render() {
    const { name, logo } = this.props.bank;
    return (
      <TouchableHighlight
        style={styles.row}
        underlayColor="#ccc"
        onPress={this.handlePress}
      >
        <View style={styles.rowContent}>
          <View style={styles.imageContainer}>
            <Image source={logo} style={styles.bankLogo} />
          </View>
          <StyledText style={styles.rowText}>{name}</StyledText>
        </View>
      </TouchableHighlight>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    backgroundColor: Colors.white,
    paddingRight: Layout.normalize(15) * Layout.hscale,
    paddingLeft: Layout.normalize(15) * Layout.hscale,
  },
  rowContent: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: Layout.normalize(80) * Layout.vscale,
  },
  imageContainer: {
    width: Layout.normalize(65) * Layout.hscale,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  bankLogo: {
    maxHeight: logoDimensions,
    maxWidth: logoDimensions,
  },
  rowText: {
    fontFamily: Layout.fontRegular,
    fontSize: Layout.normalize(16) * Layout.mscale,
    color: Colors.font.main,
  },
});
