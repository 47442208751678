import React from "react";

import {
  mdiArrowRight,
  mdiCart,
  mdiMoped,
  mdiStar,
  mdiStorefront,
} from "@mdi/js";
import Icon from "@mdi/react";

import { navigate } from "cuanto/web-app/router";
import { resizeImageUrl } from "cuanto/web-app/utils";

import { Merchant } from "../types";

type Props = {
  merchant: Pick<Merchant, "avatar_url" | "full_name" | "username">;
  totalTransactionCount: number;
  reviewsAverage: number | null;
  deliveryEnabled: boolean;
  onMoreInfoPress: () => void;
};

export default function CatalogueHeader({
  merchant,
  totalTransactionCount,
  reviewsAverage,
  deliveryEnabled,
  onMoreInfoPress,
}: Props) {
  // show more only if one of the tabs is active
  const showMoreEnabled = deliveryEnabled || reviewsAverage !== null;

  return (
    <div className="flex">
      <div className="self-center">
        <Avatar url={merchant.avatar_url} />
      </div>

      <div className="flex flex-col flex-grow justify-center">
        <div className="flex items-baseline">
          <span className="font-semibold text-xl">{merchant.full_name}</span>
          {showMoreEnabled && (
            <div className="ml-auto flex-shrink-0 pl-1 active:bg-gray-100 rounded-md">
              <ShowMore onClick={onMoreInfoPress} />
            </div>
          )}
        </div>

        <div className="flex gap-x-1">
          <AverageReviewStat average={reviewsAverage} />
          <TransactionCountStat txnCount={totalTransactionCount} />
        </div>
        <DeliveryStat enabled={deliveryEnabled} />
      </div>
    </div>
  );
}

function Avatar({ url }: { url: string }) {
  return (
    <div className="flex h-20 w-20 rounded-full overflow-hidden mr-2">
      {url ? (
        <img src={resizeImageUrl(url, { width: 300, height: 300 })} />
      ) : (
        <div className="bg-gray-200 w-full relative text-white">
          <Icon
            path={mdiStorefront}
            size={1.5}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      )}
    </div>
  );
}

function ShowMore({ onClick }: { onClick: () => void }) {
  return (
    <div
      className="flex items-center cursor-pointer text-gray-400 select-none"
      onClick={onClick}
    >
      <span className="text-sm mr-1">ver más</span>
      <Icon path={mdiArrowRight} className="h-6 w-6" />
    </div>
  );
}

function TransactionCountStat({ txnCount }: { txnCount: number }) {
  if (txnCount === 0) return null;

  function getText() {
    const word = txnCount > 1 ? "ventas" : "venta";
    if (txnCount >= 1000) {
      return `mil+ ${word}`;
    } else if (txnCount >= 100) {
      return `100+ ${word}`;
    } else if (txnCount >= 10) {
      return `10+ ${word}`;
    } else {
      return `${txnCount} ${word}`;
    }
  }

  const text = getText();

  return <Stat icon={mdiCart} text={text} />;
}

function AverageReviewStat({ average }: { average: number | null }) {
  if (average === null) return null;

  // cast to 1 digit and then back to a number, so 5.0 becomes 5 but 4.9 stays 4.9
  const casted = Number(average.toFixed(1));

  return <Stat icon={mdiStar} text={casted} />;
}

function DeliveryStat({ enabled }: { enabled: boolean }) {
  if (!enabled) return null;

  return <Stat icon={mdiMoped} text="ofrece entregas" />;
}

function Stat({ icon, text }: { icon: string; text: string | number }) {
  return (
    <div className="text-gray-400 flex items-center">
      <Icon path={icon} className="h-4 w-4 mr-1" />
      <span className="text-sm">{text}</span>
    </div>
  );
}
