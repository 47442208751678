import React from "react";

import { LoadingView } from "cuanto/components";
import CatalogueUI from "cuanto/package/Catalogue/Catalogue/Catalogue";
import CatalogueHeader from "cuanto/package/Catalogue/components/CatalogueHeader";
import useCatalogue from "cuanto/package/Catalogue/contexts/CatalogueContext";
import { CatalogueData, PriceGroups } from "cuanto/package/Catalogue/types";
import Button from "cuanto/web-app/components/Button.web";
import CategoriesPills from "cuanto/web-app/components/CategoriesPills.web";
import CatalogueBanner from "cuanto/web-app/components/ProductCatalogue/CatalogueBanner";
import CatalogueBio from "cuanto/web-app/components/StorePersonalization/CatalogueBio";
import StoreLinks from "cuanto/web-app/components/StorePersonalization/StoreLinks";
import {
  Link,
  StoreConfig,
} from "cuanto/web-app/components/StorePersonalization/types";

type Props<T = {}> = T & {
  catalogueData: CatalogueData;
  priceGroups: PriceGroups;
};

function Links(props: Props<{ links?: Link[] }>) {
  const links = props.links ?? props.catalogueData.store_config.links;

  return (
    <div className="m-4 select-none">
      <PreviewHeader />
      <div className="mt-3">
        <StoreLinks links={links} disableClicks />
      </div>
    </div>
  );
}

function Bio(props: Props<{ bio?: string }>) {
  const bio = props.bio ?? props.catalogueData.store_config.bio;

  return (
    <div className="m-4 select-none pointer-events-none">
      <PreviewHeader />
      <CatalogueBio bio={bio} />
    </div>
  );
}

function Colors(props: Props) {
  const categories = [1, 2, 3].map((n) => ({
    uuid: "",
    name: `Categoría ${n}`,
  }));

  const links = props.catalogueData.store_config.links.map((l) => ({
    ...l,
    enabled: true,
  }));

  return (
    <div className="m-4">
      <StoreLinks links={links} disableClicks />
      <div className="my-4">
        <CategoriesPills
          categories={categories}
          onCategoryClick={() => {}}
          resetSelectedCategoryPillVisbile
        />
      </div>
      <div className="flex gap-x-4 items-center">
        <Button text="Boton 1" small />
        <Button text="Boton 2" buttonType="secondary" small />
      </div>
    </div>
  );
}

type CatalogueProps = {
  bio?: string;
  links?: Link[];
  bannerUrl?: string;
};

function Catalogue(props: Props<CatalogueProps>) {
  const bannerUrl =
    props.bannerUrl ?? props.catalogueData.store_config.banner_image_url;
  const links = props.links ?? props.catalogueData.store_config.links;
  const bio = props.bio ?? props.catalogueData.store_config.bio;

  const storeConfig: StoreConfig = {
    ...props.catalogueData.store_config,
    bio,
    banner_image_url: bannerUrl,
    links,
  };

  const filteredProducts = props.catalogueData.products.filter(
    (p) => p.product_group_uuid === null
  );

  const noop = () => {};

  return (
    <CatalogueUI
      {...props}
      storeConfig={storeConfig}
      merchant={props.catalogueData.merchant}
      products={filteredProducts}
      selectedCategoryUuid="all"
      categories={props.catalogueData.categories}
      storeClosed={false}
      totalTransactionCount={props.catalogueData.total_transaction_count}
      transactionCountPastThreeMonths={0}
      reviewsAverage={props.catalogueData.reviews_average}
      deliveryEnabled={props.catalogueData.has_fulfillments_enabled}
      cartQuantity={0}
      numCatalogueColumns={2}
      productGroups={{}}
      priceGroups={props.priceGroups}
      onAddToCart={noop}
      onGoToCart={noop}
      onSelectCategory={noop}
      onMoreInfoPress={noop}
      onSelectProduct={noop}
    />
  );
}

function Banner(props: Props<{ bannerUrl?: string }>) {
  const bannerUrl =
    props.bannerUrl ?? props.catalogueData.store_config.banner_image_url;

  return (
    <div>
      <CatalogueBanner bannerUrl={bannerUrl} showPlaceholderIcon />
      <div className="m-4">
        <PreviewHeader />
      </div>
    </div>
  );
}

// simple wrapper to prevent clicks on header / navigation
function PreviewHeader() {
  const { catalogueData } = useCatalogue();

  if (!catalogueData) return <LoadingView />;

  return (
    <div className="pointer-events-none">
      <CatalogueHeader
        merchant={catalogueData.merchant}
        totalTransactionCount={catalogueData.total_transaction_count}
        reviewsAverage={catalogueData.reviews_average}
        deliveryEnabled={catalogueData.has_fulfillments_enabled}
        onMoreInfoPress={() => {}}
      />
    </div>
  );
}

function Unknown() {
  return <div>Unknown preview component</div>;
}

export default { Links, Bio, Colors, Catalogue, Banner, Unknown };
