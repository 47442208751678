import React from "react";
import { Text, TextProps, StyleSheet } from "react-native";

import Layout from "../constants/Layout";

interface Props extends TextProps {
  children: React.ReactNode;
}

export default function StyledText({ style, ...rest }: Props) {
  return (
    <Text
      {...rest}
      // Force to `false` #472
      allowFontScaling={false}
      style={StyleSheet.flatten([{ fontFamily: Layout.fontRegular }, style])}
    />
  );
}
