import axios from "axios";
import axiosRetry from "axios-retry";

import {
  addErrorInterceptor,
  urlBasedOnReleaseChannel,
  addRequestInterceptor,
} from "./utils";
export { urlBasedOnReleaseChannel };

const CancelToken = axios.CancelToken;

// Used for storing a cancel function, it will allow us to cancel pending promises
// https://github.com/axios/axios#cancellation
let cancelEmailIsAvailable;
let cancelUsernameIsAvailable;
let cancelGetDiscount;

// TODO: need to test this against an instance of the Rails API
export class CuantoAPI {
  constructor(authenticationToken) {
    this.httpClient = axios.create({
      baseURL: urlBasedOnReleaseChannel(),
      headers: this.headers(authenticationToken),
    });
    addErrorInterceptor(this.httpClient);
    addRequestInterceptor(this.httpClient);
  }

  headers = (token) => {
    const h = { "Content-Type": "application/json" };
    if (token) {
      // JWT token
      h["Authorization"] = `Bearer ${token}`;
    }
    return h;
  };

  // Wrap axios.get for easier testing
  get = (url, options) => {
    return this.httpClient.get(url, options);
  };

  // Wrap axios.post for easier testing
  post = (url, params, options) => {
    return this.httpClient.post(url, params, options);
  };

  put = (url, params, options) => {
    return this.httpClient.put(url, params, options);
  };

  patch = (url, params, options) => {
    return this.httpClient.patch(url, params, options);
  };

  delete = (url) => {
    return this.httpClient.delete(url);
  };

  // No content on response
  createLoginCode = (countryCode, cellphone, smsOrVoice = "sms") => {
    return this.post("login_codes", {
      country_code: countryCode,
      cellphone,
      sms_or_voice: smsOrVoice,
    });
  };

  // response.data
  // {jwt: string used in other requests}
  verify = ({ countryCode, cellphone, code }) => {
    return this.post("authentication_tokens", {
      country_code: countryCode,
      cellphone,
      code,
    });
  };

  // No data: success -> available; error -> not
  usernameIsAvailable = (name) => {
    // cancel any pending request (the message parameter is optional)
    cancelUsernameIsAvailable &&
      cancelUsernameIsAvailable(`Previous Username check was canceled`);

    return this.post(
      "usernames",
      {
        value: name,
      },
      {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelUsernameIsAvailable = c;
        }),
      }
    );
  };

  emailIsAvailable = (email) => {
    // cancel any pending request (the message parameter is optional)
    cancelEmailIsAvailable &&
      cancelEmailIsAvailable(`Previous Email check was canceled`);

    return this.post(
      "emails",
      { value: email },
      {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelEmailIsAvailable = c;
        }),
      }
    );
  };

  // No data: success -> available; error -> not
  cellphoneIsAvailable = (phone) => {
    return this.post("cellphones", { value: phone });
  };

  // Requires token
  // response.data == {jwt: <new token>, user}
  signUp = (fullName, address, username) => {
    return this.post("users", {
      full_name: fullName,
      address,
      username,
    });
  };

  // response.data == {balance: Number, transactions: List}
  activity = () => {
    return this.get("users/me/activity");
  };

  // expecting status='processing' to be returned first
  transactions = ({ showAll, page }) => {
    return this.get(
      showAll
        ? `users/me/transactions?page=${page}`
        : `users/me/transactions?page=${page}&status=pending`
    );
  };

  sales = (period = "day") => {
    return this.get(`users/sales/${period}`);
  };

  // response.data: [{recipient}]
  recipients = (query) => {
    return this.get(`recipients?query=${query}`);
  };

  // API should match api.paymentRequest
  pay = (recipient_id, amount, comments, email, phone) => {
    return this.post("payments", {
      recipient_id,
      amount,
      comments,
      email,
      phone,
    });
  };

  subscribeWithCard = (username, values) => {
    return this.post(`${username}/${values.amount}/mensual`, values);
  };

  registerInPersonPayment = (params) => {
    return this.post("in_person_payments", params);
  };

  requestPayment = (amount, email, phone, cart) => {
    return this.post("payment_requests", { amount, email, phone, cart });
  };

  createPaymentRequest = (params) => {
    return this.post("payment_requests", params);
  };

  createPushNotificationToken = (token) => {
    return this.post("push_notification_tokens", { token });
  };

  createSignedUploadUrl = (url = "photo_id_upload_urls") => {
    // TODO: figure out why axiosRetry config isn't working properly
    // Tried using retries, retryDelay, retryCondition and did not work as docs
    var retryCount = 0;
    axiosRetry(this.httpClient, {
      retryCondition: (error) => {
        return retryCount < 3;
      },
      retryDelay: (count, error) => {
        retryCount += 1;
        return retryCount * 600;
      },
    });
    return this.get(url);
  };

  // TODO: rename, this is used to send the s3 filename to the server
  updateUserIdUrl = (photoIdS3Key) => {
    return this.post("photo_id_s3_keys", {
      photo_id_s3_key: photoIdS3Key,
    });
  };

  updateMerchant = ({ fullname, username, cellphone, email, countryCode }) => {
    return this.put("/merchants", {
      full_name: fullname,
      username,
      cellphone: cellphone,
      country_code: countryCode,
      email,
    });
  };

  cancel = (transaction) => {
    return this.delete("payments/" + transaction.id);
  };

  deliver = (charge_type, charge_id) => {
    return this.post("deliveries", { charge_type, charge_id });
  };

  markAsPaid = ({ uuid, email }) => {
    return this.post("payment_confirmations", { uuid, email });
  };

  cancelPayment = (uuid) => {
    return this.post("payment_cancellations", { uuid });
  };

  usernames = (phones) => {
    const options = {
      params: { phone_numbers: phones },
    };
    return this.get("recipients", options);
  };

  deletePushNotificationToken = (token) => {
    return this.delete("/push_notification_tokens/" + token);
  };

  createMerchant = (name, username, email) => {
    const params = { full_name: name, username, email };
    return this.post("merchant_accounts", params);
  };

  getMerchantAccounts = () => {
    return this.get("merchant_accounts");
  };

  getMerchantAccount = (uuid) => {
    return this.get("merchant_accounts/" + uuid);
  };

  createBankAccount = (params) => {
    return this.post("/bank_accounts", params);
  };

  createProduct = (product) => {
    return this.post("products", product);
  };

  getProducts = () => {
    return this.get("products");
  };

  getProduct = (uuid) => {
    return this.get(`products/${uuid}`);
  };

  updateProduct = (id, product) => {
    return this.put(`products/${id}`, product);
  };

  updateProducts = (products) => {
    const params = products.map((product) => {
      return {
        id: product.id,
        default_sort_index: parseInt(product.default_sort_index),
      };
    });

    return this.put(`products/default_sort_indexes`, { products: params });
  };

  deleteProduct = (id) => {
    return this.delete(`products/${id}`);
  };

  registerDevice = (data) => {
    return this.post("devices", data);
  };

  getCatalogue = (username) => {
    return this.get(`${username}/products`);
  };

  getCatalogueProduct = (username, productId) => {
    return this.get(`${username}/products/${productId}`);
  };

  getSelfies = () => {
    return this.get("selfies");
  };

  createSelfie = () => {
    return this.post("selfies");
  };

  resendReceipt = (data) => {
    return this.post("receipts", data);
  };

  fetchFulfillmentFeatureFlag = () => {
    return this.get("features/fulfillments");
  };

  setFulfillmentFeatureFlag = (bool) => {
    return this.put("features/fulfillments", { enabled: bool });
  };

  fetchFulfillmentOptions = () => {
    return this.get("fulfillment_options");
  };

  createFulfillmentOption = (params) => {
    return this.post("fulfillment_options", params);
  };

  updateFulfillmentOption = (id, params) => {
    return this.put(`fulfillment_options/${id}`, params);
  };

  deleteFulfillmentOption = (id) => {
    return this.delete(`fulfillment_options/${id}`);
  };

  createFulfillment = (params) => {
    return this.post("fulfillments", params);
  };

  getUserSettings = () => {
    return this.get("/users/settings");
  };

  createUpdateUserSettings = (params) => {
    return this.post("/users/settings", params);
  };

  getInventory = (uuids) => {
    const params = uuids.join("&product_uuids[]=");
    return this.get(`inventories/availability?product_uuids[]=${params}`);
  };

  getPaymentRequest = (uuid) => {
    return this.get(`/payment_requests/${uuid}`);
  };

  getSupport = () => {
    return this.get(`/support`);
  };

  getNextDeposit = () => {
    return this.get("/withdrawals/next");
  };

  getReviews = (username) => {
    return this.get(`${username}/reviews`);
  };

  createReview = (username, params) => {
    return this.post(`${username}/reviews`, params);
  };

  getDiscount = (username, { code }) => {
    // cancel any pending request (the message parameter is optional)
    cancelGetDiscount &&
      cancelGetDiscount(`Previous Discount code check was canceled`);

    return this.get(`${username}/discounts/${code}`, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelGetDiscount = c;
      }),
    });
  };

  getLocale = (scope) => {
    return this.get(`locale/?scope=${scope}`);
  };

  fetchBadges = () => {
    return this.get(`transactions/unread_counts`);
  };

  patchTransaction = (uuid, patch) => {
    return this.patch(`transactions/${uuid}`, patch);
  };

  createConsumerSession = (params) => {
    return this.post(`consumer_sessions`, params);
  };

  createCombinationInventory = (uuid, params) => {
    return this.post(`/products/${uuid}/combinations`, params);
  };

  createProductVariant = (uuid, params) => {
    return this.post(`/products/${uuid}/variants`, params);
  };

  updateProductVariant = (uuid, params) => {
    return this.put(`/variants/${uuid}`, params);
  };

  deleteProductVariant = (params) => {
    return this.post(`/variant_deletions/`, params);
  };

  getProductGroupVariants = (uuid) => {
    return this.get(`/products/groups/${uuid}/variants/`);
  };

  getPaymentMethods = () => {
    return this.get(`/payment_methods`);
  };

  updatePaymentMethods = (identifier, params) => {
    return this.put(`/payment_methods/${identifier}`, params);
  };

  requestAuth = (params) => {
    return this.post(`/consumers/request_auth`, params);
  };
  confirmAuth = (params) => {
    return this.post(`/consumers/confirm_auth`, params);
  };
  checkAuth = (params) => {
    return this.post(`/consumers/check_auth`, params);
  };

  getConsumerAddresses = ({ consumer_id }) => {
    return this.get(`/consumers/${consumer_id}/addresses`);
  };
  getConsumerAddress = ({ consumer_id, id }) => {
    return this.get(`/consumers/${consumer_id}/addresses/${id}`);
  };
  createConsumerAddress = ({ consumer_id, ...params }) => {
    return this.post(`/consumers/${consumer_id}/addresses`, params);
  };
  updateConsumerAddress = ({ consumer_id, id, ...params }) => {
    return this.put(`/consumers/${consumer_id}/addresses/${id}`, params);
  };
  deleteConsumerAddress = ({ consumer_id, id }) => {
    return this.delete(`/consumers/${consumer_id}/addresses/${id}`);
  };

  getConsumerPaymentMethods = ({ consumer_id }) => {
    return this.get(`/consumers/${consumer_id}/consumer_payment_methods`);
  };
  getConsumerPaymentMethod = ({ consumer_id, id }) => {
    return this.get(`/consumers/${consumer_id}/consumer_payment_methods/${id}`);
  };

  deleteConsumerPaymentMethod = ({ consumer_id, id }) => {
    return this.delete(
      `/consumers/${consumer_id}/consumer_payment_methods/${id}`
    );
  };

  createSubscription = (subscription) => {
    return this.post("subscription_products", subscription);
  };
  updateSubscription = (uuid, subscription) => {
    return this.put(`subscription_products/${uuid}`, subscription);
  };

  getPrices = (username, productShortUuid) => {
    return this.get(`${username}/products/${productShortUuid}/prices`);
  };
}
