import { createSlice } from "@reduxjs/toolkit";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";
import * as actions from "./actions";

import { FulfillmentOption } from "../../types/FulfillmentOption";

function sort(list) {
  return sortBy(list, [(o) => o.description.toLowerCase()]);
}

function removeItem(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export interface State {
  featureIsAvailable: boolean;
  selectedFulfillmentOption: FulfillmentOption | {};
  fulfillmentOptionsList: FulfillmentOption[];
  isWaitingForServer: boolean;
}

export const initialState: State = {
  featureIsAvailable: false,
  // to be shown/edited in form
  selectedFulfillmentOption: {},
  fulfillmentOptionsList: [],
  isWaitingForServer: true,
};

const fulfillments = createSlice({
  name: "fulfillments",
  initialState,
  reducers: {
    // Slice reducers can't use imported actions because they create new actions
  },
  extraReducers: {
    [actions.resetFulfillmentsFeatureFlag.type]: (state, action) => {
      state.featureIsAvailable = action.payload;
    },
    [actions.resetFulfillmentOptions.type]: (state, action) => {
      const fulfillmentOptionsList = action.payload;
      const fulfillmentOptions = fulfillmentOptionsList;

      state.fulfillmentOptionsList = sort(fulfillmentOptions);

      state.isWaitingForServer = false;
    },
    [actions.selectFulfillmentOption.type]: (state, action) => {
      state.selectedFulfillmentOption = action.payload;
    },
    [actions.resetSelectedFulfillmentOption.type]: (state) => {
      state.selectedFulfillmentOption = initialState.selectedFulfillmentOption;
    },
    [actions.updateFulfillmentOption.type]: (state, action) => {
      const { id } = action.payload;

      state.fulfillmentOptionsList = sort(
        state.fulfillmentOptionsList.map((option) => {
          return option.id === id ? { ...action.payload } : option;
        })
      );
    },
    [actions.fulfillmentOptionDelete.type]: (state, { payload }) => {
      const index = findIndex(state.fulfillmentOptionsList, { id: payload.id });
      state.fulfillmentOptionsList = removeItem(
        state.fulfillmentOptionsList,
        index
      );
    },
    [actions.addFulfillmentOption.type]: (state, action) => {
      state.fulfillmentOptionsList = sort([
        ...state.fulfillmentOptionsList,
        action.payload,
      ]);
    },
    [actions.waitForServer.type]: (state, action) => {
      state.isWaitingForServer = action.payload;
    },
  },
});

export default fulfillments;
