import omit from "lodash/omit";
import createSentryMiddleware from "redux-sentry-middleware";

import { installSentry } from "../services/Sentry.web";
import consumer from "./features/Consumer/reducers";
import catalogue from "./reducers";

export const redactStateForSentry = (state) => {
  // When adding a new state entry to this object, consider the
  // implication of sending it to Sentry and redact data if necessary.
  // Most arbitrary values in Sentry have their size restricted
  // https://docs.sentry.io/development/sdk-dev/data-handling/#variable-size

  return {
    cart: state.cart,
    catalogue: {
      ...catalogue,
      products: "[redacted]",
      fulfillmentOptions: "[redacted]",
    },
    consumer: { ...consumer, paymentMethod: "{redacted}" },
    fulfillments: state.fulfillments,
    discount: state.discount,
  };
};

// From the list of [redacted] keys above we can infer
// most of what should be ignored in action payloads as well
// This should be considered a living list to be fine tuned over time
const omittedStateKeys = ["products", "fulfillmentOptions", "paymentMethod"];
const omittedDataKeys = [];

const redactActionForSentry = ({ type, payload }) => {
  return {
    type,
    payload: omit(payload, [].concat(omittedStateKeys, omittedDataKeys)),
  };
};

const Sentry = installSentry();

const sentryMiddleware = createSentryMiddleware(Sentry.Browser, {
  stateTransformer: redactStateForSentry,
  actionTransformer: redactActionForSentry,
});

export default sentryMiddleware;
