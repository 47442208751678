import React, { useState } from "react";

import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/solid";
import times from "lodash/times";
import Carousel from "nuka-carousel";
import { TouchableOpacity, TouchableHighlight } from "react-native";

import { IMAGES_API_URL } from "../../../api/config";

type Props = {
  images: string[];
  imageSize: number;
};

export default function ImagesCarousel({ images, imageSize }: Props) {
  const [index, setIndex] = useState(0);
  const total = images.length;

  return (
    <div className="flex flex-col justify-center bg-gray-900 relative">
      {total > 1 && (
        <>
          {index !== 0 && (
            <div className="absolute px-1.5 left-1.5 z-10">
              <TouchableOpacity
                onPress={() => {
                  setIndex(index - 1);
                }}
              >
                <ArrowCircleLeftIcon className="h-6 w-6 text-white-100" />
              </TouchableOpacity>
            </div>
          )}
          {index + 1 !== total && (
            <div className="absolute px-1.5 right-1.5 z-10">
              <TouchableOpacity
                onPress={() => {
                  setIndex(index + 1);
                }}
              >
                <ArrowCircleRightIcon className="h-6 w-6 text-white-100" />
              </TouchableOpacity>
            </div>
          )}
        </>
      )}
      <div className="w-full">
        <Carousel
          slideIndex={index}
          afterSlide={(index) => {
            setIndex(index);
          }}
          withoutControls
          disableEdgeSwiping
        >
          {images.map((item, index) => {
            return (
              <img
                className="object-cover w-full h-full"
                key={index}
                src={`${IMAGES_API_URL}/?url=${item}&w=${imageSize}&h=${imageSize}`}
              />
            );
          })}
        </Carousel>
      </div>
      {total > 1 && (
        <div className="flex w-full h-7 justify-center items-center absolute bottom-0">
          {times(total, (currentIndex) => {
            const indicatorStyle = `w-1.5 h-1.5 m-1 rounded-full bg-white-100 ${
              index === currentIndex ? "bg-opacity-100" : "bg-opacity-50"
            }`;

            return (
              <TouchableHighlight
                key={currentIndex}
                onPress={() => setIndex(currentIndex)}
              >
                <div className={indicatorStyle} />
              </TouchableHighlight>
            );
          })}
        </div>
      )}
    </div>
  );
}
