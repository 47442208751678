import React from "react";

import { MaterialIcons } from "@expo/vector-icons";
import {
  View,
  TouchableHighlight,
  TouchableWithoutFeedback,
  StyleSheet,
} from "react-native";
import styled, { css } from "styled-components/native";

import { Layout, Colors } from "../../constants";
import { tailwind } from "../../tailwind";
import Avatar from "../Avatar";
import Badge from "../Badge/Badge";
import { BADGE_TYPES } from "../Badge/BadgeTypes";
import { Feather } from "../Icons";
import Text from "../Text";

// default height used for list items
export const DEFAULT_ITEM_HEIGHT = Layout.vSize(80);

interface Props {
  testID?: string;

  hasInfo?: boolean;
  hasImage?: boolean;
  hasBorder?: boolean;
  hasMargin?: boolean;
  hasPressFeedback?: boolean;
  unread?: boolean;
  hasRoundImage?: boolean;
  hasImageBorderRadius?: boolean;
  disabled?: boolean;

  image?: string | JSX.Element;
  primary?: string | JSX.Element;
  primaryLineCount?: number;
  secondary?: JSX.Element;
  left?: string | JSX.Element;
  right?: string | JSX.Element;
  height?: number;
  small?: boolean;

  onPress?: (event) => void;
  onLongPress?: (event) => void;
  onNavigation?: (e) => void;
}

const ListItem = ({
  testID,

  hasInfo,
  hasImage = true,
  hasBorder = true,
  hasMargin = true,
  hasRoundImage = true,
  hasImageBorderRadius = false,
  hasPressFeedback,
  unread = false,
  disabled = false,

  image,
  primary,
  primaryLineCount,
  secondary,
  left,
  right,
  height = DEFAULT_ITEM_HEIGHT,
  small = false,

  onPress,
  onLongPress,
  onNavigation,
}: Props) => {
  const Container = hasPressFeedback
    ? TouchableHighlight
    : TouchableWithoutFeedback;

  return (
    <Container
      testID={testID}
      style={{ height }}
      onPress={onNavigation ? onNavigation : onPress}
      onLongPress={onLongPress}
      delayLongPress={1000}
    >
      <Wrapper disabled={disabled}>
        <Row hasMargin={hasMargin} hasBorder={hasBorder} style={{ height }}>
          <LeftWrapper small={small}>
            {unread && (
              <View>
                <Badge
                  number
                  numberSize={5}
                  type={BADGE_TYPES.WARNING}
                  styleOverride={{
                    badge: {
                      position: "absolute",
                      top: Layout.mSize(-5),
                      left: -Layout.mSize(14),
                    },
                  }}
                />
              </View>
            )}
            {hasImage &&
              (typeof image === "object" ? (
                image
              ) : (
                <View>
                  <Avatar
                    image={image}
                    name={primary}
                    isRound={hasRoundImage}
                    hasBorderRadius={hasImageBorderRadius}
                    width={60}
                    height={60}
                    stylesOverride={{
                      avatar: {
                        marginRight: Layout.hSize(12),
                      },
                    }}
                  />
                </View>
              ))}
            {left}

            <NameWrapper>
              {secondary ? (
                <>
                  <Name
                    numberOfLines={primaryLineCount || 1}
                    ellipsizeMode="tail"
                    testID={`${testID}-primary`}
                    small={small}
                  >
                    {primary}
                  </Name>
                  {secondary}
                </>
              ) : (
                <Name
                  numberOfLines={primaryLineCount || 2}
                  ellipsizeMode="tail"
                  testID={`${testID}-primary`}
                  small={small}
                >
                  {primary}
                </Name>
              )}
            </NameWrapper>

            {hasInfo && (
              <InfoWrapper>
                <InfoTouchable onPress={onLongPress} onLongPress={onLongPress}>
                  <Info>
                    <MaterialIcons
                      name="info-outline"
                      size={Layout.mSize(20)}
                      color={Colors.primary}
                      style={styles.infoIcon}
                    />
                  </Info>
                </InfoTouchable>
              </InfoWrapper>
            )}
          </LeftWrapper>
          <RightWrapper>
            <Price testID={`${testID}-right`}>{right}</Price>
          </RightWrapper>

          {onNavigation && (
            <Feather
              name="chevron-right"
              size={24}
              color="none"
              stroke={Colors.font.main}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )}
        </Row>
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled.View`
  background-color: ${(props) =>
    props.disabled ? Colors.disabledBG : Colors.white};
`;

const LastRowStyles = css`
  border-bottom-color: ${Colors.border.main};
  border-bottom-width: 1px;
`;

const Row = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${(props) =>
    props.hasMargin &&
    css`
      margin-horizontal: ${Layout.hSize(15)}px;
    `};
  ${(props) => props.hasBorder && LastRowStyles};
`;

const LeftWrapper = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    !props.small &&
    css`
      margin-left: ${Layout.hSize(5)}px;
      margin-right: ${Layout.hSize(5)}px;
    `}
`;

const NameWrapper = styled.View`
  flex: 1;
`;

const Name = styled(Text)`
  ${(props) =>
    props.small
      ? tailwind("text-base font-normal text-gray-500")
      : css`
          font-size: ${Layout.mSize(18)}px;
          color: ${Colors.text};
          font-family: ${Layout.fontRegular};
        `}
`;

const InfoTouchable = styled.TouchableOpacity`
  padding: ${Layout.mSize(14)}px;
`;

const InfoWrapper = styled.View`
  position: absolute;
  top: ${Layout.mSize(22)}px;
  left: ${Layout.mSize(22)}px;
`;

const Info = styled.View`
  background-color: ${Colors.white};
  width: ${Layout.mSize(16)}px;
  height: ${Layout.mSize(16)}px;
  border-radius: ${Layout.mSize(16)}px;
`;

const RightWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: ${Layout.hSize(5)}px;
`;

const Price = styled(Text)`
  font-size: ${Layout.mSize(18)}px;
  color: ${Colors.secondaryTextColor};
  font-family: ${Layout.fontRegularLight};
  text-align: right;
  padding-right: ${Layout.hSize(5)}px;
`;

ListItem.Price = Price;

export default ListItem;

const styles = StyleSheet.create({
  infoIcon: {
    marginTop: -Layout.mSize(2),
    marginLeft: -Layout.mSize(2),
  },
});
