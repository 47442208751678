import { createSlice } from "@reduxjs/toolkit";
import isAfter from "date-fns/isAfter";
import map from "lodash/map";
import mapKeys from "lodash/mapKeys";
import toPairs from "lodash/toPairs";
import { v4 as uuidv4 } from "uuid";

import { DEFAULT_PRODUCT_ID } from "../../constants";
import {
  Product,
  CartProduct,
  Price,
  PaymentPlanType,
} from "../../types/Product";
import * as actions from "./actions";
import {
  CreateCartPayload,
  CartReduxState,
  CartList,
  ProductPricesPayload,
} from "./types";

function getProductList(cartList: CartList): CartProduct[] {
  // cartList is { 123: { id: 123 ... } }
  // pairs > ["123", { id: 123 ... }]
  // map index 1 > [{ id: 123 ... }]
  return map(toPairs(cartList), 1);
}

function getTotal(productList: CartProduct[]) {
  return productList.reduce((acc, product) => {
    return acc + product.quantity * product.price;
  }, 0);
}

function getQuantity(productList: CartProduct[]) {
  return productList.reduce((acc, product) => {
    return acc + product.quantity;
  }, 0);
}

export const initialState: CartReduxState = {
  // Amount of products in the cart
  quantity: 0,
  // Just a counter that triggers Cart's icon animation
  animated: 0,
  // cartList is { 123: { id: 123 ... }
  cartList: {},
  // productList is [{ id: 123 ... }]
  productList: [],
  prices: [],
  isSubscription: false,
  isPaymentPlan: false,
  selectedPrice: null,
  showSavingsAcrossPrices: true,
  total: 0,
  isCreatingCart: false,
  hasCreatingCartErrored: false,
  uuid: "",
  toast: { message: "", timeout: 5000 },
  utm: {},
  tracker: null,
  paylinkAmount: 0,
};

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Slice reducers can't use imported actions because they create new actions
  },
  extraReducers: {
    [actions.updatePaylinkAmount.type]: (
      state,
      { payload }: { payload: number }
    ) => {
      state.paylinkAmount = payload;
    },
    [actions.addToCart.type]: (state, action) => {
      const { product, prices } = action.payload;

      if (product.id !== DEFAULT_PRODUCT_ID) {
        state.animated += 1;
      }

      // subscription products can't be in a cart with other products
      state.productList
        // Select any product that has a price that is not one-off
        .filter((p) =>
          prices.some(
            (price: Price) =>
              p.price_uuid === price.uuid &&
              price.payment_plan_type !== PaymentPlanType.ONE_OFF
          )
        )
        // Remove the product from the cart
        .forEach((p) => delete state.cartList[p.id]);

      const previous = state.cartList[product.id];
      state.cartList[product.id] = {
        ...product,
        quantity: previous ? previous.quantity + 1 : 1,
      };

      state.productList = getProductList(state.cartList);
      state.quantity = getQuantity(state.productList);
      state.total = getTotal(state.productList);
    },
    [actions.removeFromCart.type]: (state, action) => {
      const product = action.payload;

      if (product.id !== DEFAULT_PRODUCT_ID) {
        state.animated -= 1;
      }

      delete state.cartList[product.id];

      state.productList = getProductList(state.cartList);
      state.quantity = getQuantity(state.productList);
      state.total = getTotal(state.productList);
    },
    [actions.updateProductQuantity.type]: (state, action) => {
      const { product, quantity } = action.payload;

      // If we're changing quantity for DEFAULT_PRODUCT_ID
      // it means that it hasn't been added to the cart yet
      // change quantity for a newly created product with uuid
      if (product.id === DEFAULT_PRODUCT_ID) {
        const uuid = uuidv4();

        delete state.cartList[DEFAULT_PRODUCT_ID];

        state.cartList[uuid] = {
          ...product,
          id: uuid,
          quantity: Number(quantity),
        };
      } else {
        state.cartList[product.id] = {
          ...product,
          quantity: Number(quantity),
        };
      }

      state.productList = getProductList(state.cartList);
      state.quantity = getQuantity(state.productList);
      state.total = getTotal(state.productList);
    },

    // Used in Catalogue
    [actions.removeExpiredProductsFromCart.type]: (
      state,
      { payload }: { payload: Product[] }
    ) => {
      if (payload && state.productList.length) {
        // Cart items where persisted (with `redux-persist`)
        // so we need to expire those out of sync
        state.productList.map((o) => {
          const productByKeys = mapKeys(payload, "id");
          const currentProduct = productByKeys[o.id];

          if (!currentProduct) {
            // Product is not available anymore (unpublished?)
            delete state.cartList[o.id];
          } else if (
            isAfter(new Date(currentProduct.updated_at), new Date(o.updated_at))
          ) {
            // Product is out of sync (price or inventory might have changed?)
            delete state.cartList[o.id];
          }
        });

        state.productList = getProductList(state.cartList);
        state.quantity = getQuantity(state.productList);
        state.total = getTotal(state.productList);
      }
    },

    [actions.updateProduct.type]: (state, action) => {
      const product = action.payload;

      const previous =
        state.cartList[product ? product.id : DEFAULT_PRODUCT_ID] || {};

      state.cartList[product.id] = {
        ...previous,
        ...product,
      };

      state.productList = getProductList(state.cartList);
      state.quantity = getQuantity(state.productList);
      state.total = getTotal(state.productList);
    },

    [actions.resetCart.type]: (state) => {
      // TODO Ideally we migrate PaymentRequest to its own feature
      // and listen for proper actions from there

      // we reset the cart before creating a new one, but this removes prices
      // from state. should we move selectedPrice and prices to catalogue reducer instead?
      return {
        ...initialState,
        selectedPrice: state.selectedPrice,
        prices: state.prices,
      };
    },

    [actions.createCartStart.type]: (state) => {
      state.isCreatingCart = true;
      state.hasCreatingCartErrored = false;
    },
    [actions.createCart.type]: (
      state: CartReduxState,
      action: { payload: CreateCartPayload }
    ) => {
      state.isCreatingCart = false;
      state.hasCreatingCartErrored = false;
      state.uuid = action.payload.uuid;
      state.isSubscription = action.payload.isSubscription;
    },
    [actions.createCartError.type]: (state: CartReduxState, { payload }) => {
      state.isCreatingCart = false;
      state.hasCreatingCartErrored = true;
    },
    [actions.showToast.type]: (state: CartReduxState, { payload }) => {
      state.toast = { ...payload };
    },
    [actions.selectPrice.type]: (
      state: CartReduxState,
      action: { payload: Price }
    ) => {
      state.selectedPrice = action.payload;
      state.isPaymentPlan =
        action.payload.payment_plan_type === PaymentPlanType.ONE_OFF ||
        action.payload.payment_plan_type === PaymentPlanType.INSTALLMENT;
    },
    [actions.clearSelectedPrice.type]: (state: CartReduxState) => {
      state.selectedPrice = null;
    },
    [actions.async.getProductPrices.fulfilled]: (
      state,
      action: { payload: ProductPricesPayload }
    ) => {
      state.prices = action.payload.prices;
      state.showSavingsAcrossPrices = action.payload.show_savings_across_prices;
    },
    [actions.setUtm.type]: (
      state,
      action: { payload: { [key: string]: string } }
    ) => {
      state.utm = action.payload;
    },
    [actions.setTracker.type]: (state, action: { payload: string }) => {
      state.tracker = action.payload;
    },
  },
});

export default cart;
