import React from "react";

import StoreLink from "./StoreLink";
import { Link } from "./types";

export { Props as StoreLinksProps };

type Props = {
  links: Link[];
  disableClicks?: boolean;
};

// we expose the bare component w/out redux for preview
export default function StoreLinks({ links, disableClicks }: Props) {
  const enabledLinks = links.filter((l) => l.enabled);

  if (!enabledLinks.length) return null;

  return (
    <div className="flex items-center gap-x-2 overflow-x-auto no-scrollbar">
      {enabledLinks.map((link) => (
        <StoreLink link={link} key={link.type} disabled={disableClicks} />
      ))}
    </div>
  );
}
