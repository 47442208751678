import React from "react";

import "intersection-observer";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";

import { CuantoAPI } from "./api/CuantoApiTyped";
import Error from "./components/Error/Error";
import { installSentry, captureException } from "./services/Sentry.web";
import Catalogue from "./web-app/Catalogue";
import { store, persistor } from "./web-app/State";
import "./web-app/i18n";


CuantoAPI.init();
const Sentry = installSentry();

const SOMETHING_WENT_WRONG =
  "¡Ups! Algo no funcionó como debería. 😅 \n\n Un mensaje ha sido enviado.";
const NEW_VERSION_MSG =
  "La aplicación fue actualizada, refrescaremos su pantalla en un momento.";

export default function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Catalogue />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

// https://docs.sentry.io/platforms/javascript/react/
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.Browser.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = captureException(error);
      this.setState({ eventId, error });
    });
    // Reload app only on ChunkLoadError.
    if (error?.name === "ChunkLoadError") this.reloadApp();
  }

  reloadApp() {
    setTimeout(() => window.location.reload(), 3000);
  }

  render() {
    if (this.state.hasError) {
      if (this.state.error?.name === "ChunkLoadError") {
        return <Error message={NEW_VERSION_MSG} />;
      }
      return <Error message={SOMETHING_WENT_WRONG} />;
    }

    return this.props.children;
  }
}

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
