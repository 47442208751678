import React, { useState } from "react";

import { mdiCartPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { ActivityIndicator, View } from "react-native";
import { useLockBodyScroll } from "react-use";

import ListItem from "cuanto/components/List/ListItem";
import { Layout } from "cuanto/constants";
import { amountAsCurrency } from "cuanto/services/currency";
import { tailwind } from "cuanto/tailwind";
import { Category } from "cuanto/types/Category";
import CatalogueSocialProofWrapper from "cuanto/web-app/components/CatalogueSocialProofWrapper";
import CategoriesPills from "cuanto/web-app/components/CategoriesPills.web";
import CatalogueBanner from "cuanto/web-app/components/ProductCatalogue/CatalogueBanner";
import CatalogueFooter from "cuanto/web-app/components/ProductCatalogue/CatalogueFooter";
import CatalogueBio from "cuanto/web-app/components/StorePersonalization/CatalogueBio";
import StoreLinks from "cuanto/web-app/components/StorePersonalization/StoreLinks";
import { StoreConfig } from "cuanto/web-app/components/StorePersonalization/types";

import ProductVariantPicker, {
  useVariantPicker,
} from "../CatalogueLink/ProductVariantPicker";
import BottomBox from "../components/BottomBox";
import Button from "../components/Button";
import CatalogueHeader from "../components/CatalogueHeader";
import Modal from "../components/Modal";
import Toast, { useToast } from "../components/Toast";
import { Merchant, Price, Product } from "../types";
import ProductsList from "./ProductsList";

type Props = {
  merchant: Merchant;
  categories: Category[];
  selectedCategoryUuid: string;
  storeConfig: StoreConfig;
  storeClosed: boolean;
  cartQuantity: number;
  products: Product[];
  numCatalogueColumns: number;
  productGroups: Record<string, Product[]>;
  priceGroups: Record<string, Price[]>;
  totalTransactionCount: number;
  reviewsAverage: number | null;
  deliveryEnabled: boolean;
  onAddToCart: (product: Product) => void;
  onSelectCategory: (uuid: string) => void;
  onSelectProduct: (product: Product) => void;
  onGoToCart: () => void;
  onMoreInfoPress: () => void;
};

export default function Catalogue({
  merchant,
  categories,
  selectedCategoryUuid,
  storeConfig,
  cartQuantity,
  storeClosed,
  products,
  productGroups,
  priceGroups,
  numCatalogueColumns,
  totalTransactionCount,
  reviewsAverage,
  deliveryEnabled,
  onAddToCart,
  onGoToCart,
  onSelectCategory,
  onSelectProduct,
  onMoreInfoPress,
}: Props) {
  const { toast, showToast } = useToast("Producto agregado a tu carrito");

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [locked, toggleLocked] = useState(false);
  useLockBodyScroll(locked);

  const isButtonVisible = cartQuantity > 0 && selectedProduct === null;
  const isButtonEnabled = cartQuantity > 0 && !storeClosed; // TODO: hasTotal && !isLoading && !storeClosed
  const isLoading = false; // TODO: based on inventory?

  const productVariants = selectedProduct
    ? productGroups[selectedProduct.uuid]
    : [];

  function onModalClose() {
    toggleLocked(false);
    setSelectedProduct(null);
  }

  function handleAddToCart(product: Product) {
    if (storeClosed) return;

    if (productGroups[product.uuid]) {
      toggleLocked(true);
      setSelectedProduct(product);
    } else {
      showToast();
      onAddToCart(product);
    }
  }

  return (
    <div>
      <Toast text={toast} />

      {selectedProduct && (
        <VariantPickerModal
          productGroup={selectedProduct}
          productVariants={productVariants}
          storeClosed={storeClosed}
          onAddToCart={handleAddToCart}
          onClose={onModalClose}
        />
      )}

      <CatalogueBanner bannerUrl={storeConfig.banner_image_url} />

      <div className="m-4">
        <CatalogueHeader
          merchant={merchant}
          totalTransactionCount={totalTransactionCount}
          reviewsAverage={reviewsAverage}
          deliveryEnabled={deliveryEnabled}
          onMoreInfoPress={onMoreInfoPress}
        />
        <div className="mt-3">
          <StoreLinks links={storeConfig.links} />
        </div>
        <CatalogueBio bio={storeConfig.bio} />
      </div>

      <div className="pl-4">
        <CategoriesPills
          categories={categories}
          selectedCategoryUuid={selectedCategoryUuid}
          onCategoryClick={onSelectCategory}
          resetSelectedCategoryPillVisbile
        />
      </div>

      <ProductsList
        storeClosed={storeClosed}
        products={products}
        merchant={merchant}
        priceGroups={priceGroups}
        productGroups={productGroups}
        numColumns={numCatalogueColumns}
        handleSelect={onSelectProduct}
        handleAddToCart={handleAddToCart}
      />

      <CatalogueFooter />

      {selectedCategoryUuid === "all" && (
        <CatalogueSocialProofWrapper
          transactionCount={totalTransactionCount}
          cartQuantity={cartQuantity}
        />
      )}

      {isButtonVisible && (
        <BottomBox>
          <Button
            text={`Ver carrito (${cartQuantity})`}
            disabled={!isButtonEnabled}
            icon={isLoading ? <ActivityIndicator /> : undefined}
            onClick={onGoToCart}
          />
        </BottomBox>
      )}
    </div>
  );
}

type ModalProps = {
  productGroup: Product;
  productVariants: Product[];
  storeClosed: boolean;
  onAddToCart: (product: Product) => void;
  onClose: () => void;
};
function VariantPickerModal({
  productGroup,
  productVariants,
  storeClosed,
  onAddToCart,
  onClose,
}: ModalProps) {
  const [showVariantError, setShowVariantError] = useState(false);
  const {
    hasVariants,
    variants,
    currentVariantSelection,
    filteredProductVariants,
    dispatch,
  } = useVariantPicker({
    productGroup,
    productVariants,
  });

  const invalidVariant = hasVariants && filteredProductVariants.length !== 1;
  const isButtonActive = !invalidVariant && !storeClosed;

  function getButtonText() {
    if (invalidVariant) {
      return "Selecciona variantes";
    } else if (storeClosed) {
      return "Cerrado";
    } else {
      return "Agregar al carrito";
    }
  }

  const buttonText = getButtonText();

  return (
    <Modal visible={productGroup !== null} onRequestClose={onClose}>
      <Modal.Header>
        <div>
          <span className="text-lg font-sans font-medium text-gray-900">
            Elige las variables de tu producto
          </span>

          <div className="mt-5">
            <ListItem
              small
              hasImageBorderRadius={true}
              hasRoundImage={false}
              image={productGroup.images[0]}
              left={
                <span className="text-base font-bold font-sans text-gray-900 pr-2">
                  1x
                </span>
              }
              primary={productGroup.name}
              secondary={
                <span className="text-sm text-gray-900 font-sans	font-bold">
                  {amountAsCurrency({
                    amount: productGroup.price,
                    currency: productGroup.currency,
                  })}
                </span>
              }
              hasMargin={false}
              hasBorder={false}
              height={Layout.vSize(60)}
            />
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <ProductVariantPicker
          variants={variants}
          products={productVariants}
          variantSelection={currentVariantSelection}
          showVariantError={showVariantError}
          onChange={(key, value) =>
            dispatch({ type: "filter", payload: { [key]: value } })
          }
        />
      </Modal.Body>

      <Modal.Footer>
        <View
          style={tailwind(
            "border-t border-white-300 bg-white p-4 shadow-reverse"
          )}
        >
          <Button
            testID={`product-group-modal-confirm`}
            disabled={!isButtonActive}
            icon={isButtonActive ? <Icon path={mdiCartPlus} /> : undefined}
            text={buttonText}
            onClick={() => {
              setShowVariantError(filteredProductVariants.length !== 1);

              if (filteredProductVariants.length === 1) {
                onAddToCart(filteredProductVariants[0]);
                onClose();
              }
            }}
          />
        </View>
      </Modal.Footer>
    </Modal>
  );
}
