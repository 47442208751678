import React from "react";
import { View, StyleSheet } from "react-native";

import StyledText from "../StyledText";

import { Layout } from "../../constants";

import { BADGE_TYPES, BadgeTypes } from "./BadgeTypes";

export default function Badge({
  type = BADGE_TYPES.DEFAULT,
  number,
  numberSize = 8,
  hideEmpty = false,
  testID = "badge",
  styleOverride = {},
}) {
  if (hideEmpty && (!number || number === 0)) {
    return null;
  }

  return (
    <View
      style={[
        styles.badge,
        {
          backgroundColor: BadgeTypes[type].badge.background,
          width: Layout.mSize(numberSize * 1.75),
          height: Layout.mSize(numberSize * 1.75),
          borderRadius: Layout.mSize(numberSize * 1.75) / 2,
        },
        styleOverride.badge,
      ]}
    >
      <StyledText
        testID={testID}
        style={[
          styles.number,
          {
            color: BadgeTypes[type].number.color,
            fontSize: Layout.mSize(numberSize),
            width: Layout.mSize(numberSize * 1.75),
            height: Layout.mSize(numberSize * 1.75),
            lineHeight: Layout.mSize(numberSize * 1.75),
          },
        ]}
      >
        {number}
      </StyledText>
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    justifyContent: "center",
    alignItems: "center",
  },
  number: {
    textAlign: "center",
    fontFamily: Layout.fontRegular,
  },
});
