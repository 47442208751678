// returns true if the user agent is an instagram browser
export function isFromInstagramBrowser() {
  const ua = navigator.userAgent;
  return (
    ua.indexOf("Instagram") > -1 ||
    ua.indexOf("instagram") > -1 ||
    ua.indexOf("FBAN") > -1 ||
    ua.indexOf("FBAV") > -1 ||
    isFromIGPost()
  );
}

// Return true if the user came from a shoppable post
function isFromIGPost() {
  const str = document.location.search;
  return str && (isFromOurShoppablePost(str) || isFromIGShoppablePost(str));
}

function isFromOurShoppablePost(str: string) {
  return (
    str.indexOf("utm_source=instagram") > -1 &&
    str.indexOf("utm_medium=shoppable_post") > -1 &&
    str.indexOf("utm_campaign=store_engagement") > -1
  );
}

function isFromIGShoppablePost(str: string) {
  return (
    str.indexOf("utm_source=IGShopping") > -1 &&
    str.indexOf("utm_medium=Social") > -1
  );
}
