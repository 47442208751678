import React from "react";

import Bit128 from "../../../components/Assets/Bit128";
import MasterCard from "../../../components/Assets/MasterCard.web";
import Visa from "../../../components/Assets/Visa.web";

export default function CatalogueFooter() {
  return (
    <div className="mt-24">
      <div className="flex justify-center">
        <Visa width="86" height="38" />
        <Bit128 width="58" height="38" />
        <MasterCard width="86" height="38" />
      </div>

      <div className="flex flex-col gap-y-4 items-center justify-center mt-8">
        <Link
          url="https://web.cuanto.app/termsandconditions"
          text="Términos y condiciones"
        />
      </div>
    </div>
  );
}

function Link({ url, text }: { url: string; text: string }) {
  return (
    <a
      className="text-primary-500 text-center text-sm no-underline active:text-primary-100 cursor-pointer select-none"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );
}
