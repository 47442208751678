import React from "react";

import classNames from "classnames";

type ButtonType = "primary" | "secondary" | "danger" | "lowEmphasis" | "text";
type ClickType = "button" | "submit" | "reset";

type Props = {
  text: string | JSX.Element;
  icon?: JSX.Element;
  disabled?: boolean;
  small?: boolean;
  testID?: string;
  buttonType?: ButtonType;
  clickType?: ClickType;
  onClick?: () => void;
  narrow?: boolean;
  styles?: string;
};

const BASE_STYLE =
  "flex items-center justify-center rounded-md px-3.5 font-sans shadow";

const DISABLED_STYLE = "bg-gray-100 active:bg-gray-100 text-gray-200 active:";
const SMALL_STYLE = "text-sm leading-4 py-2.5";
const REGULAR_STYLE = "text-base leading-6 py-3.5";

const PRIMARY_STYLE =
  "bg-primary-500 active:bg-primary-700 text-white-100 border-none";
const SECONDARY_STYLE =
  "bg-white-100 active:bg-white-300 text-primary-600 border border-solid border-gray-200";
const DANGER_STYLE = "bg-red-500 active:bg-red-600 text-white-100 border-none";
const TEXT_STYLE =
  "bg-white active:bg-white text-primary-600 active:text-primary-500 border-none";
const LOW_EMPHASIS_STYLE =
  "bg-white-100 active:bg-white-300 text-gray-400 border border-solid border-gray-200";

const styleFromType = (buttonType: ButtonType): string => {
  switch (buttonType) {
    case "secondary":
      return SECONDARY_STYLE;
    case "danger":
      return DANGER_STYLE;
    case "text":
      return TEXT_STYLE;
    case "lowEmphasis":
      return LOW_EMPHASIS_STYLE;
    case "primary":
    default:
      return PRIMARY_STYLE;
  }
};

const Button = ({
  text,
  buttonType = "primary",
  clickType = "button",
  icon,
  small,
  disabled,
  testID,
  onClick,
  narrow = false,
  styles = "",
}: Props) => {
  const style = classNames(BASE_STYLE, styleFromType(buttonType), {
    [DISABLED_STYLE]: disabled,
    [SMALL_STYLE]: small,
    [REGULAR_STYLE]: !small,
    ["w-full"]: !narrow,
    [styles]: styles,
  });

  return (
    <button
      className={style}
      onClick={disabled ? () => {} : onClick}
      data-testid={testID}
      type={clickType}
    >
      {icon && <div className="h-5 w-5 pr-1">{icon}</div>}
      {text}
    </button>
  );
};

export default Button;
