import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { KJUR, b64utos } from "jsrsasign";
import isEmpty from "lodash/isEmpty";

import { ConsumerSessionRequest } from "cuanto/types/Consumer";

import { CuantoAPI } from "../../../api/CuantoAPI";
import { CuantoAPI as CuantoAPITyped } from "../../../api/CuantoApiTyped";
import { captureException } from "../../../services/Sentry.web";
import { DeliveryAddress, Fulfillment } from "../../../types/Delivery";
import { ConsumerPaymentMethod } from "../../../types/PaymentMethod";

export const setConsumerEmail = createAction<string>(
  "consumer/setConsumerEmail"
);
export const setConsumerName = createAction<string>("consumer/setConsumerName");

export const updateDeliveryAddress = createAction<DeliveryAddress>(
  "consumer/updateDeliveryAddress"
);

export const updatePaymentMethod = createAction<ConsumerPaymentMethod>(
  "consumer/updatePaymentMethod"
);
export const updatePaymentMethodCode = createAction<string>(
  "consumer/updatePaymentMethodCode"
);
export const resetPaymentMethod = createAction("consumer/resetPaymentMethod");

const getConsumerId = (token) => {
  const parts = token.split(".");
  const parsed = b64utos(parts[1]);
  const claim = KJUR.jws.JWS.readSafeJSONString(parsed);

  return claim.sub.id;
};

const asyncThunkCallback =
  (method) =>
  async (params, { rejectWithValue }) => {
    const token = JSON.parse(localStorage.getItem("authorization"));
    const consumerId = token ? getConsumerId(token) : null;

    const api = new CuantoAPI(token);
    const operation = api[method];

    try {
      const response = await operation({
        consumer_id: consumerId,
        ...params,
      });
      return response.data;
    } catch (error) {
      captureException(error);
      return rejectWithValue(error);
    }
  };

const createConsumerSession = createAsyncThunk<
  { jwt: string },
  ConsumerSessionRequest
>("consumer/sessionCreate", asyncThunkCallback("createConsumerSession"));

const fetchConsumerAddresses = createAsyncThunk<DeliveryAddress[]>(
  "consumer/fetchAddresses",
  asyncThunkCallback("getConsumerAddresses")
);
const createConsumerAddress = createAsyncThunk(
  "consumer/createAddress",
  asyncThunkCallback("createConsumerAddress")
);
const updateConsumerAddress = createAsyncThunk(
  "consumer/updateAddress",
  asyncThunkCallback("updateConsumerAddress")
);
const deleteConsumerAddress = createAsyncThunk(
  "consumer/deleteAddress",
  asyncThunkCallback("deleteConsumerAddress")
);

const fetchConsumerPaymentMethods = createAsyncThunk<ConsumerPaymentMethod[]>(
  "consumer/fetchPaymentMethods",
  asyncThunkCallback("getConsumerPaymentMethods")
);

const updateConsumerPaymentMethods = createAction<ConsumerPaymentMethod>(
  "consumer/updatePaymentMethods"
);

const updateConsumerPaymentMethod = createAsyncThunk(
  "consumer/updatePaymentMethod",
  async (paymentMethod: ConsumerPaymentMethod) => {
    const token = JSON.parse(localStorage.getItem("authorization"));
    const consumerId = token ? getConsumerId(token) : null;

    const api = new CuantoAPITyped({ authenticationToken: token });

    const response = await api.updateConsumerPaymentMethod(
      consumerId,
      paymentMethod
    );
    return response.data;
  }
);
const deleteConsumerPaymentMethod = createAsyncThunk(
  "consumer/deletePaymentMethod",
  asyncThunkCallback("deleteConsumerPaymentMethod")
);

export const async = {
  createConsumerSession,

  fetchConsumerAddresses,
  createConsumerAddress,
  updateConsumerAddress,
  deleteConsumerAddress,

  fetchConsumerPaymentMethods,
  updateConsumerPaymentMethod,
  updateConsumerPaymentMethods,
  deleteConsumerPaymentMethod,

  createConsumerFulfillment:
    (address: DeliveryAddress) => (dispatch, getState) => {
      const { catalogue, cart, fulfillments } = getState();

      const fulfillment: Fulfillment = {
        ...address,
        cellphone: address.phone,
        fulfillment_option_uuid: fulfillments.selectedFulfillmentOption.uuid,
      };

      const isPaymentRequest = !isEmpty(catalogue.paymentRequest);
      if (isPaymentRequest) {
        fulfillment.cart_uuid = catalogue.paymentRequest.cart_uuid;
      } else {
        fulfillment.cart_uuid = cart.uuid;
      }

      const api = new CuantoAPI();
      return api.createFulfillment(fulfillment);
    },
};
