import React from "react";

import styled, { css } from "styled-components/native";

import Text from "../Text";

import { Layout, Colors } from "../../constants";

interface Props {
  children: React.ReactNode;
  isEmpty: boolean;
  size: number;
}

export default function SquareCashCentsText({
  children,
  isEmpty = true,
  size = Layout.sizes.XL,
}: Props) {
  return (
    <Cents empty={isEmpty} fontSize={Layout.mSize(size)}>
      {children}
    </Cents>
  );
}

const Cents = styled(Text)`
  color: ${Colors.text};
  font-family: ${Layout.fontRegularLight};
  font-size: ${(props) => props.fontSize}px;

  ${(props) =>
    props.empty &&
    css`
      color: ${Colors.placeholder};
      font-family: ${Layout.fontRegularLight};
    `};
`;
