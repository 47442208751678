import React from "react";
import {
  View,
  TouchableWithoutFeedback,
  Keyboard,
  StyleSheet,
  Image,
} from "react-native";

import StyledText from "./StyledText";

import Layout from "../constants/Layout";
import Colors from "../constants/Colors";

const iconSize = Layout.normalize(48) * Layout.mscale;
const MESSAGE_TEXT = "Buscando...";

export default class LoadingSearchResults extends React.Component {
  render() {
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={[styles.body, styles.top]}>
          <View style={styles.noResults}>
            <View style={styles.loadingIconWrapper}>
              <Image
                style={styles.gif}
                source={require("../assets/gifs/spinner.gif")}
              />
            </View>
            <StyledText style={styles.messageText}>{MESSAGE_TEXT}</StyledText>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  body: {
    backgroundColor: Colors.mainBG,
    paddingBottom: Layout.buttonHeight,
    flex: 1,
  },
  top: {
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "15%",
  },
  noResults: {
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  gif: {
    height: iconSize,
    width: iconSize,
  },
  loadingIconWrapper: {
    height: iconSize * 1.5,
    width: iconSize * 1.5,
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: Layout.normalize(22) * Layout.vscale,
  },
  messageText: {
    color: Colors.mainTextColor,
    fontFamily: Layout.fontRegular,
    textAlign: "center",
    fontSize: Layout.normalize(17) * Layout.mscale,
    marginBottom: Layout.normalize(25) * Layout.vscale,
  },
});
