import GlobalStyles from "./GlobalStyles";
import Colors from "./Colors";
import Layout from "./Layout";
import Copy from "./Copy";

const SUPPORT_CELLPHONE_NUMBER = "14154297380";
const DEFAULT_PRODUCT_ID = "payment-request";

export {
  GlobalStyles,
  Colors,
  Layout,
  Copy,
  SUPPORT_CELLPHONE_NUMBER,
  DEFAULT_PRODUCT_ID,
};
