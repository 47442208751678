import React, { PureComponent } from "react";
import { View } from "react-native";

import styled from "styled-components/native";

import MasterCard from "./Assets/MasterCard.web";
import Visa from "./Assets/Visa.web";

import { Layout } from "../constants";

function firstDigitIsValid(number) {
  return number[0] === "4" || number[0] === "5";
}

function getCardType(number) {
  if (number[0] === "4") {
    return "visa";
  }
  if (number[0] === "5") {
    return "mastercard";
  }

  return "";
}

interface Props {
  number: string;
}

export class CardType extends PureComponent<Props> {
  render() {
    const { number } = this.props;

    return (
      <Container>
        <View>
          <Icon>
            <Card number={number} />
          </Icon>
        </View>
      </Container>
    );
  }
}

export const Card = ({ number }: Props) => {
  return (
    firstDigitIsValid(number) && (
      <>
        {(() => {
          switch (getCardType(number)) {
            case "visa":
              return <Visa width="32" height="20" />;
            case "mastercard":
              return <MasterCard width="32" height="20" />;
            default:
              return null;
          }
        })()}
      </>
    )
  );
};

export default CardType;

const Container = styled.View`
  position: absolute;
  top: ${Layout.vSize(30)}px;
  right: ${Layout.hSize(20)}px;
  width: auto;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
`;

const Icon = styled.View`
  position: absolute;
  left: ${Layout.hSize(-32)}px;
  top: ${Layout.vSize(6)}px;
`;
