import get from "lodash/get";
import * as Sentry from "sentry-expo";

import { AxiosError } from "../types/Error";

// cuanto / expo-web-catalogue
const SENTRY_PUBLIC_DSN =
  "https://f5b49bdd7596482aa1b243cba13fb625@sentry.io/1510869";

export function installSentry() {
  // https://docs.expo.io/guides/using-sentry/
  Sentry.init({
    dsn: SENTRY_PUBLIC_DSN,
    // Remove this once Sentry is correctly setup
    // enableInExpoDevelopment: true,
    // Sentry will try to print out useful debugging information if something goes wrong with sending an event.
    // Set this to `false` in production.
    // debug: true,
    release: process.env.RELEASE_NAME,
  });

  return Sentry;
}

export function captureException(error, params = {}) {
  if (process.env.EXPO_RELEASE_CHANNEL === "production") {
    Sentry.Browser.captureException(error, params);
  } else {
    console.error(error, params);
  }
}

export function serializeException(error: AxiosError) {
  const payload = get(error, "response.data", {
    message: "Error en el pago",
    details:
      "No fue posible procesar tu pago.\n\nPor favor, intenta nuevamente.",
    error_keys: [],
  });

  return payload;
}

export function setUserContext(email) {
  if (email) {
    return Sentry.Browser.configureScope((scope) => {
      scope.setUser({ email });
    });
  }
}

export function removeUserContext() {
  return Sentry.Browser.setUser();
}

export function captureMessage(msg, context = {}) {
  return Sentry.Browser.captureMessage(msg, context);
}
