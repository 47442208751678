import React, { useCallback, useState } from "react";

import { mdiCartPlus } from "@mdi/js";
import Icon from "@mdi/react";

import { useThrottleFn } from "cuanto/hooks/useThrottleFn";

import Button from "./Button";

type Props =
  | { text: string; onPress?: never; quantity?: never }
  | { text: string; onPress: () => void; quantity: number };

// displays an autoclearing text with an optional button to pay now
export default function Toast({ text, ...btnProps }: Props) {
  if (!text) return null;

  return (
    <div className="fixed top-0 w-ecommerce z-50 ">
      <div className="flex items-center justify-center bg-gray-50 h-14 shadow-xl">
        <Icon path={mdiCartPlus} className="text-primary-500 mr-4 h-5 w-5" />
        <span className="text-gray-800 leading-6">{text}</span>
      </div>

      <PayButton {...btnProps} />
    </div>
  );
}

function PayButton({ quantity, onPress }: Pick<Props, "quantity" | "onPress">) {
  if (!quantity || !onPress) return null;

  return (
    <div className="pt-2 px-4">
      <Button text={`Pagar (${quantity})`} onClick={onPress} small />
    </div>
  );
}

const TIMEOUT = 5000;

export function useToast(message: string, timeoutMs = TIMEOUT) {
  const [toast, setToast] = useState("");

  const showToast = useThrottleFn(
    useCallback(() => {
      setToast(message);
      setTimeout(() => setToast(""), timeoutMs);
    }, []),
    timeoutMs,
    { trailing: false }
  );

  return { toast, showToast };
}
