import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

import { async } from "cuanto/web-app/actions";

// KEY, path, and domain must match checkout's key in services/Cookies.ts
const KEY = "reset-cart";

export default function useResetCartCookie(username: string) {
  const dispatch = useDispatch();
  const cookie = Cookies.get(KEY);

  if (cookie) {
    Cookies.remove(KEY, { path: `/${username}`, domain: ".cuanto.app" });
    dispatch(async.nuke());
  }
}
