export const i18n = {
  catalogue: {
    paymentResult: {
      bank: {
        instructions:
          "Por favor realiza una transferencia bancaria por <0>{{amount}}</0> a:",
      },
    },
  },
};
