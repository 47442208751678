import React from "react";

import styled from "styled-components/native";

import { Layout, Colors } from "../../constants";
import StyledText from "../StyledText";

interface Props {
  message: string;
}

export default function Error(props: Props) {
  return (
    <Screen>
      <Container>
        <TextEmoji>🤷‍♀️</TextEmoji>
        <TextEmoji />
        <TextEmoji />
        <Text>{props.message}</Text>
        <Text />
        <Text />
      </Container>
    </Screen>
  );
}

const Screen = styled.View`
  flex: 1;
  justify-content: center;
  background-color: ${Colors.mainBG};
`;

const Container = styled.View`
  padding: ${Layout.mSize(48)}px;
  align-items: center;
`;

const Text = styled(StyledText)`
  color: ${Colors.text};
  font-size: ${Layout.mSize(24)}px;
  text-align: center;
`;

const TextEmoji = styled(StyledText)`
  font-size: ${Layout.mSize(36)}px;
`;
