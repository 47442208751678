import React from "react";

import * as Linking from "expo-linking";
import { View, StyleSheet } from "react-native";

import Button from "cuanto/web-app/components/Button.web";

import { Colors, Layout } from "../constants";
import StyledText from "./StyledText";

function goToUrl(url) {
  return Linking.openURL(url);
}

export default function WaitList({ countryCode, url }) {
  if (countryCode == "506") {
    return (
      <WaitListCostaRica url={url} buttonOnPress={goToUrl.bind(null, url)} />
    );
  } else {
    return (
      <GenericWaitList url={url} buttonOnPress={goToUrl.bind(null, url)} />
    );
  }
}

function WaitListCostaRica({ buttonOnPress }) {
  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <StyledText style={[styles.text, styles.bigText]}>
          ¡Solicitá acceso para usar Cuanto en Costa Rica!
        </StyledText>
        <StyledText style={[styles.text, styles.emojis]}>🇨🇷🙌</StyledText>
        <View style={styles.textContainer}>
          <View style={styles.paragraph}>
            <StyledText style={[styles.text, styles.sentence]}>
              Estamos trabajando para apoyar a
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              todos los creadores y
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              emprendedores ticos.
            </StyledText>
          </View>
          <View style={styles.paragraphDivider} />
          <View style={styles.paragraph}>
            <StyledText style={[styles.text, styles.sentence]}>
              ¡Podés solicitar acceso
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              respondiendo algunas preguntas
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              sobre tu negocio!
            </StyledText>
          </View>
        </View>
      </View>
      <View style={styles.buttonWrapper}>
        <Button text={"Continuar"} onClick={buttonOnPress} />
      </View>
    </View>
  );
}

function GenericWaitList({ buttonOnPress }) {
  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <StyledText style={[styles.text, styles.bigText]}>
          ¡Solicita acceso para usar Cuanto en tu país!
        </StyledText>
        <StyledText style={[styles.text, styles.emojis]}>🙌</StyledText>
        <View style={styles.textContainer}>
          <View style={styles.paragraph}>
            <StyledText style={[styles.text, styles.sentence]}>
              Estamos trabajando para apoyar a
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              todos los creadores y
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              emprendedores de la región.
            </StyledText>
          </View>
          <View style={styles.paragraphDivider} />
          <View style={styles.paragraph}>
            <StyledText style={[styles.text, styles.sentence]}>
              ¡Puedes solicitar acceso
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              respondiendo algunas preguntas
            </StyledText>
            <StyledText style={[styles.text, styles.sentence]}>
              sobre tu negocio!
            </StyledText>
          </View>
        </View>
      </View>
      <View style={styles.buttonWrapper}>
        <Button text={"Continuar"} onPress={buttonOnPress} isActive={true} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontFamily: Layout.fontRegularLight,
    textAlign: "center",
    color: Colors.font.main,
  },
  screen: {
    backgroundColor: Colors.mainBG,
    height: Layout.window.height,
    flex: 1,
    overflow: "scroll",
  },
  container: {
    height: Layout.window.height * 0.9,
    paddingTop: Layout.window.height * 0.05,
    paddingBottom: Layout.window.height * 0.05,
  },
  bigText: {
    fontSize: Layout.normalize(24) * Layout.mscale,
    padding: Layout.normalize(50) * Layout.mscale,
    paddingBottom: Layout.normalize(20) * Layout.vscale,
  },
  emojis: {
    textAlign: "center",
    fontSize: Layout.normalize(56) * Layout.mscale,
    height: Layout.window.height * 0.2,
    paddingTop: Layout.window.height * 0.05,
    paddingBottom: Layout.window.height * 0.05,
  },
  textContainer: {
    paddingTop: Layout.normalize(50) * Layout.mscale,
    paddingBottom: Layout.normalize(50) * Layout.mscale,
  },
  paragraphDivider: {
    height: Layout.normalize(20) * Layout.mscale,
  },
  paragraph: {
    // paddingTop: Layout.normalize(50)*Layout.mscale,
  },
  sentence: {
    fontSize: Layout.normalize(16) * Layout.mscale,
  },
  buttonWrapper: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
});
