import { amountAsCurrency } from "cuanto/services/currency";

import { Currency, PaymentPlanType, Price } from "../types";

/**
 * extends {@link pricesPreview} with a more detailed preview for a single price
 * includes an anchor if possible (to show in larger size), + price preview
 */
export function detailedPricesPreview(prices: Price[], currency: Currency) {
  const preview = pricesPreview(prices, currency);

  if (prices.length === 1) {
    const price = prices[0];

    if (price.payment_plan_type === PaymentPlanType.INSTALLMENT) {
      const interval = priceInterval(price);
      return {
        anchor: amountAsCurrency({ amount: price.amount, currency }),
        details: `en ${preview} cada ${interval}`,
      };
    }
  }

  const someInstallments = isSome(PaymentPlanType.INSTALLMENT, prices);
  const someOneOffs = isSome(PaymentPlanType.ONE_OFF, prices);
  const noSubscriptions = !isSome(PaymentPlanType.RECURRING, prices);

  if (someInstallments && someOneOffs && noSubscriptions) {
    const oneOffs = prices.filter(
      (p) => p.payment_plan_type === PaymentPlanType.ONE_OFF
    );

    const installments = prices.filter(
      (p) => p.payment_plan_type === PaymentPlanType.INSTALLMENT
    );

    const amount = Math.min(...oneOffs.map((p) => p.amount));
    const anchor = amountAsCurrency({ amount, currency });
    const insPreview = installmentsPricePreview(installments, currency, true);
    const details = insPreview.replace("desde", "o en");

    return { anchor, details };
  }

  return { anchor: null, details: preview };
}

export function pricesPreview(prices: Price[], currency: Currency) {
  if (prices.length === 1) {
    const price = prices[0];

    switch (price.payment_plan_type) {
      case PaymentPlanType.ONE_OFF:
        return amountAsCurrency({ amount: price.amount, currency });
      case PaymentPlanType.RECURRING:
        return subscriptionPricePreview(price, currency);
      case PaymentPlanType.INSTALLMENT:
        return installmentPricePreview(price, currency);
    }
  }

  const allOneOffs = isAll(PaymentPlanType.ONE_OFF, prices);
  if (allOneOffs) return oneOffsPricePreview(prices, currency);

  const allSubscriptions = isAll(PaymentPlanType.RECURRING, prices);
  if (allSubscriptions) return subscriptionsPricePreview(prices, currency);

  const allInstallments = isAll(PaymentPlanType.INSTALLMENT, prices);
  if (allInstallments) return installmentsPricePreview(prices, currency);

  return mixedPricePreview(prices, currency);
}

function isSome(type: PaymentPlanType, prices: Price[]) {
  return prices.some((price) => price.payment_plan_type === type);
}

function isAll(type: PaymentPlanType, prices: Price[]) {
  return prices.every((price) => price.payment_plan_type === type);
}

/**
 * @throws if `payment_plan_type` is not {@link PaymentPlanType.INSTALLMENT}}
 * @throws if `installments_count` is missing {@link Price.installments_count}

 * @returns "`installments_count` pagos de `pricePerInstallment`"
 * @example installmentPricePreview({ amount: 100_00, installments_count: 5, payment_plan_type: PaymentPlanType.INSTALLMENT }, "usd");
 * "5 pagos de $20"
 */
function installmentPricePreview(price: Price, currency: Currency) {
  if (price.payment_plan_type !== PaymentPlanType.INSTALLMENT) {
    throw new Error("Price is not an installment");
  }

  if (price.installments_count === undefined) {
    throw new Error("Price is missing installments_count");
  }

  const pricePerInstallment = amountAsCurrency({
    amount: price.amount / price.installments_count,
    currency,
  });

  return `${price.installments_count} pagos de ${pricePerInstallment}`;
}

/**
 *
 * @example subscriptionPricePreview({ amount: 100_00, recurring_interval: "month", recurring_interval_count: 1 }, "usd");
 * "$100 cada mes"
 * "$50 cada 2 semanas"
 */
function subscriptionPricePreview(price: Price, currency: Currency) {
  const amount = amountAsCurrency({ amount: price.amount, currency });

  const interval = priceInterval(price);

  return `${amount} cada ${interval}`;
}

/**
 * @example oneOffsPricePreview([{ amount: 100_00 }, { amount: 50_00 }], "usd");
 * "desde $50"
 */
function oneOffsPricePreview(prices: Price[], currency: Currency) {
  const minAmount = Math.min(...prices.map((price) => price.amount));
  const amount = amountAsCurrency({ amount: minAmount, currency });
  return prices.length > 1 ? `desde ${amount}` : amount;
}

const intervalInDays = { day: 1, week: 7, month: 30, year: 365 } as const;

/**
 * @returns the lowest prorated amount by the lowest recurring interval
 * @example subscriptionsPricePreview([subscription({ amount: 10_00, recurring_interval: "day" }), subscription({ amount: 60_00, recurring_interval: "week" })], "usd");
 * "desde $8.57 /día"
 */
function subscriptionsPricePreview(prices: Price[], currency: Currency) {
  const normalizedPrices = prices.map((price) => {
    const days =
      intervalInDays[price.recurring_interval || "day"] *
      price.recurring_interval_count;
    const dailyRate = price.amount / days;
    return {
      ...price,
      dailyRate,
    };
  });

  // Find the price with the lowest daily rate
  const lowestPrice = normalizedPrices.reduce((prev, curr) => {
    return curr.dailyRate < prev.dailyRate ? curr : prev;
  });

  // Normalize the lowest price back to the lowest recurring interval
  const lowestInterval = findLowestInterval(prices);
  const proratedAmount = lowestPrice.dailyRate * intervalInDays[lowestInterval];

  // Format the price using the currency and interval
  const formattedAmount = amountAsCurrency({
    amount: proratedAmount,
    currency,
  });
  const intervalLabel = parseInterval(lowestInterval);

  return `desde ${formattedAmount} /${intervalLabel}`;
}

/**
 * @throws if `installments_count` is missing {@link Price.installments_count}
 * @example installmentsPricePreview([{ amount: 20_00, installments_count: 20 }, { amount: 15, installments_count: 3 }], "usd");
 * "desde $1 en 20 pagos"
 */
function installmentsPricePreview(
  prices: Price[],
  currency: Currency,
  detailed = false
) {
  const minByInstallment = prices.reduce((acc, curr) => {
    if (!acc.installments_count || !curr.installments_count) return acc;

    if (amountPerPayment(curr) - amountPerPayment(acc) < 0) {
      return curr;
    }

    return acc;
  }, prices[0]);

  if (!minByInstallment.installments_count) {
    throw new Error("Price is missing installments_count");
  }

  const { amount, installments_count } = minByInstallment;

  const amt = amountAsCurrency({
    amount: amount / installments_count,
    currency,
  });

  const text = `desde ${amt} en ${installments_count} pagos`;

  if (detailed) {
    const interval = priceInterval(minByInstallment);
    return `${text} cada ${interval}`;
  } else {
    return text;
  }
}

function mixedPricePreview(prices: Price[], currency: Currency) {
  const min = prices.reduce((acc, curr) => {
    if (amountPerPayment(curr) - amountPerPayment(acc) < 0) {
      return curr;
    }
    return acc;
  }, prices[0]);

  const amount = min.installments_count
    ? min.amount / min.installments_count
    : min.amount;

  const amt = amountAsCurrency({ amount, currency });

  return `desde ${amt}`;
}

function amountPerPayment(price: Price) {
  return price.installments_count
    ? price.amount / price.installments_count
    : price.amount;
}

const intervalRanks = { day: 1, week: 2, month: 3, year: 4 } as const;
function findLowestInterval(prices: Price[]) {
  return prices.reduce<Exclude<Price["recurring_interval"], null>>(
    (acc, curr) => {
      if (!curr.recurring_interval) return acc;

      return intervalRanks[acc] < intervalRanks[curr.recurring_interval]
        ? acc
        : curr.recurring_interval;
    },
    "month"
  );
}

function priceInterval(price: Price) {
  const { recurring_interval, recurring_interval_count } = price;

  let label = "";

  const intervalString = parseInterval(recurring_interval);

  if (recurring_interval_count > 1) {
    label += `${recurring_interval_count} ${intervalString}`;
    if (recurring_interval == "month") {
      label += "es";
    } else {
      label += "s";
    }
  } else {
    label += intervalString;
  }

  return label;
}

function parseInterval(interval: Price["recurring_interval"]) {
  switch (interval) {
    case "day":
      return "día";
    case "week":
      return "semana";
    case "month":
      return "mes";
    case "year":
      return "año";
    default:
      return "";
  }
}
