import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={39}
      height={24}
      viewBox="0 0 39 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M38.144 0H.362v24h37.782V0z" fill="#fff" />
      <path d="M37.514 20.1H.992v3.274h36.522v-3.275z" fill="#F7B600" />
      <path
        d="M37.514.63H.992v3.274h36.522V.63zM15.611 7.51l-3.773 9.002h-2.46L7.52 9.328c-.112-.44-.21-.603-.553-.79a9.704 9.704 0 00-2.297-.765l.054-.262h3.964a1.086 1.086 0 011.074.918l.98 5.214 2.422-6.122 2.447-.01zm9.643 6.063c.01-2.375-3.283-2.506-3.262-3.568 0-.322.315-.666.988-.754a4.402 4.402 0 012.297.403l.407-1.909a6.278 6.278 0 00-2.177-.399c-2.302 0-3.922 1.225-3.936 2.974-.014 1.296 1.158 2.018 2.038 2.449.908.44 1.212.724 1.208 1.117 0 .604-.724.875-1.399.875a4.875 4.875 0 01-2.39-.568l-.424 1.973c.546.248 1.55.465 2.589.477 2.448 0 4.047-1.209 4.054-3.08l.007.01zm6.071 2.928h2.154l-1.874-8.99h-1.987a1.06 1.06 0 00-.992.661l-3.498 8.34h2.449l.484-1.345h2.988l.276 1.335zm-2.592-3.178l1.224-3.38.7 3.38h-1.924zm-9.795-5.812l-1.924 9h-2.33l1.924-9h2.33z"
        fill="#1A1F71"
      />
    </svg>
  );
}

export default SvgComponent;
