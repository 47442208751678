export const currencies = ["usd", "crc"] as const;
export type Currency = typeof currencies[number];

export type FormatterStyle =
  | "currency"
  | "currencyInteger"
  | "decimal"
  | "decimalCents";

// https://github.com/microsoft/TypeScript/blob/master/lib/lib.esnext.intl.d.ts
export interface NumberFormatPart {
  currency?: string;
  decimal?: string;
  fraction?: string;
  group?: string;
  integer?: string;
  literal?: string;
  plusSign?: string;
  percentSign?: string;
  minusSign?: string;
  // infinity?: string;
  // nan?: string;
}

export interface CurrencyFormatPart extends NumberFormatPart {
  fractionOrEmpty: string;
}
