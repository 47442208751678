import React from "react";

import { StyleSheet } from "react-native";
import Svg, { Path } from "react-native-svg";

import Layout from "../../constants/Layout";
import { default as AntDesignIcons } from "./AntDesign";
import { default as CustomIcons } from "./Custom";
import { default as FeatherIcons } from "./Feather";
import { default as FontAwesomeIcons } from "./FontAwesome";
import { default as IoniconsIcons } from "./Ionicons";
import { default as MaterialIconsIcons } from "./MaterialIcons";
import { default as PhosphorIcons } from "./Phosphor";
import { default as SimpleLineIconsIcons } from "./SimpleLineIcons";

const DEFAULT_SIZE = Layout.mSize(20);
const DEFAULT_COLOR = "#000";

interface Props {
  name: string;
  size: number;
  color: string;
  style: object;

  source: object;
}

const Icons = (props: Props) => {
  const { source, name, size, color, style, ...rest } = props;
  const Icon = source[name];

  if (!Icon) {
    return <Svg />;
  }

  return (
    <Svg
      viewBox={Icon.viewBox}
      width={size || DEFAULT_SIZE}
      height={size || DEFAULT_SIZE}
      fill={color || DEFAULT_COLOR}
      style={StyleSheet.flatten([
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        style || {},
      ])}
      {...rest}
    >
      {Icon.paths.map((path, i) => {
        if (typeof path === "string") {
          return <Path key={i} d={path} />;
        }
        return <Path key={i} {...path} />;
      })}
    </Svg>
  );
};

export const AntDesign = (props) => (
  <Icons source={AntDesignIcons} {...props} />
);

export const Custom = (props) => <Icons source={CustomIcons} {...props} />;

export const FontAwesome = (props) => (
  <Icons source={FontAwesomeIcons} {...props} />
);

export const Feather = (props) => <Icons source={FeatherIcons} {...props} />;

export const Ionicons = (props) => <Icons source={IoniconsIcons} {...props} />;

export const MaterialIcons = (props) => (
  <Icons source={MaterialIconsIcons} {...props} />
);

export const Phosphor = (props) => <Icons source={PhosphorIcons} {...props} />;

export const SimpleLineIcons = (props) => (
  <Icons source={SimpleLineIconsIcons} {...props} />
);
