import React from "react";
import { Link } from "./types";
import Icon from "@mdi/react";
import {
  mdiInstagram,
  mdiSpotify,
  mdiTwitter,
  mdiWhatsapp,
  mdiYoutube,
} from "@mdi/js";

type Props = {
  link: Link;
  disabled?: boolean;
};

export default function StoreLink({ link, disabled }: Props) {
  const path = getIconPath(link.type);
  const disabledStyle = disabled ? "pointer-events-none select-none" : "";

  return (
    <a
      className={`flex text-primary-500 ${disabledStyle}`}
      href={link.url || undefined}
      target="_blank"
    >
      <Icon
        path={path}
        className="h-6 w-6 p-3 border border-white-500 active:bg-gray-100 border-solid rounded-full cursor-pointer flex-shrink-0"
        style={{ fillRule: "evenodd" }}
      />
    </a>
  );
}

function getIconPath(type: Link["type"]) {
  switch (type) {
    case "whatsapp":
      return mdiWhatsapp;
    case "instagram":
      return mdiInstagram;
    case "twitter":
      return mdiTwitter;
    case "youtube":
      return mdiYoutube;
    case "spotify":
      return mdiSpotify;
    case "discord":
      return "M20.3303 4.22781C18.7767 3.50093 17.1156 2.97267 15.3789 2.67188C15.1656 3.05749 14.9164 3.57614 14.7446 3.98873C12.8985 3.71109 11.0693 3.71109 9.25716 3.98873C9.08539 3.57614 8.83055 3.05749 8.61536 2.67188C6.87681 2.97267 5.21376 3.50287 3.66019 4.23166C0.526643 8.96686 -0.322811 13.5845 0.101917 18.1365C2.18025 19.6885 4.19441 20.6313 6.17457 21.2483C6.66349 20.5754 7.09953 19.8601 7.47518 19.1063C6.75975 18.8344 6.07453 18.499 5.42707 18.1095C5.59884 17.9822 5.76686 17.8492 5.92918 17.7123C9.87819 19.5594 14.1689 19.5594 18.0707 17.7123C18.235 17.8492 18.403 17.9822 18.5728 18.1095C17.9235 18.5009 17.2364 18.8363 16.521 19.1082C16.8966 19.8601 17.3308 20.5774 17.8216 21.2502C19.8036 20.6333 21.8197 19.6905 23.898 18.1365C24.3964 12.8595 23.0467 8.28434 20.3303 4.22781ZM8.01318 15.337C6.82772 15.337 5.85555 14.2303 5.85555 12.8826C5.85555 11.535 6.80696 10.4264 8.01318 10.4264C9.21942 10.4264 10.1916 11.533 10.1708 12.8826C10.1727 14.2303 9.21942 15.337 8.01318 15.337ZM15.9867 15.337C14.8013 15.337 13.8291 14.2303 13.8291 12.8826C13.8291 11.535 14.7805 10.4264 15.9867 10.4264C17.193 10.4264 18.1651 11.533 18.1444 12.8826C18.1444 14.2303 17.193 15.337 15.9867 15.337Z";
    case "telegram":
      return "M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm4.813 5.472-1.784 8.995s-.25.624-.936.325L9.99 13.56s4.566-4.104 4.753-4.279c.187-.174.125-.212.125-.212.012-.212-.337 0-.337 0l-6.05 3.843-2.52-.849s-.388-.137-.425-.436c-.037-.3.437-.462.437-.462l10.018-3.93s.823-.362.823.237Zm-5.258 7.374L9.926 16.33s-.127.097-.266.036l.312-2.758 1.583 1.238Z";
    case "threads":
      return "M17.692 11.123a8.547 8.547 0 0 0-.315-.142c-.185-3.414-2.05-5.368-5.182-5.388h-.042c-1.873 0-3.431.8-4.39 2.255l1.722 1.181c.716-1.087 1.84-1.318 2.669-1.318h.028c1.031.006 1.81.306 2.313.89.367.426.612 1.015.733 1.757a13.176 13.176 0 0 0-2.96-.143c-2.977.172-4.892 1.909-4.763 4.322.065 1.223.675 2.277 1.717 2.964.88.582 2.015.866 3.194.802 1.558-.085 2.78-.68 3.632-1.766.647-.825 1.056-1.894 1.237-3.241.742.448 1.292 1.037 1.596 1.745.516 1.205.546 3.184-1.068 4.797-1.415 1.414-3.116 2.025-5.686 2.044-2.851-.02-5.008-.935-6.41-2.717-1.313-1.67-1.991-4.08-2.016-7.165.025-3.085.703-5.496 2.016-7.165 1.402-1.782 3.558-2.696 6.41-2.717 2.871.02 5.065.94 6.521 2.73.714.879 1.252 1.983 1.607 3.27l2.018-.538c-.43-1.585-1.107-2.95-2.027-4.083C18.38 1.2 15.65.024 12.134 0h-.014C8.61.024 5.912 1.205 4.099 3.51c-1.614 2.05-2.446 4.904-2.474 8.482v.016c.028 3.578.86 6.431 2.474 8.482 1.813 2.305 4.511 3.486 8.02 3.51h.015c3.12-.022 5.319-.838 7.13-2.649 2.371-2.368 2.3-5.336 1.518-7.158-.56-1.307-1.629-2.368-3.09-3.07Zm-5.387 5.065c-1.305.074-2.66-.512-2.728-1.766-.05-.93.662-1.969 2.808-2.092.246-.015.487-.021.724-.021.78 0 1.508.075 2.171.22-.247 3.088-1.697 3.59-2.975 3.66Z";
    case "tiktok":
      return "M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.79.042 2.895 2.895 0 0 1 3.772-2.809v-3.5a6.329 6.329 0 1 0 5.463 6.268V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.83 4.83 0 0 1-1.003-.104Z";
  }
}
