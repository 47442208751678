import React, { useState } from "react";

import { InView } from "react-intersection-observer";

import { IMAGES_API_URL } from "../../../api/config";
import { hasWebp } from "../../../services/Browser";

const getInitial = (name: string) => {
  // Watch out for emojis in string
  // https://stackoverflow.com/questions/24531751/how-can-i-split-a-string-containing-emoji-into-an-array
  return [...name][0];
};

type InViewProps = {
  name: string;
  image: string;
  imageSize: number;
};

// wrapper for ProductPicture. removes images once outside view for performance
const InViewProductPicture = ({ name, image, imageSize }: InViewProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <InView
      threshold={0.1}
      as="div"
      // Style object for <div />
      // Inherits from parent component
      // has to keep inline shape
      className="w-full h-full"
      onChange={(isInView) => {
        if (isInView) {
          setIsVisible(true);
        }
      }}
    >
      <ProductPicture
        name={name}
        image={image}
        isVisible={isVisible}
        imageSize={imageSize}
      />
    </InView>
  );
};

type PictureProps = {
  name: string;
  image: string;
  isVisible: boolean;
  imageSize: number;
};

const ProductPicture = ({
  name,
  image,
  isVisible,
  imageSize,
}: PictureProps) => {
  const imgSrc = `${IMAGES_API_URL}/?url=${image}&w=${imageSize}&h=${imageSize}&quality=100&output=${
    hasWebp ? "webp" : "jpg"
  }`;

  return (
    <div className="h-full w-full flex">
      {isVisible && image ? (
        <img
          className="object-cover w-full h-full"
          data-testid="product-picture"
          src={imgSrc}
        />
      ) : (
        <span
          className="flex flex-1 flex-col items-center justify-center text-white text-8xl font-light bg-gray-300 h-44"
          data-testid="product-picture-placeholder"
        >
          {getInitial(name)}
        </span>
      )}
    </div>
  );
};

export default InViewProductPicture;
