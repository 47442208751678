import React from "react";
import { View, StyleSheet } from "react-native";

import Svgx from "./Svgx";
import * as Svg from "react-native-svg";

import { Layout } from "../constants";

import cuantoIconsLib from "../icons/cuantoIconsLib.json";

type Props = {
  bg?: string;
  opacity?: number;
  borderColor?: string;
};

export default class CardIdMask extends React.PureComponent<Props> {
  render() {
    const { bg, opacity, borderColor } = this.props;

    const iconProps = {
      component: Svg,
      styles: {
        height: Layout.idCardHeight,
        width: Layout.idCardWidth,
        "outside-bg-path": {},
        "border-path": {},
      },
      data: cuantoIconsLib.cameraSquare,
    };

    if (bg) iconProps.styles["outside-bg-path"].fill = bg;
    // opacity is number
    if (!isNaN(+opacity) && isFinite(opacity)) {
      iconProps.styles["outside-bg-path"].opacity = opacity;
    }
    // border color
    if (borderColor) {
      iconProps.styles["border-path"].fill = borderColor;
    }

    return (
      <View style={styles.idContainer}>
        <Svgx {...iconProps} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  idContainer: {
    height: Layout.idCardHeight,
    width: Layout.window.width,
  },
});
