import { Product as FullProduct } from "../types";

type Product = Pick<FullProduct, "inventory" | "uuid" | "variant_options">;

export function hasInventory(
  product: Product,
  productVariants: Product[] = []
) {
  // product has no variants
  if (productVariants.length === 0) {
    if (!isInventoryEnabled(product)) {
      return true;
    } else {
      const q = product.inventory?.quantity || 0;
      return q > 0;
    }
  } else {
    // product has variants
    const quantity = productVariants.reduce((acc, variant) => {
      // Inventory is disabled on Product variant
      if (!isInventoryEnabled(variant)) {
        return acc + 1;
      }

      const q = variant.inventory?.quantity || 0;
      if (q >= 0) {
        return acc + q;
      } else {
        return acc;
      }
    }, 0);

    return quantity > 0;
  }
}

function isInventoryEnabled(product: Product) {
  return product.inventory?.enabled || false;
}
