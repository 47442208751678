import React from "react";

import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ImageStyle,
} from "react-native";
import styled, { css } from "styled-components/native";

import { IMAGES_API_URL } from "../api/config";
import { Layout, Colors } from "../constants";
import Text from "./Text";

type StylesOverride = {
  avatar?: StyleProp<ImageStyle>;
  icon?: StyleProp<ViewStyle>;
};

interface Props {
  testID?: string;

  image?: string;
  name?: string;
  placeholder?: JSX.Element;

  compress?: boolean;
  isRound?: boolean;
  hasBorderRadius?: boolean;

  width?: number;
  height?: number;
  stylesOverride?: StylesOverride;

  onPress?: Function;
}

const getInitial = (name) => {
  // Watch out for emojis in string
  // https://stackoverflow.com/questions/24531751/how-can-i-split-a-string-containing-emoji-into-an-array
  return [...name][0];
};

function Avatar({
  testID,

  image,
  name,
  placeholder,

  compress = true,
  isRound = true,
  hasBorderRadius = false,

  width = 50,
  height = 50,
  stylesOverride = { avatar: {}, icon: {} },

  onPress,
}: Props) {
  // resolution to which image will be resized
  // bigger number => higher resolution => higher load times
  const imageResize = 145;
  let content;
  if (image) {
    content = (
      <StyledImage
        testID="avatar-picture"
        width={width}
        height={height}
        isRound={isRound}
        hasBorderRadius={hasBorderRadius}
        source={{
          uri: compress
            ? `${IMAGES_API_URL}/?url=${image}&w=${imageResize}&h=${imageResize}`
            : `${IMAGES_API_URL}/?url=${image}`,
        }}
        style={[stylesOverride.avatar]}
      />
    );
  } else if (placeholder) {
    content = (
      <Container
        width={width}
        height={height}
        isRound={isRound}
        hasBorderRadius={hasBorderRadius}
        hasPlaceholder
        style={[stylesOverride.avatar]}
      >
        {placeholder}
      </Container>
    );
  } else {
    content = (
      <Container
        width={width}
        height={height}
        isRound={isRound}
        hasBorderRadius={hasBorderRadius}
        testID="avatar-placeholder"
        style={[stylesOverride.avatar]}
      >
        <Icon style={[stylesOverride.icon]}>{getInitial(name)}</Icon>
      </Container>
    );
  }

  const Touchable: React.ElementType = onPress ? TouchableOpacity : View;

  return (
    <Touchable testID={testID} onPress={onPress}>
      {content}
    </Touchable>
  );
}

const ContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.background.tertiary};

  width: ${(props) => Layout.mSize(props.width)}px;
  height: ${(props) => Layout.mSize(props.width)}px;

  ${(props) =>
    props.isRound &&
    css`
      border-radius: ${(props) => Layout.mSize(props.width / 2)}px;
    `}

  ${(props) =>
    props.hasBorderRadius &&
    css`
      border-radius: 0.5rem;
    `}
`;

const Container = styled.View`
  ${ContainerStyles};

  ${(props) =>
    props.hasPlaceholder &&
    css`
      background-color: ${Colors.white};
      border: 1px dashed ${Colors.border.tertiary};
    `}
`;

const StyledImage = styled.Image`
  ${ContainerStyles};
`;

const Icon = styled(Text)`
  font-size: ${Layout.mSize(24)}px;
  color: ${Colors.white};
`;

export default Avatar;
