import React, { Suspense } from "react";

import { Router } from "@reach/router";
import { connect, ConnectedProps } from "react-redux";

import { urlBasedOnReleaseChannel } from "cuanto/api/utils";
import CatalogueScreen from "cuanto/package/Catalogue/Catalogue/CatalogueScreen";

import CatalogueLinkScreen from "package/Catalogue/CatalogueLink/CatalogueLinkScreen";
import { APIProvider } from "package/hooks/APIContext";

import { setUtm } from "../features/Cart/actions";
import CatalogueLayout from "./CatalogueLayout";
import PreviewScreen from "./features/Preview/PreviewScreen";
import { url } from "./router";
import RouterListener from "./RouterListener";
import {
  PaymentRequest,
  ProductQuestion,
  Consumer,
  CatalogueCart,
  CatalogueQuantity,
  CatalogueCartPayment,
  CatalogueMoreInfo,
  CheckoutReview,
  PaymentRequestReview,
  PaymentResult,
  CatalogueReviews,
  CatalogueReviewForm,
  FulfillmentOptionsList,
  ConsumerDeliveryInfoForm,
  ConsumerDeliverySelection,
  CatalogueDiscountForm,
  Paylink,
  SubscriptionPrice,
  PaylinkPayment,
  NotFound,
  SubscriptionCancellation,
  MerchantAdminDashboard,
  MerchantAdminProductForm,
  ConsumerValidation,
} from "./Screens";

import "./Catalogue.css";
import "./tailwind.css";

const baseURL = urlBasedOnReleaseChannel();

function Root(reduxProps: ReduxProps) {
  const props: any = {};

  let root = "/:username";
  // Sometimes we set the username as a subdomain. This is used in 'cool' links like
  // miranda.cuan.to, etc.
  if (url.subdomain) {
    props.username = url.subdomain;
    root = "/";
  }

  // Capture utm query params in URL
  // Router's useParams requires this to be a child of router and we need this to happen regardless of the route.
  const urlParams = new URLSearchParams(window.location.search);
  const utmKeys = Array.from(urlParams.keys()).filter((k: string) =>
    k.startsWith("utm_")
  );
  // Set utm params in Redux so create cart request can send them to the backend
  if (utmKeys.length > 0) {
    const utmParams = utmKeys.reduce<Record<string, string>>((acc, key) => {
      const value = urlParams.get(key);
      if (value) {
        acc[key] = value;
      }

      return acc;
    }, {});
    reduxProps.setUtm(utmParams);
  }

  return (
    <Suspense fallback={<div />}>
      <RouterListener />
      <APIProvider baseURL={baseURL}>
        <Router id="router">
          {/* Layout includes a CatalogueProvider & a cookie listener for resetting carts inside */}
          <CatalogueLayout path={root} {...props}>
            <CatalogueScreen path="/" {...props} />
            <PreviewScreen path="/preview" {...props} />

            <CatalogueScreen path="/category/:category_uuid" {...props} />

            <Consumer path="/customer/email" {...props} />

            <CatalogueCart path="/cart" {...props} />
            <CatalogueQuantity path="/cart/quantity" {...props} />
            <CatalogueCartPayment path="/cart/payment" {...props} />
            <CheckoutReview path="/checkout/review" {...props} />
            <PaymentRequestReview path="/cart/review" {...props} />
            <ConsumerValidation path="/checkout/validation" {...props} />
            <PaymentResult path="/cart/result" {...props} />

            <CatalogueMoreInfo path="/more_info" {...props} />

            <CatalogueReviews path="/customers/reviews" {...props} />
            <CatalogueReviewForm
              path="/customers/review/:receipt_uuid/:rating"
              {...props}
            />

            <FulfillmentOptionsList path="/fulfillments/info" {...props} />
            <ConsumerDeliveryInfoForm path="/delivery/details" {...props} />
            <ConsumerDeliverySelection path="/delivery/selection" {...props} />

            <CatalogueDiscountForm path="/discount" {...props} />

            <CatalogueLinkScreen path="/p/:short_uuid" {...props} />

            <ProductQuestion path="/p/:short_uuid/questions" {...props} />

            <PaylinkPayment path="/payment" {...props} />

            <Paylink path="/c" {...props} />
            <Paylink path="/c/:amount" {...props} />

            <SubscriptionPrice path="/p/:short_uuid/prices" {...props} />

            {/* This needs to be below urls with /p/:short_uuid/:string otherwise would capture all */}
            <CatalogueLinkScreen path="/p/:short_uuid/:tracker" {...props} />

            {/* We place this last to ensure it doens't mess with other routes */}
            <CatalogueScreen path="/:tracker" {...props} />
          </CatalogueLayout>

          <PaymentRequest path="/c/:short_uuid" />
          <SubscriptionCancellation
            path="/subscription_cancellations/:uuid"
            {...props}
          />

          <MerchantAdminDashboard path="/:username/admin" {...props} />
          <MerchantAdminProductForm
            path="/:username/admin/p/:uuid"
            {...props}
          />

          <NotFound default />
        </Router>
      </APIProvider>
    </Suspense>
  );
}

const mapDispatchToProps = {
  setUtm: setUtm,
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(Root);
